import { Component, Inject, Injector, NgZone, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
//import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
//import { SocialAuthService, GoogleLoginProvider, SocialUser } from 'angularx-social-login';

// Import the AuthService type from the SDK
// import { AuthService, User } from '@auth0/auth0-angular';
// import { DOCUMENT } from '@angular/common';

//declare const gapi: any;

@Component({
  selector: 'app-ese-family-services',
  templateUrl: './ese-family-services.component.html',
  styleUrls: ['./ese-family-services.component.scss']
})
export class EseFamilyServicesComponent {

  isProcessLogin: boolean = false;
  loginFormSubmitted = false;
  //socialUser!: SocialUser;
  //socialUser: SocialUser | undefined;
  socialUser: any;
  loggedIn: boolean;
  accessToken = '';
  
  apiUrl:string;
  userID: string = "";
  isOpenLoginView:number=0;
  serviceList: any;
  languageSetting: string = "0";
  serviceType: string;
  serviceTypeName: string = "Loading...";

  serviceId: number;
  serviceName: string = 'Renew Residency Visa';
  applyHere: string = "Apply Here";
  price:string;
  remark:string;
  type:string;
  authType: number;

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // constructor(private http: HttpClient, private injector: Injector, private router: Router, private route: ActivatedRoute, public auth: AuthService, @Inject(DOCUMENT) public document: Document) {
    constructor(private http: HttpClient, private injector: Injector, private router: Router, private route: ActivatedRoute) {
    this.apiUrl = this.injector.get('APIURL');
    if(localStorage.getItem('UserID') != null) this.userID = localStorage.getItem('UserID');
    if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
    if(this.languageSetting == "0") this.applyHere = "Apply Here";
    else this.applyHere = "للتقديم";
    this.socialUser = {};
    // window['onSignIn'] = user => {
    //   this.zone.run(
    //     () => {
    //       console.log(user);
    //       //this.afterSignUp(user);
    //     }
    //   ); 
    // }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.queryParams.subscribe(
        params => {
            this.serviceType =  params['sType'];
            // this.serviceName =  params['ServiceName'];
            // this.price = params['Price'];
            // this.remark = params['Remark'];
            // this.type = params['Type'];
            // this.authType = params['AuthType'];
        }
    );
    this.actionGetAllService();

    // this.auth.user$.subscribe((value: User | null | undefined) => {
    //   console.log("User:", value);
    // });

    // this.auth.isAuthenticated$.subscribe((value: boolean) => {
    //   console.log("Authd:", value);
    // });
    
    // this.auth.user$.subscribe(user => {
    //   console.log(user);
    //   // this.socialUser = user;
    //   // console.log(this.socialUser);
    //   // this.socialUser = JSON.stringify(user, null, 2);
    //   // console.log(this.socialUser);
    // });
    // this.auth.user$.subscribe(
    //   (profile) => {
    //     (this.socialUser = JSON.stringify(profile, null, 2)),
    //     console.log(this.socialUser);
    //   }
    // );
    // console.log(this.socialUser);
    // console.log(this.auth.user$);
    

    // this.socialAuthService.authState.pipe(
    //   map((socialUser: SocialUser) => !!socialUser),
    //   tap((isLoggedIn: boolean) => {
    //     if (!isLoggedIn) {
    //       this.router.navigate(['ese-family-services']);
    //     }
    //   })
    // )

    // this.socialAuthService.authState.subscribe((user) => {
    //   debugger;
    //   console.log(user);
    //   this.socialUser = user;
    //   this.loggedIn = (user != null);
    // });


    // this.socialAuthService.authState.subscribe((user) => {
    //   debugger;
    //   this.socialUser = user;
    //   console.log(this.socialUser);
    //   this.googleLogin();
    // });

    // this.socialAuthService.initState.subscribe(auth => {
    //   //this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);

    //   console.log(auth);

    //   this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
    //     debugger;
    //     console.log(user);
    //   },
    //   (error) => console.error(error));

    //   this.socialAuthService.authState.subscribe((user) => {
    //     debugger;
    //     this.socialUser = user;
    //     console.log(this.socialUser);
    //     //this.googleLogin();
    //   });
    // })

    //console.log(this.userIDP);
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  openModal(){
	  this.isOpenLoginView = Math.floor(Math.random() * 50);
  }//openModal()

  // loginWithRedirect(): void {
  //   this.auth.loginWithRedirect();
  // }
  
//   ngAfterViewInit() {
//     debugger;
//     gapi.signin2.render('my-signin2', {
//         'scope': 'profile email',
//         'width': 240,
//         'height': 50,
//         'longtitle': true,
//         'theme': 'light',
//         'onsuccess': (param: any) => this.onSignIn(param)
//     });
//   }

//   public onSignIn(googleUser) {
//     debugger;
//     console.log(googleUser);
//     console.log(googleUser.getBasicProfile());
  
//     // var user : User = new user();
//     var user : SocialUser;

//     ((u, p) => {
//         u.id            = p.getId();
//         u.name          = p.getName();
//         u.email         = p.getEmail();
//         // u.imageUrl      = p.getImageUrl();
//         // u.givenName     = p.getGivenName();
//         // u.familyName    = p.getFamilyName();
//     })(user, googleUser.getBasicProfile());

//     ((u, r) => {
//         u.idToken         = r.id_token;
//     })(user, googleUser.getAuthResponse());

//     // user.save();
//     // this.goHome();
// };

  // refreshGoogleToken(): void {
  //   this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  // }

  // afterSignUp(googleUser){
  //   debugger;
  //   this.socialUser = googleUser;
  //   console.log(this.socialUser);
  // }

  // refreshToken(): void {
  //   this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  // }

  // googleSignIn(){
  //   return from(this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID))
  // }

  //login(){
  //   let socialUser = this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
  //     console.log(userData);
  //     if(userData.idToken){
  //       console.log(userData.idToken);
  //     } else {
  //       alert('danger')
  //     }
  //  });
  //  console.log(socialUser);
  //   this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(() => {
  //       let socialUser = this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((userData) => {
  //     console.log(userData);
  //     if(userData.idToken){
  //       console.log(userData.idToken);
  //     } else {
  //       alert('danger')
  //     }
  //  });
  //  console.log(socialUser);
  //   });
    //this.socialAuthService.signIn();
    //this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(() => this.router.navigate(['ese-family-services']));
  //this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  //   // this.socialAuthService.authState.subscribe(user => {
  //   //   debugger;
  //   //   this.socialUser = user;
  //   //   console.log(this.socialUser);
  //   //   //this.googleLogin();
  //   // });

  //   // this.googleSignIn().subscribe(user => {
  //   //   debugger;
  //   //   this.socialUser = user;
  //   //   console.log(this.socialUser);
  //   //   //this.googleLogin();
  //   // });
  //}//login()
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // refreshToken(): void {
  //   this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  //   //this.socialAuthService.refreshAccessToken(GoogleLoginProvider.PROVIDER_ID);
  //   //this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  //   this.getAccessToken();
  // }

  // getAccessToken(): void {
  //   this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(accessToken => this.accessToken = accessToken);
  // }

  // attemptLogin(): void {
  //   this.auth.isAuthenticated$.subscribe((value: boolean) => {
  //     if (!value) {
  //       let query: string = window.location.search;
  //       let shouldParseResult: boolean = query.includes("code=") && query.includes("state=");
  //       if (shouldParseResult) {
  //         try {
  //           this.auth.handleRedirectCallback().subscribe((value) => {
  //             console.log("Logged in!", value);
  //           });
  //         } catch (err: unknown) {
  //           console.log("Error parsing redirect:", err);
  //         }
  //         window.history.replaceState({}, this.document.title, "/");
  //       }
  //       else {
  //         this.auth.loginWithRedirect().subscribe((value: void) => {
  //           console.log("Logging in...", value);
  //         });
  //       }
  //     }
  //     else {
  //       this.router.navigate(['home']);
  //     }
  //   });
  // }

  
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionGetAllService(){
    var formData = new FormData();
    formData.append('PageMenuID', this.serviceType);
    formData.append('LanguageID', this.languageSetting);

    this.http.post(this.apiUrl + "/Service/DDL_By_ServiceType", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.serviceTypeName = data["TblServiceType"][0].A1;
        this.serviceList = data["TblService"];
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetAllService

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // googleLogin(){
  //   debugger;
  //   // this.spinner.show(undefined,
  //   //   {
  //   //     type: 'ball-triangle-path',
  //   //     size: 'medium',
  //   //     bdColor: 'rgba(0, 0, 0, 0.8)',
  //   //     color: '#fff',
  //   //     fullScreen: true
  //   //   });
      
  //     var param: any;
  //     param = {email:this.socialUser.email, id:this.socialUser.id, clientname:this.socialUser.name, photourl:this.socialUser.photoUrl, ClientIDF: 0, loginType: 0};
      
  //     this.http.post(this.apiUrl + '/APIMaster.asmx/UserLoginGoogle', JSON.stringify(param), this.injector.get('httpHeader')).subscribe(
  //       (data => {
  //         if(JSON.stringify(data).indexOf("Invalid")>=0){
  //           //this.spinner.hide();
  //           console.log('Login Failed.');
  //           return;
  //         }

  //         // if(data[0].ClientIDF!=undefined){
  //         //   localStorage.setItem("ClientIDF", String(data[0].ClientIDF));
  //         // }else{
  //         //   localStorage.setItem("ClientIDF", "0");
  //         // }
  //         localStorage.setItem("UserIDP", String(data[0].UserIDP));
  //         localStorage.setItem("UserName", String(data[0].UserName));
  //         localStorage.setItem("Token", String(data[0].CurrentToken));
  //         localStorage.setItem("ProfilePicture", String(data[0].ProfilePicture));
          
  //         //this.cookieService.set('b2bzatpat', String(data[0].CurrentToken), 1, '/','.zatpatmachines.com', false, "Strict"); // To Set Cookie
          
  //         if(data[0].ClientIDF==0)
  //         {
  //           //this.spinner.hide();
  //           //this.router.navigate(['/admin/dashboard']);///Jump To Admin's Dashbaord
  //         }
  //         else
  //         {
  //           //this.spinner.hide();
  //           //this.router.navigate(['/crm/machine']);///Jump To CLIENT's Dashbaord
  //         }
  //       }),
  //       (error => {
  //         //this.spinner.hide();
  //         console.log('error: ' + error);
  //       })
  //     )
  // }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
}
