import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-renew-residency-visa',
  templateUrl: './renew-residency-visa.component.html',
  styleUrls: ['./renew-residency-visa.component.scss']
})
export class RenewResidencyVisaComponent implements OnInit {

  apiUrl:string;
  submittedRenewVisa = false;
  isProcessSave: boolean = false;
  isProcessLoading: boolean = false;
  pnService: boolean = true;
  
  serviceId: number;
  customerId: number;
  serviceName: string;
  price:string;
  remark:string;
  type:string;
  authType:number;

  profilePictureAttachements: string[] = [];
  passportAttachements: string[] = [];
  medicalCertificateAttachements: string[] = [];
  eIDApplicationAttachements: string[] = [];
  healthInsuranceAttachements: string[] = [];
  salaryCertificateAttachements: string[] = [];

  StaffName: string = 'ESE Staff Name';
  StaffNumber: string = 'ESE Staff Number';
  Location: string = 'Location';
  EmployeeEmail: string = 'Employee Email';
  EmployeeMobile: string = 'Employee Mobile';
  EmployeeLocation: string = 'Employee Location';
  ProfilePicture: string = 'Personal Photo';
  Passport: string = 'Passport Copy';
  MedicalCertificate: string = 'Medical Certificate';
  EIDApplication: string = 'EID Application';
  HealthInsurance: string = 'Health Insurance Copy';
  SalaryCertificate: string = 'Salary Certificate';
  Comments: string = 'Comments';
  SalaryCertificateIssueDate: string = 'Salary Certificate Issue Date';

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private injector: Injector, private fb: FormBuilder) {
    this.apiUrl = this.injector.get('APIURL');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.serviceId = params['ServiceID'];
        this.serviceName =  params['ServiceName'];
        this.price = params['Price'];
        this.remark = params['Remark'];
        this.type = params['Type'];
        this.authType = params['AuthType'];
      }
    )
  }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  frmCtrlRenewVisa = new FormGroup({
    ServiceRenewVisaIDP: new FormControl(0),
    EmployeeIDF: new FormControl(),
    StaffName: new FormControl("", [Validators.required]),
    StaffNumber: new FormControl(),
    Location: new FormControl("", [Validators.required]),
    EmployeeEmail: new FormControl("", [Validators.required]),
    EmployeeMobile: new FormControl(),
    EmployeeLocation: new FormControl("", [Validators.required]),
    Comments: new FormControl(),
    StatusID: new FormControl(),
    SalaryCertificateIssueDate: new FormControl(),
  });

  get fRenewVisa() { return this.frmCtrlRenewVisa.controls; }
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =  
  clearCtrl(){
    this.frmCtrlRenewVisa.reset();
    this.submittedRenewVisa = false;
    this.fRenewVisa.ServiceRenewVisaIDP.setValue(0);
    this.profilePictureAttachements = [];
    this.passportAttachements = [];
    this.medicalCertificateAttachements = [];
    this.eIDApplicationAttachements = [];
    this.healthInsuranceAttachements = [];
    this.salaryCertificateAttachements = [];
  }//clearCtrl
  
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  ProfilePictureChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.profilePictureAttachements.push(event.target.files[i]);
    }
  }
  
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  PassportChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.passportAttachements.push(event.target.files[i]);
    }
  }
  
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  MedicalCertificateChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.medicalCertificateAttachements.push(event.target.files[i]);
    }
  }
  
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  EIDApplicationChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.eIDApplicationAttachements.push(event.target.files[i]);
    }
  }
  
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  HealthInsuranceChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.healthInsuranceAttachements.push(event.target.files[i]);
    }
  }
  
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  SalaryCertificateChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.salaryCertificateAttachements.push(event.target.files[i]);
    }
  }
  
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionSave(){
    this.submittedRenewVisa = true;
    if (this.frmCtrlRenewVisa.invalid) {
      return;
    }
    this.isProcessSave = true;

    var formData = new FormData();
    formData.append('ServiceRenewVisaIDP', (this.fRenewVisa.ServiceRenewVisaIDP.value?.toString() == null) ? '0' : this.fRenewVisa.ServiceRenewVisaIDP.value.toString());
    formData.append('EmployeeIDF', (this.fRenewVisa.EmployeeIDF.value == null) ? '1' : this.fRenewVisa.EmployeeIDF.value);
    formData.append('StaffName', (this.fRenewVisa.StaffName.value == null) ? '' : this.fRenewVisa.StaffName.value);
    formData.append('StaffNumber', (this.fRenewVisa.StaffNumber.value == null) ? "" : this.fRenewVisa.StaffNumber.value);
    formData.append('Location', (this.fRenewVisa.Location.value! == null) ? "" : this.fRenewVisa.Location.value);
    formData.append('EmployeeEmail', (this.fRenewVisa.EmployeeEmail.value! == null) ? '' : this.fRenewVisa.EmployeeEmail.value!);
    formData.append('EmployeeMobile', (this.fRenewVisa.EmployeeMobile.value! == null) ? '' : this.fRenewVisa.EmployeeMobile.value!);
    formData.append('EmployeeLocation', (this.fRenewVisa.EmployeeLocation.value! == null) ? '' : this.fRenewVisa.EmployeeLocation.value!);
    formData.append('ProfilePicture', (this.profilePictureAttachements[0] == undefined) ? '' : this.profilePictureAttachements[0]);
    formData.append('Passport', (this.passportAttachements[0] == undefined) ? '' :  this.passportAttachements[0]);
    formData.append('MedicalCertificate', (this.medicalCertificateAttachements[0] == undefined) ? '' :  this.medicalCertificateAttachements[0]);
    formData.append('EIDApplication', (this.eIDApplicationAttachements[0] == undefined) ? '' :  this.eIDApplicationAttachements[0]);
    formData.append('HealthInsurance', (this.healthInsuranceAttachements[0] == undefined) ? '' :  this.healthInsuranceAttachements[0]);
    formData.append('SalaryCertificate', (this.salaryCertificateAttachements[0] == undefined) ? '' :  this.salaryCertificateAttachements[0]);
    formData.append('Comments', (this.fRenewVisa.Comments.value! == null) ? '' : this.fRenewVisa.Comments.value!);
    // formData.append('Remarks', (this.fRenewVisa.Remarks.value! == null) ? '' : this.fRenewVisa.Remarks.value!);
    formData.append('StatusID', (this.fRenewVisa.StatusID.value == null) ? '1' : this.fRenewVisa.StatusID.value);
    formData.append('SalaryCertificateIssueDate', (this.fRenewVisa.SalaryCertificateIssueDate.value! == null) ? '' : this.fRenewVisa.SalaryCertificateIssueDate.value!);

      this.http.post(this.apiUrl + '/ServiceRenewVisa/Save', formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data => {
        const res : any = data;
        if(res.status == "Done"){
          this.clearCtrl();
          this.pnService = false;
        }
        this.isProcessSave = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessSave = false;
      })
    )
  }//actionSave
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =

}