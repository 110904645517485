import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import * as SweetAlert from 'src/app/shared/data/sweet-alerts';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent {

  apiUrl:string;
  attachmentURL:string;
  submitted = false;
  isProcessSave: boolean = false;
  isProcessLoading: boolean = false;
  httpHeaderMultipart: any;
  userID: string = "";
  languageSetting: string = "0";
	UserPicture: any = "assets/img/NoPhofile.png";
  usrPicture: any;
  newAttachements: string[] = [];
  usrPicture_Height : number = 0;
  usrPicture_Width : number = 0;

  FirstName: string = 'First Name';
  LastName: string = 'Last Name';
  MobileNo: string = 'Mobile No';
  EmailID: string = 'Email ID';
  EmployeeMobile: string = 'Employee Mobile';
  ProfilePicture: string = 'ProfilePicture';
  IsActive: string = 'Is Active';

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private injector: Injector, private fb: FormBuilder, public sanitizer: DomSanitizer) {
    this.apiUrl = this.injector.get('APIURL');
    this.attachmentURL = this.injector.get('AttachmentURL');
    this.httpHeaderMultipart = this.injector.get('httpHeaderMultipart');
    if(localStorage.getItem('UserID') != null){
      this.userID = localStorage.getItem('UserID');
    }
    if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  ngOnInit(): void {
    this.actionEdit(this.userID);
  }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  frmCtrlClient = new FormGroup({
    ClientIDP: new FormControl(0),
    FirstName: new FormControl("", [Validators.required]),
    LastName: new FormControl("", [Validators.required]),
    MobileNo: new FormControl("",[Validators.required]),
    EmailID: new FormControl("",[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    //ProfilePicture: new FormControl(),
    IsActive: new FormControl(),
  });

  get f() { return this.frmCtrlClient.controls; }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  clearCtrl(){
    this.frmCtrlClient.reset();
    this.submitted = false;
    this.f.ClientIDP.setValue(0);
    this.f.IsActive.setValue(true);
    this.UserPicture = 'assets/img/NoPhofile.png';
    this.usrPicture = '';
  }//clearCtrl

  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  inputFileChange(event: any) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.newAttachements.push(event.target.files[i]);
      this.UserPicture = URL.createObjectURL(event.target.files[i]);
    }
    
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        this.usrPicture_Height = img.naturalHeight;
        this.usrPicture_Width = img.naturalWidth;
      };
    };
	}//inputFileChange

  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  public getSantizeUrl(url : string) {
    if(url != undefined && url.indexOf('blob:') >= 0) return this.sanitizer.bypassSecurityTrustUrl(url);
    else return url;
  }//getSantizeUrl

  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  imageDelete(){
    let inputFile = (<HTMLInputElement>document.getElementById('importImagePath'));
    inputFile.files = null;
    this.UserPicture = 'assets/img/NoPhofile.png';
    this.usrPicture = '';
    localStorage.setItem('Photo', null);
  }//imageDelete

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionSave(){
    this.submitted = true;
    if (this.frmCtrlClient.invalid) {
      return;
    }
    this.isProcessSave = true;

    let inputFile = (<HTMLInputElement>document.getElementById('importImagePath'));
    
    if(this.newAttachements.length != 0){
      if(this.usrPicture_Width != 125 || this.usrPicture_Height != 125){
        SweetAlert.ActionFail("Profile image dimensions must be 125px X 125px", "Warning!");
        this.isProcessSave = false;
        return;
      }
    }
    
    var formData = new FormData();
    formData.append('ClientIDP', (this.f.ClientIDP.value.toString() == null) ? '0' : this.f.ClientIDP.value.toString());
    formData.append('FirstName', (this.f.FirstName.value == null) ? "" : this.f.FirstName.value);
    formData.append('LastName', (this.f.LastName.value == null) ? "" : this.f.LastName.value);
    formData.append('MobileNo', (this.f.MobileNo.value == null) ? "" : this.f.MobileNo.value);
    formData.append('EmailID', (this.f.EmailID.value == null) ? "" : this.f.EmailID.value);
    formData.append('ProfilePicture', (inputFile.files[0] == undefined) ? this.usrPicture : inputFile.files[0]);
    //formData.append('ProfilePicture', (this.f.ProfilePicture.value == null) ? '' : this.f.ProfilePicture.value);
    formData.append('IsActive', (this.f.IsActive.value == null) ? "true" : this.f.IsActive.value);

    this.http.post(this.apiUrl + '/Client/Update', formData, this.httpHeaderMultipart).subscribe(
      (data => {
        const res : any = data;
        if(res.status == "Done"){
          SweetAlert.ActionSuccess(res.msg);
          this.clearCtrl();
          window.location.reload();
        }
        else{
          SweetAlert.ActionFail(res.msg);
        }
        this.isProcessSave = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessSave = false;
      })
    )
  }//actionSave
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //= = = = = = = = = = = = = = = = = = = = = = = = =
  actionEdit(ID: any)
  {
    this.isProcessLoading = true;
    var formData = new FormData();
    formData.append('ParamId', ID);

    this.http.post(this.apiUrl + "/Client/Get", formData, this.httpHeaderMultipart).subscribe(
      (data => {
        const res : any = data;
        this.f.ClientIDP.setValue(res[0].ClientIDP);
        this.f.FirstName.setValue(res[0].FirstName);
        this.f.LastName.setValue(res[0].LastName);
        this.f.MobileNo.setValue(res[0].MobileNo);
        this.f.EmailID.setValue(res[0].EmailID);
        this.f.IsActive.setValue(res[0].IsActive);
        if(res[0].ProfilePicture == null || res[0].ProfilePicture == "undefined"){
          this.UserPicture = "assets/img/NoPhofile.png";
          localStorage.setItem('Photo', "");
        }else{
          //this.UserPicture = res[0].ProfilePicture;
          this.UserPicture = this.attachmentURL + "Client/" + res[0].ClientIDP + "/" + res[0].ProfilePicture;
          this.usrPicture = res[0].ProfilePicture;
          localStorage.setItem('Photo', res[0].ProfilePicture);
        }
        this.isProcessLoading = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessLoading = false;
      })
    )
  }//actionEdit
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
}
