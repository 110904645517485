import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.scss']
})
export class UserDashboardComponent implements OnInit {

  @ViewChild('modalCtrlFormDetail') modalCtrlFormDetail: FormGroup;

  apiUrl:string;
  attachmentURL:string;
  httpHeaderMultipart: any;
  isRTL: string = '';
  userID: number = 0;
  isProcessLoading: boolean = false;
  serviceId: number;
  serviceName: string;
  formType: string;
  recordIDP: number;
  dynamicIdf: number;
  tableName: string;
  formDetails: any;
  formTableDetail: any;
  serviceList: any;

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  constructor(private http: HttpClient, private injector: Injector, private route: ActivatedRoute, public sanitizer: DomSanitizer, private mdl: NgbModal) {
    this.apiUrl = this.injector.get('APIURL');
    this.attachmentURL = this.injector.get('AttachmentURL');
    this.httpHeaderMultipart = this.injector.get('httpHeaderMultipart');
    this.isRTL = (localStorage.getItem('IsRTL').toString() == "rtl") ? "1" : "0";
    if(localStorage.getItem('UserID') != null){
      this.userID = Number(localStorage.getItem('UserID'));
    }
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  ngOnInit(): void {
    this.actionGetUserHistory();
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionCancel(){
    this.mdl.dismissAll();
  }//actionCancel()
  
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionGetUserHistory(){
    this.isProcessLoading = true;
    this.http.get(this.apiUrl + "/UserHistory/GetAll/" + this.userID + "/" + this.isRTL, this.httpHeaderMultipart).subscribe(
      (data) => {
        this.serviceList = data;
        this.isProcessLoading = false;
      },
      (error) => {
        console.log(error);
        this.isProcessLoading = false;
      }
    );
  }//actionGetServiceDetails

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionViewForm(data, type){
    this.http.get(this.apiUrl + "/DynamicFrom/DynamicFromDetails_Get_By_RecordIDP/" + data.RecordIDF + "/" + data.ServiceIDF + "/" + this.isRTL).subscribe(
      (data) => {
        this.formType = type;
        this.serviceName = data["ServiceData"][0].ServiceName;
        this.serviceId = data["ServiceData"][0].ServiceIDP;
        this.dynamicIdf = data["DynamicFormTableData"][0].DynamicIDF;
        this.tableName = data["DynamicForm"][0].TableName;
        this.formDetails = JSON.stringify(data["DynamicFormDetailData"]);
        this.formTableDetail = JSON.stringify(data["DynamicFormTableData"]);
        this.recordIDP = data["DynamicFormTableData"][0].ID;
        this.mdl.open(this.modalCtrlFormDetail, { size: 'xl', backdrop: 'static' });
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionViewForm

}
