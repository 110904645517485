<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url(assets/img/page-title/page-title5.jpg);">
    <div class="container">
        <div class="page-title-content">
            <h2>{{serviceName}} To Apply</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
                <li>{{serviceName}}</li>
                <li>Checkout</li>
            </ul>
        </div>
    </div>
</div>
<!-- <div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{Content.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{Content.title}}</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

<!-- Start Checkout Area -->
<section *ngIf="pnService && authType == 2" class="checkout-area pt-100">
    <div class="container text-center">
        <a class="btn btn-primary m-5" href="https://stg-ids.uaepass.ae/authenticationendpoint/login.do?acr_values=urn%3Adigitalid%3Aauthentication%3Aflow%3Amobile&client_id=self_service&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http://localhost:4200&response_type=code&scope=urn%3Auae%3Adigitalid%3Aprofile+urn%3Auae%3Adigitalid%3Abackend_api%3Ahistory+urn%3Auae%3Adigitalid%3Abackend_api%3Auser_notifications+urn%3Auae%3Adigitalid%3Abackend_api%3Acertificate_data+urn%3Auae%3Adigitalid%3Abackend_api%3Aupdate_email_mobile+urn%3Auae%3Adigitalid%3Abackend_api%3Asupport+urn%3Auae%3Adigitalid%3Abackend_api%3Amanage_device+urn%3Auae%3Adigitalid%3Abackend_api%3Arevoke_device+urn%3Auae%3Adigitalid%3Abackend_api%3Aupdate_profile+urn%3Auae%3Adigitalid%3Abackend_api%3Aget_profile+urn%3Auae%3Adigitalid%3Abackend_api%3Aotp+openid&tenantDomain=carbon.super&ui_locales=en&sessionDataKey=4b401f84-eb47-48b2-b635-7e12aafe08a3&relyingParty=self_service&type=oidc&sp=Self+Service+Portal&isSaaSApp=false&reCaptcha=TRUE&authenticators=BasicAuthenticator%3ALOCAL">Login to UAE PASS</a>
        <!-- <iframe src="https://stg-ids.uaepass.ae/authenticationendpoint/login.do?acr_values=urn%3Adigitalid%3Aauthentication%3Aflow%3Amobile&client_id=self_service&commonAuthCallerPath=%2Foauth2%2Fauthorize&forceAuth=false&passiveAuth=false&redirect_uri=http://localhost:4200&response_type=code&scope=urn%3Auae%3Adigitalid%3Aprofile+urn%3Auae%3Adigitalid%3Abackend_api%3Ahistory+urn%3Auae%3Adigitalid%3Abackend_api%3Auser_notifications+urn%3Auae%3Adigitalid%3Abackend_api%3Acertificate_data+urn%3Auae%3Adigitalid%3Abackend_api%3Aupdate_email_mobile+urn%3Auae%3Adigitalid%3Abackend_api%3Asupport+urn%3Auae%3Adigitalid%3Abackend_api%3Amanage_device+urn%3Auae%3Adigitalid%3Abackend_api%3Arevoke_device+urn%3Auae%3Adigitalid%3Abackend_api%3Aupdate_profile+urn%3Auae%3Adigitalid%3Abackend_api%3Aget_profile+urn%3Auae%3Adigitalid%3Abackend_api%3Aotp+openid&tenantDomain=carbon.super&ui_locales=en&sessionDataKey=4b401f84-eb47-48b2-b635-7e12aafe08a3&relyingParty=self_service&type=oidc&sp=Self+Service+Portal&isSaaSApp=false&reCaptcha=TRUE&authenticators=BasicAuthenticator%3ALOCAL" height="500px" width="100%"></iframe> -->
    </div>
</section>

<form *ngIf="authType == 1" class="needs-validation" [formGroup]="frmCtrl" autocomplete="off">
<section *ngIf="pnService" class="checkout-area pt-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <h3>Registration</h3>
                <div class="row">
                    <div class="col-md-3 form-group">
                        <label for="FirstName" class="form-label">First Name <i>*</i></label>
                        <input type="text" class="form-control" placeholder="Enter First Name" formControlName="FirstName"
                        [ngClass]="{ 'is-invalid': submitted && f.FirstName.invalid, 'is-valid': submitted &&  !f.FirstName.invalid }" required autofocus>
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="MiddleName" class="form-label">Middle Name <i>*</i></label>
                        <input type="text" class="form-control" placeholder="Enter Middle Name" formControlName="MiddleName"
                        [ngClass]="{ 'is-invalid': submitted && f.MiddleName.invalid, 'is-valid': submitted &&  !f.MiddleName.invalid }" required>
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="LastName" class="form-label">Last Name <i>*</i></label>
                        <input type="text" class="form-control" placeholder="Enter Last Name" formControlName="LastName"
                        [ngClass]="{ 'is-invalid': submitted && f.LastName.invalid, 'is-valid': submitted &&  !f.LastName.invalid }" required>
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="EmiratesID" class="form-label">EmiratesID</label>
                        <input type="number" class="form-control" placeholder="Enter Emirates ID" formControlName="EmiratesID">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label for="CompanyName" class="form-label">Company Name</label>
                        <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="CompanyName">
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="EmailID" class="form-label">EmailID <i>*</i></label>
                        <input class="form-control" type="email" email="true" placeholder="Enter EmailID" formControlName="EmailID"
                        [ngClass]="{ 'is-invalid': submitted && f.EmailID.invalid, 'is-valid': submitted &&  !f.EmailID.invalid }" required>
                    </div>
                    <div class="col-md-3 form-group">
                        <label for="ContactNo" class="form-label">ContactNo <i>*</i></label>
                        <input class="form-control" type="text" id="ContactNo" placeholder="Enter ContactNo" formControlName="ContactNo" minlength="10" maxlength="12"
                        [ngClass]="{ 'is-invalid': submitted && f.ContactNo.invalid, 'is-valid': submitted && !f.ContactNo.invalid }" required>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label for="Address" class="form-label">Address</label>
                        <textarea placeholder="Enter Address" class="form-control" rows="2" formControlName="Address"></textarea>
                    </div>
                    <div class="col-md-2 form-group">
                        <label for="City" class="form-label">City</label>
                        <input type="text" class="form-control" placeholder="Enter City" formControlName="City">
                    </div>
                    <div class="col-md-2 form-group">
                        <label for="State" class="form-label">State</label>
                        <input type="text" class="form-control" placeholder="Enter State" formControlName="State">
                    </div>
                    <div class="col-md-2 form-group">
                        <label for="Country" class="form-label">Country</label>
                        <input type="text" class="form-control" placeholder="Enter Country" formControlName="Country">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label for="Remark" class="form-label">Remark</label>
                        <textarea placeholder="Enter Remark" class="form-control" rows="2" formControlName="Remark"></textarea>
                    </div>
                    <!-- <div class="col-md-1 form-group mt-3">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="IsActive" formControlName="IsActive" checked>
                            <label class="form-check-label" for="IsActive">IsActive</label>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="pnService" class="checkout-area p-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <h3>Required Documents</h3>
                <div formArrayName="innerDetail">
                    <div class="outcomes-list" *ngFor="let templateFields of templateFieldsFormGroup.controls; let i = index;">
                        <div [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-3 form-group">
                                    <input type="text" class="form-control" formControlName="Type" readonly>
                                    <!-- <label for="{{templateFields.value.Type}}" class="form-label">{{templateFields.value.Type}}</label> -->
                                </div>
                                <div class="col-md-1 form-group"> : </div>
                                <div class="col-md-8 form-group">
                                    <input type="file" class="form-control" id="importAttachmentPath{{i}}" formControlName="Attachments" (change)="SavFile($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="Attachments" class="form-label">Attachments</label>
                        <input type="file" class="form-control" id="importAttachmentPath" formControlName="Attachments">
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<section *ngIf="pnService" class="checkout-area pb-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-success" (click)="actionSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</form>

<section *ngIf="!pnService" class="checkout-area ptb-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper text-center">
                <h3 class="text-success" style="padding: 50px;">Thank you for apply {{serviceName}} service.</h3>
                <a class="btn btn-primary" routerLink="/service-form-template" [queryParams]="{sid:serviceId, cid:customerId, ServiceName:serviceName}"> Next </a>
            </div>
        </div>
    </div>
</section>


<!-- <section class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <span>Returning customer? <a href="#" data-bs-toggle="modal" data-bs-target="#loginRegisterModal">Click here to login</a></span>
        </div>

        <form>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Billing Details</h3>

                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>
                                    <div class="select-box">
                                        <ngx-select-dropdown (searchChange)="searchChange($event)" tabindex="0" [multiple]="false" [(ngModel)]="singleSelect" name="country-select" [config]="config" [options]="options"></ngx-select-dropdown>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="text" class="form-control">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account">
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>

                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Note Book Mockup</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">$250.00</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Motivational Book Cover</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">$200.00</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Book Cover Softcover</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">$200.00</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="product-name">
                                            <a routerLink="/single-products">Stop and Take a Second</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">$150.00</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-subtotal">
                                            <span>Cart Subtotal</span>
                                        </td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">$1683.50</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-shipping">
                                            <span>Shipping</span>
                                        </td>
                                        <td class="shipping-price">
                                            <span>$30.00</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="total-price">
                                            <span>Order Total</span>
                                        </td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount">$750.00</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                    <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                    <label for="direct-bank-transfer">Direct Bank Transfer</label>
                                    Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.
                                </p>
                                <p>
                                    <input type="radio" id="paypal" name="radio-group">
                                    <label for="paypal">PayPal</label>
                                </p>
                                <p>
                                    <input type="radio" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Cash on Delivery</label>
                                </p>
                            </div>
                            <button type="submit" class="default-btn"><i class="flaticon-tick"></i>Place Order</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</section> -->
<!-- End Checkout Area -->

<app-footer-style-two></app-footer-style-two>