import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subPageMenu'
})
export class SubPageMenuPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }

  transform(items: any[], pageMenuIDF: number): any {
    if(items != undefined && pageMenuIDF == 0) return items.filter(item => item.PageMenuIDF === pageMenuIDF);
    if(items != undefined && pageMenuIDF != 0) return items.filter(item => item.PageMenuIDF === pageMenuIDF);
  }

}
