// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { default as data } from '../app/auth_config.json';

export const environment = {
  production: false,
  // auth0: {
  //   domain: 'dev-8law55djka4lr36q.us.auth0.com',
  //   clientId: 'br4TlHQ3OOOVs58pVLw4Fd7AjURGbxr7',
  //     authorizationParams: {
  //       redirect_uri: window.location.origin
  //     }
  //   //redirectUri: window.location.origin
  //   //redirectUri: 'http://localhost:65387',
  // },
  // auth: {
  //   domain : data.domain,
  //   clientId : data.clientId,
  //   //redirectUri: "http://localhost:4200/#/ese-family-services"
  //   redirectUri: window.location.origin
  // },
  // auth: {
  //   domain: "dev-8law55djka4lr36q.us.auth0.com",
  //   clientId: "xXax8frRzROvI9FRtnjhc3xtkYDv72kd",
  //   redirectUri: "http://localhost:4200/#/ese-family-services",
  //   //redirectUri: window.location.origin,
  // },
  // auth: {
  //   domain: "dev-8law55djka4lr36q.us.auth0.com",
  //   clientId: "9cHwaHpz75mH1VOFl9bMi7p4gZEMVm1M",
  //   //redirectUri: "http://localhost:4200/#/ese-family-services",
  //   redirectUri: window.location.origin,
  // },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
