import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as SweetAlert from 'src/app/shared/data/sweet-alerts';
// Typescript
import WebIM, { AgoraChat } from 'agora-chat';

// import { GroupUser, ChatUser, ChatMessage, ContactModel } from './chat.model';
// import { groupData, chatData, chatMessagesData, contactData } from './data';

import { ITblAgoraDetail } from 'src/app/Models/ITblAgoraDetail';
import { AgoraDetailService } from 'src/app/Services/agoraDetail.service';
import { DatePipe } from '@angular/common';
import { IParamAgoraDetailLeave } from 'src/app/Models/IParamAgoraDetailLeave';
import { IParam_Get_By_ID } from 'src/app/Models/IParam_Get_By_ID';
import { Param_Get_By_ID_IDF } from 'src/app/Models/IParam_Get_By_ID_IDF';
import { Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TblAgoraChatService } from 'src/app/Services/agoraChat.service';
import { IParam_GetAll_AgoraChat } from 'src/app/Models/IParam_GetAll_AgoraChat';
import { DomSanitizer } from '@angular/platform-browser';
import { IParam_Verify_OTP } from 'src/app/Models/IParam_Verify_OTP';

declare var joinChannel: any;
declare var showHide_ChatBox: any;
declare var leave: any;
declare var getCountryCode: any;
declare var Action_GetAll_AgoraChat_for_Client: any;

@Component({
  selector: 'app-live-chat',
  templateUrl: './live-chat.component.html',
  styleUrls: ['./live-chat.component.scss']
})
export class LiveChatComponent implements OnInit, OnDestroy {

	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	  Local Variable
	  - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	dataList: Array<any> = [];
	searchKeyword: string = '';
	pnGrid: Boolean = false;
	submitted: Boolean = false;
	submittedOTP: Boolean = false;
	pageCrr: number = 1;
	agoraDetailIDP: number = 0;
	agoraIDP: number = 0;
	agoraAppID: string = "";
	agoraToken: string = "";
	//employeeIDF: number = 0;
	agoraChannelName: string = "";
	OTP: number = 0;
	isProcess: boolean = true;
	isMissedCall: boolean = false;
	pnContactForm: Boolean = false;
	pnChat: Boolean = false;
	pnOTP: Boolean = false;
	mobileNoType: number = 1;
	preferredLanguage: number = 0;
	TAWmobileNoType: number = 1;
	// TAWPreferredLanguage: number = 0;
	pnAnotherWay: Boolean = false;
	IsExistClient: Boolean = false;
	pnNoAgent: Boolean = false;

	apiURL: string = "";
	attachmentURL: string = "";
	clientIDP: number = 0;
	employeeIDF: number = 0;
	mobileNo: string = "";
	userName: string = "";
	agoraAgentName: string = "";
	AgentPicture: string = 'assets/img/NoPhofile.png';
	CustomerPicture: string = 'assets/img/NoPhofile.png';
	
	countDown: Subscription;
	counter = 120;
	tick = 1000;

  	// chatMessagesData!: ChatMessage[];
	chatMessagesData: Array<any> = [];
 	showEmojiPicker = false;

	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	Constructor InIt
	- - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	constructor(private _service: AgoraDetailService, public datepipe: DatePipe, public sanitizer: DomSanitizer, private injector: Injector, private router: Router, private _agoraChatService: TblAgoraChatService) {
		this.IsExistClient = ((localStorage.getItem('UserID') == null) ? false : true);
		if(this.IsExistClient == true) this.pnContactForm =! this.pnContactForm;
		this.apiURL = this.injector.get('APIURL');
		this.attachmentURL = this.injector.get('AttachmentURL');
		this.mobileNo = (localStorage.getItem('MobileNo'));
		this.clientIDP = Number((localStorage.getItem('UserID')));
		this.CustomerPicture = this.attachmentURL + "Client/" + this.clientIDP + "/" + localStorage.getItem('Photo');
	}

	ngOnInit() {
		setTimeout(() => {
			this.AddNew();
		}, 500);

    this._fetchData();
	}

	ngOnDestroy() {
	  this.countDown = null;
	}

	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	Form Group
	- - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	frmCtrl = new FormGroup({
		AgoraDetailIDP: new FormControl(0),
		AgoraIDF: new FormControl(),
		UserIDF: new FormControl(),
		EmployeeIDF: new FormControl(),
		PartnerIDF: new FormControl(),
		ClientIDF: new FormControl(),
		RecordingID: new FormControl(),
		CustomerName: new FormControl('', [Validators.required]),
		CustomerEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
		CustomerMobileNo: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
		MobileNumberType: new FormControl(),
		DateJoinUser: new FormControl(),
		DateJoinEmployee: new FormControl(),
		DateEndUser: new FormControl(),
		DateEndEmployee: new FormControl(),
		PreferredLanguage: new FormControl(),
	});
	get f() { return this.frmCtrl.controls; }
	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	AddNew() {
		this.isProcess = false;
		this.submitted = false;

		this.frmCtrl.reset();
		this.frmCtrl.controls['AgoraDetailIDP'].setValue(0);
		this.frmCtrl.controls['AgoraIDF'].setValue(0);
		this.frmCtrl.controls['UserIDF'].setValue(0);
		this.frmCtrl.controls['EmployeeIDF'].setValue(0);
		this.frmCtrl.controls['PartnerIDF'].setValue(0);
		this.frmCtrl.controls['RecordingID'].setValue('');
		(localStorage.getItem('UserID') != null) ? this.frmCtrl.controls['ClientIDF'].setValue(localStorage.getItem('UserID')) : this.frmCtrl.controls['ClientIDF'].setValue(0);
		(localStorage.getItem('UserName') != null) ? this.frmCtrl.controls['CustomerName'].setValue(localStorage.getItem('UserName').toString()) : this.frmCtrl.controls['CustomerName'].setValue('');
		(localStorage.getItem('EmailID') != null) ? this.frmCtrl.controls['CustomerEmail'].setValue(localStorage.getItem('EmailID').toString()) : this.frmCtrl.controls['CustomerEmail'].setValue('');
		(localStorage.getItem('MobileNo') != null) ? this.frmCtrl.controls['CustomerMobileNo'].setValue(localStorage.getItem('MobileNo').toString()) : this.frmCtrl.controls['CustomerMobileNo'].setValue('');
		this.frmCtrl.controls['MobileNumberType'].setValue(1);
		this.frmCtrl.controls['DateJoinUser'].setValue('');
		this.frmCtrl.controls['DateJoinEmployee'].setValue('');
		this.frmCtrl.controls['DateEndUser'].setValue('');
		this.frmCtrl.controls['DateEndEmployee'].setValue('');
		this.frmCtrl.controls['PreferredLanguage'].setValue(0);
	}
	//==================================================================
	showHide_ChatBox(id: number, event: any) {
		showHide_ChatBox(event, '');
		//id == 0 ? showHide_ChatBox(event, '') : showHide_ChatBox(event, '.wmc');
	}
	
	//==================================================================
	actionshowHideContactForm(){
		if(this.IsExistClient == true){
			this.isProcess = true;
			this.actionSave();
		}
	}//actionshowHideContactForm()
	//==================================================================
	ClearCtrl() {
		this.frmCtrl.reset();
		this.frmCtrl.controls['AgoraDetailIDP'].setValue(0);
		this.frmCtrl.controls['MobileNumberType'].setValue(1);
		this.frmCtrl.controls['PreferredLanguage'].setValue(0);
		this.mobileNoType = 1;
		this.preferredLanguage = 0;
		this.submitted = false;
	}

    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    public getSantizeUrl(url : string) {
      if(url != undefined && url.indexOf('blob:') >= 0) return this.sanitizer.bypassSecurityTrustUrl(url);
      else return url;
    }//getSantizeUrl

    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    getProfile(userType, img){
      var url = "";
      if(userType == 2 && img != null && img != "" && img != undefined){
        //this.EmployeePicture = GlobalComponent.AttachmentUrl + "Employee/" + GlobalComponent.userID.toString() + "/" + res[0].EmployeeIDP + "/" + res[0].ProfileImage;
        //this.FranchisePicture = GlobalComponent.AttachmentUrl + "Assets/Franchise/" + GlobalComponent.userID + "/" + res["FranchiseData"][0].FranchiseIDP + "/" + res["FranchiseData"][0].ProfileImage;
        url = this.attachmentURL + "Franchise/" + this.employeeIDF + "/" + 0 + "/" + img;
      }
      else if(userType == 3 && img != null && img != "" && img != undefined){
        url = this.attachmentURL + "Client/" + this.clientIDP + "/" + localStorage.getItem('Photo');
      }
      else url = "assets/images/NoPhofile.png";
      return this.getSantizeUrl(url);
    }//getProfile()
	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	/*-------------------------------------------------------------------
			__   ___  __  ___          __   __       ___  ___ 
	| |\ | /__` |__  |__)  |     |  | |__) |  \  /\   |  |__  
	| | \| .__/ |___ |  \  |     \__/ |    |__/ /~~\  |  |___ 
	-------------------------------------------------------------------*/
	async actionSave() {
		//console.log("Start Video Call Button Click...");
		
		this.submitted = true;
		if (this.frmCtrl.invalid) {
			return;
		}
		this.isProcess = true;
		const obj = this.frmCtrl.value;
		
		this.TAWmobileNoType = this.f.MobileNumberType.value;
		//this.TAWPreferredLanguage = this.f.PreferredLanguage.value;

		var tempGetCountryCode = getCountryCode();

		this.userName = this.f.CustomerName.value;
		
		var tempMobNo = "";
		if(this.IsExistClient == true){
			tempMobNo = String(obj["CustomerMobileNo"]);
		}else{
			tempMobNo = (tempGetCountryCode.toString()) + (String(obj["CustomerMobileNo"]));
		}

		var fd: ITblAgoraDetail = {
			agoraDetailIDP: Number(obj['AgoraDetailIDP']),
			agoraIDF: obj['AgoraIDF'],
			userIDF: obj['UserIDF'],
			employeeIDF: obj['EmployeeIDF'],
			partnerIDF: obj['PartnerIDF'],
			recordingID: obj["RecordingID"] == null ? null : String(obj["RecordingID"]),
			customerName: String(obj["CustomerName"]),
			customerEmail: String(obj["CustomerEmail"]),
			// customerMobileNo: (tempGetCountryCode.toString()) + (String(obj["CustomerMobileNo"])),
			customerMobileNo: (tempMobNo),
			mobileNumberType: (obj["MobileNumberType"] == 1 ? true : false),
			languageID: Number(localStorage.getItem("LanguageID")),
			dateJoinUser: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
			dateJoinEmployee: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
			dateEndUser: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
			dateEndEmployee: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
			preferredLanguage: Number(obj["PreferredLanguage"] == null ? 0 : obj["PreferredLanguage"]),
			clientIDF: Number(obj['ClientIDF'])
		}
		// this._service.Action_InsertUpdate(fd).subscribe(
		this._service.Action_InsertUpdate_v2(fd).subscribe(
			async (r) => {
				// if(r.status == "Fail"){
				// 	SweetAlert.ActionFail(r.msg);
				// 	this.pnContactForm != this.pnContactForm;
				// 	this.pnChat = false;
				// 	this.pnOTP = false;
				// 	this.pnGrid = false;
				// 	this.isProcess = false;
				// 	this.isMissedCall = false;
				// 	return;
				// }
				if(r.id <= 0) { SweetAlert.ActionFail(r.msg); this.isProcess = false; return; }
				else {
					if (r.agoraID != "") {
						this.agoraDetailIDP = r.id;
						this.agoraIDP = r.agoraID;
						this.agoraAppID = r.appId;
						this.agoraToken = r.token;
						this.agoraChannelName = r.channelName;
						
						var tempUser = JSON.parse(r.userDetail);
						localStorage.setItem('UserName', tempUser[0].FullName);
						localStorage.setItem('UserID', tempUser[0].ClientIDP);
						localStorage.setItem('Token', tempUser[0].CurrentToken);
						localStorage.setItem('EmailID', tempUser[0].EmailID);
						localStorage.setItem('MobileNo', tempUser[0].MobileNo);
						localStorage.setItem('Photo', tempUser[0].ProfilePicture);
						if(tempUser[0].isRTL == 0){
							localStorage.setItem('IsRTL', "true");
							localStorage.setItem('LanguageID', "1");
						}
						else{
							localStorage.setItem('IsRTL', "false");
							localStorage.setItem('LanguageID', "0");
						}

						// if(this.IsExistClient == true){
						// 	this.isProcess = false;
						// 	this.pnContactForm = false;
						// 	this.pnGrid = true;
						// 	this.pnChat = true;
						// 	joinChannel(this.agoraAppID, this.agoraToken, this.agoraChannelName);
						// 	this.ClearCtrl();
						// 	var  param : Param_Get_By_ID_IDF = {
						// 		ParamId: this.agoraDetailIDP,
						// 		ParamIdf : this.agoraDetailIDP
						// 	}
						// 	var isMissedCall = setInterval(()=>{
						// 		this._service.Action_MissedCall(param).subscribe(
						// 			async (r) => {
						// 				if(r.isMisssedCall)
						// 				{
						// 					this.isProcess = false;
						// 					this.pnGrid = false;
						// 					this.pnChat = false;
						// 					this.pnOTP = true;
						// 					this.pnAnotherWay = false;
						// 					this.pnNoAgent = true;
						// 					this.pnContactForm = false;
						// 					this.isMissedCall  = true;
						// 					this.Action_Leave(false);
						// 					//SweetAlert.ActionFail("Sorry, Our all employee are busy!, please try after sometime.");
						// 					clearInterval(isMissedCall);
						// 				}
						// 			}, (error) => {
						// 				console.log(error);
						// 			}
						// 		);
						// 	},30000);
						// }
						// else{
							this.isProcess = false;
							this.pnGrid = false;
							this.pnChat = false;
							this.pnOTP = true;
							this.pnContactForm = false;
							setTimeout(() => { var c1 = <HTMLInputElement>document.querySelector("[autofocus]"); if (c1 != null) c1.focus(); }, 300);
							this.countDown = timer(0, this.tick).pipe(take(this.counter)).subscribe(() => {
								--this.counter;
								if (this.counter == 0) { this.countDown.unsubscribe(); }
							});
						//}
						// this.ClearCtrl();
						// var  param : Param_Get_By_ID_IDF = {
						// 	Id: this.agoraDetailIDP,
						// 	Idf : this.agoraDetailIDP
						// }
						// var isMissedCall = setInterval(()=>{
						// 	this._service.Action_MissedCall(param).subscribe(
						// 		async (r) => {
						// 			if(r.isMisssedCall)
						// 			{
						// 				//console.log("Missed Call...");
						// 				this.isMissedCall  = true;
						// 				//this.pnChat = false;
						// 				this.pnOTP = false;
						// 				this.Action_Leave();
						// 				SweetAlert.ActionFail("Sorry, Our all employee are busy!, please try after sometime.");
						// 				clearInterval(isMissedCall);
						// 			}
						// 		}, (error) => {
						// 			console.log(error);
						// 		}
						// 	);
						// },30000)
					}
					// else {
					// 	SweetAlert.ActionFail("Sorry, Our all employee are busy!, please try after sometime.");
					// }
				}
			}, (error) => {
				this.isProcess = false;
				console.log(error);
			}
		);
	}//END - Action Insert Update

	async Action_Leave(isAgentAvailable) {
		if(isAgentAvailable == true){
			var replyReason = await SweetAlert.ActionConfirmCatalogue("Are You Sure?", "Do want to leave this meeting!", "warning", "Yes, Leave It!");
			if(replyReason!=undefined){
				var fd: IParamAgoraDetailLeave = {
					agoraDetailIDP: this.agoraDetailIDP,
					agoraIDF: this.agoraIDP,
					employeeIDF: 0,
					clientIDF: this.clientIDP,
					partnerIDF: null,
				}
				this._service.Action_Leave(fd).subscribe(
					async (r) => {
						if (r.id > 0) {
							leave();
							//this.router.navigate(['/live-chat']);
							setTimeout(() => { window.location.reload(); }, 200);
							//if(!this.isMissedCall)SweetAlert.ActionSuccess(r.msg);
							// this.pnContactForm = true;
							// this.pnChat = false;
							// this.pnOTP = false;
							// this.pnGrid = false;
							// this.isProcess = false;
							// this.isMissedCall = false;
						}
						else {
							SweetAlert.ActionFail('Something wrong!'); this.isProcess = false; return;
						}
					}, (error) => {
						this.isProcess = false;
						console.log(error);
					}
				);
			}
		}
		else{
			var fd: IParamAgoraDetailLeave = {
				agoraDetailIDP: this.agoraDetailIDP,
				agoraIDF: this.agoraIDP,
				employeeIDF: 0,
				clientIDF: this.clientIDP,
				partnerIDF: null,
			}
			this._service.Action_Leave(fd).subscribe(
				async (r) => {
					if (r.id > 0) {
						//if(!this.isMissedCall)SweetAlert.ActionSuccess(r.msg);
						leave();
					}
					else {
						SweetAlert.ActionFail('Something wrong!'); this.isProcess = false; return;
					}
				}, (error) => {
					this.isProcess = false;
					console.log(error);
				}
			);
		}
	}

	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	
	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	Form Group
	- - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	frmCtrlOTP = new FormGroup({
		OTP1: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
		OTP2: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
		OTP3: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
		OTP4: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
	});
	get fOTP() { return this.frmCtrlOTP.controls; }
	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

	actionVerifyOTP(){
		this.submittedOTP = true;
		if (this.frmCtrlOTP.invalid) {
			return;
		}
		this.isProcess = true;

		var tempOTP = (this.fOTP.OTP1.value.toString()) + (this.fOTP.OTP2.value.toString()) + (this.fOTP.OTP3.value.toString()) + (this.fOTP.OTP4.value.toString());
    
		var fd: IParam_Verify_OTP = {
			AgoraDetailIDP: this.agoraDetailIDP,
			OTP: Number(tempOTP)
		}

		this._service.Action_Verify_OTP(fd).subscribe(
			async (r) => {
				if (r[0].IsAvailableAgent == false && r[0].IsValidOTP == 1) {
					this.isProcess = false;
					this.pnNoAgent = true;
					return;
				}
				else if (r[0].IsValidOTP == 0) {
					SweetAlert.ActionFail("Invalid OTP!");
					this.isProcess = false;
					this.frmCtrlOTP.reset();
					setTimeout(() => { var c1 = <HTMLInputElement>document.querySelector("[autofocus]"); if (c1 != null) c1.focus(); }, 100);
					return;
				}
				else {
					//this.Action_GetAll_AgoraChat_for_Client();
					//SweetAlert.ActionSuccess("Your Request was sent successfully.");
					this.isProcess = false;
					this.pnContactForm = false;
					this.pnGrid = true;
					this.pnChat = true;
					this.pnOTP = false;
					this.pnAnotherWay = true;
					//console.log("Join Chaneel Calling...");
					joinChannel(this.agoraAppID, this.agoraToken, this.agoraChannelName);
					Action_GetAll_AgoraChat_for_Client();
					this.ClearCtrl();
					var  param : Param_Get_By_ID_IDF = {
						ParamId: this.agoraDetailIDP,
						ParamIdf : this.agoraDetailIDP
					}
					var isMissedCall = setInterval(()=>{
						this._service.Action_MissedCall(param).subscribe(
							async (r) => {
								if(r.isMisssedCall)
								{
									Action_GetAll_AgoraChat_for_Client();
									//console.log("Missed Call...");this.isProcess = false;
									this.isProcess = false;
									this.pnGrid = false;
									this.pnChat = false;
									this.pnOTP = true;
									this.pnAnotherWay = false;
									this.pnNoAgent = true;
									this.pnContactForm = false;
									this.isMissedCall  = true;
									this.Action_Leave(false);
									//SweetAlert.ActionFail("Sorry, Our all employee are busy!, please try after sometime.");
									clearInterval(isMissedCall);
								}
							}, (error) => {
								console.log(error);
							}
						);
					},30000);
					// joinChannel(r.appId, r.token, r.channelName);
					// this.pnGrid = true;
					// this.isProcess = false;
					// this.pnChat = true;
					// this.pnOTP = false;
					// this.ClearCtrl();
				}
			}, (error) => {
				this.isProcess = false;
				console.log(error);
			}
		);
	}//actionVerifyOTP

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // Chat Data Fetch
  private _fetchData() {
    //this.groupData = groupData;
    //this.chatData = chatData;
    //this.chatMessagesData = chatMessagesData;
    //this.contactData = contactData;    
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  closeReplay() {
    document.querySelector('.replyCard')?.classList.remove('show');
  }

//   onFocus() {
//     this.showEmojiPicker = false;
//   }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  onBlur() {
	//alert("Click the send button...");
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionTryAnother(){
	this.pnAnotherWay = true;
	this.TAWmobileNoType = this.mobileNoType;
	//this.TAWPreferredLanguage = this.preferredLanguage;
  }//actionTryAnother

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  backToHome(){
	this.isProcess = true;
	this.router.navigate(['/live-chat']);
	window.location.reload();
  }//backToHome()

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
  
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
//   async Action_GetAll_AgoraChat_for_Client() {
//     this.isProcess = true;
// 	const paramGetAll: IParam_GetAll_AgoraChat = { agoraIDF: Number(this.agoraIDP), clientIDF: Number(this.clientIDP) };
//     this._agoraChatService.Action_GetAll_AgoraChat_Client(paramGetAll).subscribe(
//       (r) => {
//         console.log(r);
// 		this.agoraAgentName = r["AgentNameData"][0].EmployeeName;
// 		this.AgentPicture = this.attachmentURL + "Franchise/" + this.employeeIDF + "/" + 0 + "/" + r["AgentNameData"][0].EmployeeProfileImage;
//         this.chatMessagesData = r["AgoraChatData"];
//         this.isProcess = false;
//       }, (error) => {
//         this.isProcess = false;
//         console.log(error);
//       }
//     );
//   }//Action_GetAll_AgoraChat_for_Employee()

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionUpdateByMobileType(){
	this.submitted = true;
	this.isProcess = true;

	this.f.MobileNumberType.setValue(this.TAWmobileNoType);
	//this.f.PreferredLanguage.setValue(this.TAWPreferredLanguage);

	var formData = new FormData();
    formData.append('AgoraDetailIDP', this.agoraDetailIDP.toString());
    formData.append('AgoraIDF', this.agoraIDP.toString());
	formData.append('MobileNumberType', (this.TAWmobileNoType == 1) ? "true" : "false");
	//formData.append('PreferredLanguage', this.TAWPreferredLanguage.toString());

	this._service.Action_Update_MobileNoType(formData).subscribe(
		async (r) => {
			if(r.status == "Fail"){
				SweetAlert.ActionFail(r.msg);
				this.pnContactForm != this.pnContactForm;
				this.pnChat = false;
				this.pnOTP = false;
				this.pnGrid = false;
				this.isProcess = false;
				this.isMissedCall = false;
				return;
			}
			else if(r.id <= 0) { SweetAlert.ActionFail(r.msg); this.isProcess = false; return; }
			else {
				if (r.status == "Done") {
					//SweetAlert.ActionSuccess("Please verify via OTP.");
					this.isProcess = false;
					this.pnAnotherWay = false;
					this.counter = 120;
					this.tick = 1000;
					setTimeout(() => { var c1 = <HTMLInputElement>document.querySelector("[autofocus]"); if (c1 != null) c1.focus(); }, 300);
					this.countDown = timer(0, this.tick).pipe(take(this.counter)).subscribe(() => {
						--this.counter;
						if (this.counter == 0) { this.countDown.unsubscribe(); }
					});
				}
			}
		}, (error) => {
			this.isProcess = false;
			console.log(error);
		}
	);
  }//actionUpdateByMobileType

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
}