import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//import { GoogleLoginProvider, SocialAuthServiceConfig} from 'angularx-social-login';


import { LoginComponent } from './login.component';

@NgModule({
  declarations: [
    LoginComponent
  ]
  ,imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ]
  // ,providers: [
  //   {
  //       provide: 'SocialAuthServiceConfig',
  //       useValue: {
  //           autoLogin: false,
  //           providers: [
  //               {
  //                   id: GoogleLoginProvider.PROVIDER_ID,
  //                   provider: new GoogleLoginProvider('890432786909-cpi642db45pvie1gfmnrcetp4cftba7r.apps.googleusercontent.com'),
  //               },
  //               // {
  //               //     id: FacebookLoginProvider.PROVIDER_ID,
  //               //     provider: new FacebookLoginProvider('561602290896109'),
  //               // },
  //               // {
  //               //     id: AmazonLoginProvider.PROVIDER_ID,
  //               //     provider: new AmazonLoginProvider('amzn1.application-oa2-client.f074ae67c0a146b6902cc0c4a3297935'),
  //               // },
  //               // {
  //               //     id: VKLoginProvider.PROVIDER_ID,
  //               //     provider: new VKLoginProvider('7624815'),
  //               // },
  //               // {
  //               //     id: MicrosoftLoginProvider.PROVIDER_ID,
  //               //     provider: new MicrosoftLoginProvider('0611ccc3-9521-45b6-b432-039852002705'),
  //               // },
  //               // {
  //               //     id: LinkedInLoginProvider.PROVIDER_ID,
  //               //     provider: new LinkedInLoginProvider("LinkedIn-client-Id", false, 'en_US')
  //               // },
  //           ],
  //           onError: (err) => {
  //               console.error(err);
  //           }
  //       } as SocialAuthServiceConfig,
  //   },
  //]
  ,exports: [LoginComponent]
  ,schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class LoginModule { }
