import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-service-form-template',
  templateUrl: './service-form-template.component.html',
  styleUrls: ['./service-form-template.component.scss']
})
export class ServiceFormTemplateComponent implements OnInit {

  apiUrl:string;
  submitted = false;
  isProcessSave: boolean = false;
  isProcessLoading: boolean = false;
  pnServiceTemplate: boolean = true;
  serviceTemplateList: any = [];
  
  serviceId: number;
  customerIdf: number;
  serviceName: string = '';

  public frmCtrl!: FormGroup;
  public templateFields!: FormArray;

  // returns all form groups under innerDetail
  get templateFieldsFormGroup() {
    return this.frmCtrl.get('innerDetail') as FormArray;
  }

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private injector: Injector, private fb: FormBuilder) {
    this.apiUrl = this.injector.get('APIURL');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.serviceId = params['sid'];
        this.customerIdf = params['cid'];
        this.serviceName = params['ServiceName'];
      }
    )

    this.actionGetService();
    
    this.frmCtrl = this.fb.group({
      CustomerIdp: [0],
      FirstName: [""],
      MiddleName: [""],
      LastName: [""],
      EmiratesID: [""],
      CompanyName: [""],
      EmailID: ["", [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      ContactNo: ["", [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(12)]],
      Address: [""],
      City: [""],
      State: [""],
      Country: [""],
      Remark: [""],
      //IsActive:[""],
      Attachments: [""],
      innerDetail: this.fb.array([])
    });
    
    // set templateFields to this field
    this.templateFields = this.frmCtrl.get('innerDetail') as FormArray;
    
    this.clearCtrl();

    // var temp = this.type.split("#");
    // for (let i = 0; i < temp.length-1; i++) {
    //   this.templateFields.push(this.createTemplateFields(''));
    //   this.templateFields.at(i).get('Type')?.setValue(temp[i+1]);
    //   this.templateFields.at(i).get('Attachments')?.setValue('');
    // }
  }
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  get f() { return this.frmCtrl.controls; }
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  createTemplateFields(data: any): FormGroup {
    return this.fb.group({
      Type: [(data == "") ? "" : ""],
      Attachments: [(data == "") ? "" : ""]
    });
  }// invoice formgroup
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  AddTemplateFields() {
    this.templateFields.push(this.createTemplateFields(''));
  }//add a invoice form group
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  // RemoveTemplateFields(index: any) {
  //   this.templateFields.removeAt(index);
  // }//remove invoice from group
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  getinnerDetailFormGroup(index: any): FormGroup {
    const formGroup = this.templateFields.controls[index] as FormGroup;
    return formGroup;
  }// get the formgroup under innerDetail form array
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  clearCtrl(){
    this.frmCtrl.reset();
    this.submitted = false;
    this.f.CustomerIdp.setValue(0);
    //this.f.IsActive.setValue(true);
  }//clearCtrl
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
	SavFile(event) {
		// if(event.target.files[0]) this.fileToUpload.push(event.target.files[0]);
		// else this.fileToUpload;
	}
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionGetService(){
    this.http.get(this.apiUrl + "/Service/Get/" + this.serviceId).subscribe(
      (data) => {
        this.serviceTemplateList = JSON.parse(data[0].ServiceTemplate);
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetService
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionSave(){
    this.submitted = true;
    if (this.frmCtrl.invalid) {
      return;
    }
    this.isProcessSave = true;
    let inputFile = (<HTMLInputElement>document.getElementById('importAttachmentPath'));
    const formData:any = new FormData();
    //var formData = new FormData();
    formData.append('CustomerIdp', (this.f.CustomerIdp.value?.toString() == null) ? '0' : this.f.CustomerIdp.value.toString());
    formData.append('FirstName', (this.f.FirstName.value == null) ? '' : this.f.FirstName.value);
    formData.append('MiddleName', (this.f.MiddleName.value == null) ? '' : this.f.MiddleName.value);
    formData.append('LastName', (this.f.LastName.value == null) ? '' : this.f.LastName.value);
    formData.append('CompanyName', (this.f.CompanyName.value == null) ? '' : this.f.CompanyName.value);
    formData.append('EmiratesID', (this.f.EmiratesID.value == null) ? '' : this.f.EmiratesID.value);
    formData.append('Address', (this.f.Address.value! == null) ? '' : this.f.Address.value!);
    formData.append('City', (this.f.City.value! == null) ? '' : this.f.City.value!);
    formData.append('State', (this.f.State.value == null) ? '' : this.f.State.value);
    formData.append('Country', (this.f.Country.value == null) ? '' : this.f.Country.value);
    formData.append('ContactNo', (this.f.ContactNo.value == null) ? '' : this.f.ContactNo.value);
    formData.append('EmailID', (this.f.EmailID.value == null) ? '' : this.f.EmailID.value);
    formData.append('Remark', (this.f.Remark.value == null) ? '' : this.f.Remark.value);
    formData.append('AttachmentsTypeDetails', JSON.stringify(this.templateFieldsFormGroup.value));
    //formData.append('IsActive', (this.f.IsActive.value == null) ? '' : this.f.IsActive.value);
      this.http.post(this.apiUrl + '/Admin/Customer/Save/' + this.serviceId, formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data => {
        const res : any = data;
        if(res.status == "Done"){
          this.clearCtrl();
          this.pnServiceTemplate = false;
        }
        // else{
        //   alert(res.msg);
        //   //this.toastr.error(res.msg, res.status);
        // }
        this.isProcessSave = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessSave = false;
      })
    )
  }//actionSave
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
}