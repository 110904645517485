<app-navbar-style-one></app-navbar-style-one>

<section class="checkout-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-md-12" *ngIf="isProcessLoading == false">

                <form class="needs-validation" [formGroup]="frmCtrlClient" autocomplete="off">
                    <section class="checkout-area pt-100">
                        <div class="container">
                            <div id="add-review">
                                <div class="review-form-wrapper">
                                    <h2>User Profile</h2>
                                    <div id="userProfileForm" class="row">
                                        <div class="col-md-3 form-group">
                                            <div class="row">
                                                <div class="col-md-12 form-group text-center">
                                                    <input type="file" id="importImagePath" accept=".jpg, .png, .jpeg" hidden (change)="inputFileChange($event)">
                                                    <label type="button" ngbTooltip="Upload Photo for User Picture" for="importImagePath"><img [src]="getSantizeUrl(UserPicture)" width="100%" style="height: 150px;" class="img-thumbnail img-fluid rounded"></label><span *ngIf="UserPicture != '/assets/images/Image-Icon.png'" class="image-delete-button" title="Image Delete" (click)="imageDelete()">x</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-9 form-group">
                                            <div class="row">
                                                <div class="col-md-12 form-group">
                                                    <label for="FirstName" class="form-label">{{FirstName}}<i>*</i></label>
                                                    <input class="form-control" type="text" placeholder="{{FirstName}}" formControlName="FirstName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.FirstName.invalid, 'is-valid': submitted &&  !f.FirstName.invalid }" required>
                                                </div>
                                                <div class="col-md-12 form-group">
                                                    <label for="LastName" class="form-label">{{LastName}}<i>*</i></label>
                                                    <input class="form-control" type="text" placeholder="{{LastName}}" formControlName="LastName"
                                                    [ngClass]="{ 'is-invalid': submitted && f.LastName.invalid, 'is-valid': submitted &&  !f.LastName.invalid }" required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-2">
                                        <div class="col-md-6 form-group">
                                            <label for="MobileNo" class="form-label">{{MobileNo}}<i>*</i></label>
                                            <input class="form-control" type="text" placeholder="{{MobileNo}}" formControlName="MobileNo" minlength="10" maxlength="12"
                                            [ngClass]="{ 'is-invalid': submitted && f.MobileNo.invalid, 'is-valid': submitted &&  !f.MobileNo.invalid }" required>
                                        </div>
                                        <div class="col-6 form-group">
                                            <label for="EmailID" class="form-label">{{EmailID}}<i>*</i></label>
                                            <input class="form-control" type="text" placeholder="{{EmailID}}" formControlName="EmailID"
                                            [ngClass]="{ 'is-invalid': submitted && f.EmailID.invalid, 'is-valid': submitted &&  !f.EmailID.invalid }" required>
                                        </div>
                                    </div>
                                    <!-- <div class="row mt-2">
                                        <div class="col-md-2 form-group mt-3">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="IsActive" formControlName="IsActive" checked>
                                                <label class="form-check-label form-label" for="IsActive">{{IsActive}}</label>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="row mt-2">
                                        <div class="col-md-12 text-center">
                                            <button type="button" class="btn btn-success" (click)="actionSave()" [disabled]="isProcessSave"><i [ngClass]="{'fa-refresh fa-spin fa mr-1' : isProcessSave}"></i> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <!-- <section class="checkout-area pb-100">
                        <div class="container">
                            <div id="add-review">
                                <div class="review-form-wrapper">
                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <button type="button" class="btn btn-success" (click)="actionSave()" [disabled]="isProcessSave"><i [ngClass]="{'fa-refresh fa-spin fa mr-1' : isProcessSave}"></i> Save</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> -->
                </form>

            </div>
            <div *ngIf="isProcessLoading == true" class="text-center"><i class="fa-refresh fa-spin fa ml-2" style="font-size: 40px;"></i></div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>