import { Component, Injector, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home-demo-one',
  templateUrl: './home-demo-one.component.html',
  styleUrls: ['./home-demo-one.component.scss']
})
export class HomeDemoOneComponent implements OnInit {

  apiUrl:string;
  serviceList: any;
  //languageSetting: number = 1;
  menuDetails: any;
  menuId: number;
  languageSetting: string = "0";

  // serviceId: number;
  // serviceName: string = 'Renew Residency Visa';
  // price:string;
  // remark:string;
  // type:string;
  // authType: number;

  constructor(private http: HttpClient, private injector: Injector, private route: ActivatedRoute) {
    this.apiUrl = this.injector.get('APIURL');
    if(localStorage.getItem('languageSetting') != null) this.languageSetting = localStorage.getItem('languageSetting');
    // if(localStorage.getItem("IsRTL") == 'rtl') this.languageSetting = 2;
    // else this.languageSetting = 1;
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.menuId =  params['mid'];
        // this.serviceId =  params['ServiceID'];
        // this.serviceName =  params['ServiceName'];
        // this.price = params['Price'];
        // this.remark = params['Remark'];
        // this.type = params['Type'];
        // this.authType = params['AuthType'];
      }
    )
    // this.actionGetMenuDetails();
    // this.actionGetAllService();
  }
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionGetMenuDetails(){
    var formData = new FormData();
    formData.append('PageMenuID', this.menuId.toString());
    formData.append('LanguageID', this.languageSetting);
    formData.append('FranchiseIDF', "16");

    this.http.post(this.apiUrl + "/PageMenu/Get_ActiveMenu_Details", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data) => {
        this.menuDetails = data[0].A3;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetMenuDetails
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionGetAllService(){
    var formData = new FormData();
    formData.append('languageSetting', this.languageSetting);

    this.http.post(this.apiUrl + "/Service/DDL", formData, this.injector.get('httpHeaderMultipart')).subscribe(
    //this.http.get(this.apiUrl + "/Service/DDL").subscribe(
      (data) => {
        this.serviceList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetAllService

  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  
  // customOptions: OwlOptions = {
  //     loop: true,
  //     nav: true,
  //     dots: true,
  //     autoplayHoverPause: true,
  //     autoplay: true,
  //     margin: 30,
  //     navText: [
  //         "<i class='flaticon-left-chevron'></i>",
  //         "<i class='flaticon-right-chevron'></i>"
  //     ],
  //     responsive: {
  //         0: {
  //             items: 1,
  //         },
  //         768: {
  //             items: 2,
  //         },
  //         1200: {
  //             items: 3,
  //         }
  //     },
  // }

}
