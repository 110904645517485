import { Component, Inject, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, NavigationStart } from '@angular/router';
import { DOCUMENT, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
//import { AuthService } from '@auth0/auth0-angular';
//import { Subscription } from 'rxjs';
//import { SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

declare let $: any;
export let browserRefresh = false;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {

    isAuthenticated: boolean;
    
    //subscription: Subscription;

    location: any;
    routerSubscription: any;
    isRTL: any;

    // user: SocialUser;
    // loggedIn: boolean;

    // APIUrl: string = 'https://localhost:44397';

    constructor(private router: Router, @Inject(DOCUMENT) private document: any,
        //, private authService: AuthService
        //, private authService: SocialAuthService
        ) {
            this.isAuthenticated = false;
        localStorage.setItem('IsRTL', 'ltr');
        this.isRTL = localStorage.getItem('IsRTL');

        // this.subscription = router.events.subscribe((event) => {
        //     if (event instanceof NavigationStart) {
        //       browserRefresh = !router.navigated;
        //     }
        // });
    }

    ngOnInit(){
        // this.authService.isAuthenticated$.subscribe((success: boolean) => {
        //     this.isAuthenticated = success;
        // });

        this.recallJsFuntions();
        // // @ts-ignore
        // google.accounts.id.initialize({
        //     client_id: "711285896249-1m3s6sdbalf14qkdcogtua77t900vn5o.apps.googleusercontent.com",
        //     callback: this.handleCredentialResponse.bind(this),
        //     auto_select: false,
        //     cancel_on_tap_outside: true,

        // });
        // // @ts-ignore
        // google.accounts.id.renderButton(
        // // @ts-ignore
        // document.getElementById("google-button"),
        //     { theme: "outline", size: "large", width: "100%" }
        // );
        // // @ts-ignore
        // google.accounts.id.prompt((notification: PromptMomentNotification) => {});

        // this.authService.authState.subscribe((user) => {
        //     this.user = user;
        //     this.loggedIn = (user != null);
        //     console.log(this.user);
            
        //   });

        if(localStorage.getItem("LanguageID") == "1"){
            const headEL0 = this.document.getElementsByTagName('head')[0];
            const newLinkEL0 = this.document.createElement('link');
            newLinkEL0.rel = "stylesheet";
            //newLinkEL.type = "text/css";
            newLinkEL0.href = "/assets/css/general.css";
            headEL0.appendChild(newLinkEL0);
            
            document.querySelector('html').setAttribute('lang', 'ar');
            document.querySelector('html').setAttribute('dir', 'rtl');
        }else{
            document.querySelector('html').setAttribute('lang', 'en');
            document.querySelector('html').setAttribute('dir', 'ltr');
        }
    }

    // async handleCredentialResponse(response: any) {
    //     // Here will be your response from Google.
    //     console.log(response);
    // }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            $.getScript('../assets/js/main.js');
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    // public signOut(): void {
    //     this.authService.logout({
    //       //returnTo: "http://localhost:65387/#/ese-family-services?sType=2",
    //     });
    // }
}