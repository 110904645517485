<app-navbar-style-one></app-navbar-style-one>

<!-- <app-homeone-banner></app-homeone-banner> -->

<div [innerHTML]="menuDetails"></div>
<!-- <div id="et-main-area">
    <div id="main-content">
        <article id="post-1133" class="post-1133 page type-page status-publish hentry">
            <div class="entry-content">
                <div class="et-l et-l--post">
                    <div class="et_builder_inner_content et_pb_gutters3">
                        <div class="et_pb_section et_pb_section_0 et_section_regular">
                            <div class="et_pb_row et_pb_row_0">
                                <div class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">
                                    <div class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">
                                        <div class="et_pb_text_inner">
                                            <h3><span style="color: #000000;">Emirates Schools Establishment</span>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="et_pb_row et_pb_row_1">
                                <div
                                    class="et_pb_column et_pb_column_1_2 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_0">


                                        <span class="et_pb_image_wrap "><img width="286" height="176"
                                                src="https://tadween.ae/wp-content/uploads/2023/02/svgexport-1.png"
                                                alt="" title="svgexport-1" class="wp-image-1155"></span>
                                    </div>
                                </div>
                                <div
                                    class="et_pb_column et_pb_column_1_2 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div class="et_pb_module et_pb_image et_pb_image_1">


                                        <span class="et_pb_image_wrap "><img loading="lazy" width="1000"
                                                height="391"
                                                src="https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1.png"
                                                alt="" title="TADWEEN (1)"
                                                srcset="https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1.png 1000w, https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1-980x383.png 980w, https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1-480x188.png 480w"
                                                sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1000px, 100vw"
                                                class="wp-image-1158"></span>
                                    </div>
                                </div>


                            </div>
                            <div class="et_pb_row et_pb_row_2">
                                <div
                                    class="et_pb_column et_pb_column_4_4 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">


                                        <div class="et_pb_text_inner">
                                            <p><span style="color: #000000;"><strong>Emirates Schools Establishment in collaboration with Tadween is offering exclusive portal for their employees to easily complete Emirates ID and Residence Service online at ease</strong></span></p>
                                        </div>
                                    </div>
                                    <div class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">
                                        <div class="et_pb_text_inner"><p><span style="color: #000000;"><strong>Please note that there are some important notes to be followed to complete the transaction successfully</strong></span></p>
                                            <ul>
                                                <li><span style="color: #000000;"><strong>The employee’s documents should include a salary certificate that is valid for not less than 15 days from the date of its issuance.</strong></span></li>
                                                <li><span style="color: #000000;"><strong>The validity of the employee’s passport should not be less than six months from the expiration date.</strong></span></li>
                                                <li><span style="color: #000000;"><strong>Employee shall confirm and acknowledge that all papers and data provided is correct</strong></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div class="et_pb_row et_pb_row_3">
                                <div
                                    class="et_pb_column et_pb_column_1_2 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough">


                                    <div class="et_pb_module et_pb_image et_pb_image_2">

                                        <a routerLink="/renew-residency-visa" [queryParams]="{ServiceID:serviceId,ServiceName:serviceName,Price:price,Remark:remark,Type:type,AuthType:authType}"><span
                                            class="et_pb_image_wrap "><img loading="lazy" width="512"
                                                height="512"
                                                src="https://tadween.ae/wp-content/uploads/2023/02/self-service.png"
                                                alt="" title="self service"
                                                srcset="https://tadween.ae/wp-content/uploads/2023/02/self-service.png 512w, https://tadween.ae/wp-content/uploads/2023/02/self-service-480x480.png 480w"
                                                sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 512px, 100vw"
                                                class="wp-image-1175"></span></a>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">


                                        <div class="et_pb_text_inner">
                                            <h3 style="text-align: left;"><strong>ESE Staff Self Residency
                                                    Services</strong></h3>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="et_pb_column et_pb_column_1_2 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                    <div class="et_pb_module et_pb_image et_pb_image_3">
                                        //<a href="/ese-family-services"><span
                                        <a href=""><span
                                            class="et_pb_image_wrap "><img loading="lazy" width="512"
                                            height="512"
                                            src="https://tadween.ae/wp-content/uploads/2023/02/family-services.png"
                                            alt="" title="family services"
                                            srcset="https://tadween.ae/wp-content/uploads/2023/02/family-services.png 512w, https://tadween.ae/wp-content/uploads/2023/02/family-services-480x480.png 480w"
                                            sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 512px, 100vw"
                                            class="wp-image-1178"></span></a>
                                    </div>
                                    <div
                                        class="et_pb_module et_pb_text et_pb_text_3  et_pb_text_align_left et_pb_bg_layout_light">


                                        <div class="et_pb_text_inner">
                                            <h3 style="text-align: center;"><strong>ESE Family Residency
                                                    Services</strong></h3>
                                        </div>
                                    </div>
                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </article>



    </div>

</div> -->


<!-- <div id="et-main-area">

        <div id="main-content">



            <article id="post-1133" class="post-1133 page type-page status-publish hentry">


                <div class="entry-content">
                    <div class="et-l et-l--post">
                        <div class="et_builder_inner_content et_pb_gutters3">
                            <div class="et_pb_section et_pb_section_0 et_section_regular">




                                <div class="et_pb_row et_pb_row_0">
                                    <div
                                        class="et_pb_column et_pb_column_4_4 et_pb_column_0  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                        <div
                                            class="et_pb_module et_pb_text et_pb_text_0  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div class="et_pb_text_inner">
                                                <h3><span style="color: #000000;">Emirates Schools Establishment</span>
                                                </h3>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="et_pb_row et_pb_row_1">
                                    <div
                                        class="et_pb_column et_pb_column_1_2 et_pb_column_1  et_pb_css_mix_blend_mode_passthrough">


                                        <div class="et_pb_module et_pb_image et_pb_image_0">


                                            <span class="et_pb_image_wrap "><img width="286" height="176"
                                                    src="https://tadween.ae/wp-content/uploads/2023/02/svgexport-1.png"
                                                    alt="" title="svgexport-1" class="wp-image-1155"></span>
                                        </div>
                                    </div>
                                    <div
                                        class="et_pb_column et_pb_column_1_2 et_pb_column_2  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                        <div class="et_pb_module et_pb_image et_pb_image_1">


                                            <span class="et_pb_image_wrap "><img loading="lazy" width="1000"
                                                    height="391"
                                                    src="https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1.png"
                                                    alt="" title="TADWEEN (1)"
                                                    srcset="https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1.png 1000w, https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1-980x383.png 980w, https://tadween.ae/wp-content/uploads/2023/02/TADWEEN-1-480x188.png 480w"
                                                    sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1000px, 100vw"
                                                    class="wp-image-1158"></span>
                                        </div>
                                    </div>


                                </div>
                                <div class="et_pb_row et_pb_row_2">
                                    <div
                                        class="et_pb_column et_pb_column_4_4 et_pb_column_3  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                        <div
                                            class="et_pb_module et_pb_text et_pb_text_1  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div class="et_pb_text_inner">
                                                <p><span style="color: #000000;"><strong>Emirates Schools Establishment
                                                            in collaboration with Tadween is offering exclusive portal
                                                            for their employees to process the following services online
                                                            at ease</strong></span></p>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div class="et_pb_row et_pb_row_3">
                                    <div
                                        class="et_pb_column et_pb_column_1_2 et_pb_column_4  et_pb_css_mix_blend_mode_passthrough">


                                        <div class="et_pb_module et_pb_image et_pb_image_2">

                                            <a *ngIf="serviceId == 1" routerLink="/renew-service" [queryParams]="{ServiceID:serviceId,ServiceName:serviceName,Price:price,Remark:remark,Type:type,AuthType:authType}"><span
                                                    class="et_pb_image_wrap "><img loading="lazy" width="512"
                                                        height="512"
                                                        src="https://tadween.ae/wp-content/uploads/2023/02/self-service.png"
                                                        alt="" title="self service"
                                                        srcset="https://tadween.ae/wp-content/uploads/2023/02/self-service.png 512w, https://tadween.ae/wp-content/uploads/2023/02/self-service-480x480.png 480w"
                                                        sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 512px, 100vw"
                                                        class="wp-image-1175"></span></a>
                                        </div>
                                        <div
                                            class="et_pb_module et_pb_text et_pb_text_2  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div class="et_pb_text_inner">
                                                <h3 style="text-align: left;"><strong>ESE Staff Self Residency
                                                        Services</strong></h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="et_pb_column et_pb_column_1_2 et_pb_column_5  et_pb_css_mix_blend_mode_passthrough et-last-child">


                                        <div class="et_pb_module et_pb_image et_pb_image_3">


                                            <a *ngIf="serviceId == 1" routerLink="/renew-service" [queryParams]="{ServiceID:serviceId,ServiceName:serviceName,Price:price,Remark:remark,Type:type,AuthType:authType}"><span
                                                class="et_pb_image_wrap "><img loading="lazy" width="512"
                                                    height="512"
                                                    src="https://tadween.ae/wp-content/uploads/2023/02/family-services.png"
                                                    alt="" title="family services"
                                                    srcset="https://tadween.ae/wp-content/uploads/2023/02/family-services.png 512w, https://tadween.ae/wp-content/uploads/2023/02/family-services-480x480.png 480w"
                                                    sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) 512px, 100vw"
                                                    class="wp-image-1178"></span></a>
                                        </div>
                                        <div
                                            class="et_pb_module et_pb_text et_pb_text_3  et_pb_text_align_left et_pb_bg_layout_light">


                                            <div class="et_pb_text_inner">
                                                <h3 style="text-align: center;"><strong>ESE Family Residency
                                                        Services</strong></h3>
                                            </div>
                                        </div>
                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                </div>


            </article>



        </div>
    </div> -->

<!-- <app-features></app-features> -->

<!-- <app-homeone-listings></app-homeone-listings> -->

<!-- <section class="category-area pb-70">
    <app-category></app-category>
</section> -->

<!-- <app-homeone-destinations></app-homeone-destinations> -->

<!-- <section class="feedback-area ptb-100">
    <app-feedback-style-one></app-feedback-style-one>
</section> -->

<section class="how-it-works-area pt-100 pb-70 bg-f9f9f9">
    <app-how-it-works></app-how-it-works>
</section>

<!-- <section class="feedback-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Services Galary</h2>
        </div>
        
        <div class="single-listings-box">
            <div class="listings-image">
                <div class="listings-image-slides">
                    <iframe src="/assets/3D-Galary/3d_Galary.html" height="500px" width="100%"></iframe>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- <app-homeone-blog></app-homeone-blog> -->

<!-- <app-app-download></app-app-download> -->

<app-footer-style-one></app-footer-style-one>