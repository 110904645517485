<div class="row">
    <div class="col-md-12">
        <h1> Login Result </h1>
        <!-- <ul *ngIf="auth.user$ | async as user">
            <li>{{ user.name }}</li>
            <li>{{ user.email }}</li>
        </ul> -->
    </div>
</div>


<div class="row">
    <div class="col-md-12">
        <!-- <div *ngIf="auth.isAuthenticated$ | async; else loggedOut">
            <h1> Login Successfully.</h1>
            <button type="button" class="btn btn-warning btn-lg" (click)="auth.logout()"> Logout</button>
        </div>
        <ng-template #loggedOut>
            <div><button type="button" class="btn btn-danger btn-lg" (click)="Login()"><i class='bx bxl-google'></i> Google</button></div>
        </ng-template> -->
    </div>
</div>