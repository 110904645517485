<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<div class="page-title-area" style="background-image: url(assets/img/page-title/page-title5.jpg);">
    <div class="container">
        <div class="page-title-content">
            <h2>{{serviceName}} To Apply</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
                <li>{{serviceName}}</li>
                <li>Checkout</li>
            </ul>
        </div>
    </div>
</div>

<!-- Start Checkout Area -->
<form class="needs-validation" [formGroup]="frmCtrl" autocomplete="off">
<section *ngIf="pnServiceTemplate" class="checkout-area pt-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <h3>Service Form Template</h3>
                <div class="row mt-2" *ngFor="let serviceTemplate of serviceTemplateList">
                    <div class="col-md-12 form-group" *ngIf="serviceTemplate.type == 'text'">
                        <label for="serviceTemplate.label" class="form-label">{{serviceTemplate.label}} <i *ngIf="serviceTemplate.required == true">*</i></label>
                        <input type="text" class="{{serviceTemplate.className}}" placeholder="Enter {{serviceTemplate.label}}" formControlName="{{serviceTemplate.label}}">
                        <!-- [ngClass]="{ 'is-invalid': submitted && f.FirstName.invalid, 'is-valid': submitted &&  !f.FirstName.invalid }" required autofocus -->
                    </div>
                    <div class="col-md-12 form-group" *ngIf="serviceTemplate.type == 'textarea'">
                        <label for="serviceTemplate.label" class="form-label">{{serviceTemplate.label}}</label>
                        <textarea placeholder="Enter {{serviceTemplate.label}}" class="{{serviceTemplate.className}}" rows="2" formControlName="{{serviceTemplate.label}}"></textarea>
                    </div>
                    <div class="col-md-12 form-group" *ngIf="serviceTemplate.type == 'select'">
                        <label for="{{serviceTemplate.label}}" class="form-label">{{serviceTemplate.label}}</label>
                        <ng-select formControlName="{{serviceTemplate.label}}" placeholder="Select {{serviceTemplate.label}}" [multiple]="false" [clearable]="false">
                            <!-- <ng-option *ngFor="let item of serviceTemplate.values" [value]="item.value">{{item.label}}</ng-option> -->
                        </ng-select>
                    </div>
                    <!-- <div class="col-md-12 form-group mt-3" *ngIf="serviceTemplate.type == 'text'">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="checkbox" id="{{serviceTemplate.label}}" formControlName="{{serviceTemplate.label}}" checked>
                            <label class="form-check-label" for="{{serviceTemplate.label}}">{{serviceTemplate.label}}</label>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="pnServiceTemplate" class="checkout-area p-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <h3>Required Documents</h3>
                <div formArrayName="innerDetail">
                    <div class="outcomes-list" *ngFor="let templateFields of templateFieldsFormGroup.controls; let i = index;">
                        <div [formGroupName]="i">
                            <div class="row">
                                <div class="col-md-3 form-group">
                                    <input type="text" class="form-control" formControlName="Type" readonly>
                                    <!-- <label for="{{templateFields.value.Type}}" class="form-label">{{templateFields.value.Type}}</label> -->
                                </div>
                                <div class="col-md-1 form-group"> : </div>
                                <div class="col-md-8 form-group">
                                    <input type="file" class="form-control" id="importAttachmentPath{{i}}" formControlName="Attachments" (change)="SavFile($event)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="Attachments" class="form-label">Attachments</label>
                        <input type="file" class="form-control" id="importAttachmentPath" formControlName="Attachments">
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>

<section *ngIf="pnServiceTemplate" class="checkout-area pb-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-success" (click)="actionSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</form>

<section *ngIf="!pnServiceTemplate" class="checkout-area ptb-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <h3 class="text-success" style="padding: 100px; padding-left: 250px;">Thank you for apply {{serviceName}} service.</h3>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>