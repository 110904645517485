import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { GlobalComponent } from '../Global/global-component';
import { IParam_GetAll_AgoraChat } from '../Models/IParam_GetAll_AgoraChat';
import { Param_Get_By_ID_IDF } from '../Models/IParam_Get_By_ID_IDF';
import { ITblAgoraChat } from '../Models/ITblAgoraChat';

@Injectable({
  providedIn: 'root'
})

export class TblAgoraChatService {

    apiUrl: string;
    
    /* - - -     CONSTRUCTOR      - - - */
    constructor(private http: HttpClient, private injector: Injector) {
        this.apiUrl = this.injector.get('APIURL') + '/api/TblAgoraChat';
    }
    
    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    //INSERT UPDATE
    Action_InsertUpdate(TblAgoraChat : ITblAgoraChat): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/agorachat-insert`, TblAgoraChat, { headers: GlobalComponent.header() });
    }
    
    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    //GET ALL FOR Client
    Action_GetAll_AgoraChat_Client(modelCommonGetAll : IParam_GetAll_AgoraChat): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/agorachat-get-all-for-client`, modelCommonGetAll, { headers: GlobalComponent.header() });
    }
    
}