import Swal from 'sweetalert2';
import swal from 'sweetalert2';

//ActionSaveSuccess
//ActionDeleteConfirm
//ActionDeleted
//ActionDeleteCancel
//ActionFail      (with error message from server, the reason)

//-------------- Special --------------
export function ActionSaveSuccess() {
    swal.fire({
        title: "Done",
        text: "Record saved successfully",
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
        showClass: {
            popup: 'animated shake'
        },
    });
}

// export async function ActionConfirm(_title: any,_confirmBtnText: any,_icon: string) {
//     if(_icon=='')_icon='warning';
    
//     const {value: deleteReply} = await swal.fire({
//         title: 'Are You Sure?',
//         text: _title,
//         icon: _icon,
//         showCancelButton: true,
//         confirmButtonColor: '#2F8BE6',
//         cancelButtonColor: '#F55252',
//         confirmButtonText: _confirmBtnText,
//         customClass: {
//             confirmButton: 'btn btn-warning',
//             cancelButton: 'btn btn-danger ml-1'
//         },
//         showClass: {
//             popup: 'animated shake'
//         },
//         buttonsStyling: false,
//         inputValidator: (value) => {
//             return value;
//         }
//     });
//     if(deleteReply!=true){
//         ActionDeleteCancel('Process cancled');
//     }
//     return deleteReply;
// }

export async function ActionConfirmCatalogue(_title: any, _text: any, _icon: any, _confirmButtonText: any) {
    
    const {value: reply} = await swal.fire({
        title: _title,
        text: _text,
        icon: _icon,
        showCancelButton: true,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: _confirmButtonText,
        customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger ml-1'
        },
        showClass: {
            popup: 'animated shake'
        },
        buttonsStyling: false,
        inputValidator: (value) => {
            return value;
        }
    });
    if(reply!=true){
        ActionDeleteCancel();
    }
    return reply;
}

export async function ActionDeleteConfirm() {
    const {value: deleteReply} = await swal.fire({
        title: 'Are You Sure?',
        text: "You won't to delete record!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: 'Yes, Delete It!',
        customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger ml-1'
        },
        buttonsStyling: false,
        inputValidator: (value) => {
            return value;
        }
    });
    if(deleteReply!=true){
        ActionDeleteCancel();
    }
    return deleteReply;
}


export async function ActionDeletePause_ConfirmReason(_text: any, _confirmButtonText: any) {
    const {value: deleteReply=''} = await swal.fire({
        input:'select',
        inputOptions: {
            'Other': 'Other',
            'Sold': 'Sold',
            'Scrapped': 'Scrapped',
            'Not available': 'Not available',
            'Wrong entry': 'Wrong entry',
          },
        inputPlaceholder: 'Select some reason',
        title: 'Are You Sure?',
        text: _text,
        //text: "You won't to delete record!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: _confirmButtonText,
        //confirmButtonText: 'Yes, Delete It!',
        customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger ml-1'
        },
        showClass: {
            popup: 'animated shake'
        },
        buttonsStyling: false,
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value != '') {
                    resolve('');
                }else {
                    resolve('You need to select some reason');
                }
            })
        }
    })
    if(deleteReply==''){
        ActionDeleteCancel('Process has been cancelled.');
    }
    return deleteReply;
}

export async function ActionDeleteFeed_ConfirmReason() {
    const {value: deleteReply=''} = await swal.fire({
        input:'select',
        inputOptions: {
            'Requirement fullfilled': 'Requirement fullfilled',
            'No more needed': 'No more needed',
            'Wrong entry': 'Wrong entry',
          },
        inputPlaceholder: 'Select some reason',
        title: 'Are You Sure?',
        text: "You won't to delete record!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: 'Yes, Delete It!',
        customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger ml-1'
        },
        showClass: {
            popup: 'animated shake'
        },
        buttonsStyling: false,
        inputValidator: (value) => {
            return new Promise((resolve) => {
                if (value != '') {
                    resolve('');
                }else {
                    resolve('You need to select some reason');
                }
            })
        }
    })
    if(deleteReply==''){
        ActionDeleteCancel();
    }
    return deleteReply;
}

export function ActionDeleted() {
    swal.fire({
        icon: "success",
        title: 'Deleted!',
        text: 'Record has been deleted.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
            confirmButton: 'btn btn-success'
        },
        showClass: {
            popup: 'animated shake'
        },
    })
}

export function ActionPinned() {
    swal.fire({
        icon: "success",
        title: 'Pinned!',
        text: 'Template has been pinned.',
        showConfirmButton: false,
        timer: 1500,
        customClass: {
            confirmButton: 'btn btn-success'
        },
        showClass: {
            popup: 'animated shake'
        },
    })
}

export function ActionDeleteCancel(_text='Delete process has been cancelled.') {
    swal.fire({
        title: 'Cancelled',
        text: _text,
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
        customClass: {
            confirmButton: 'btn btn-success'
        },
        showClass: {
            popup: 'animated shake'
        },
    })
}

export function ActionFail(_failReason: any, _title='Error') {
    swal.fire({
        title: _title,
        text: _failReason,
        icon: "error",
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        showClass: {
            popup: 'animated shake'
        },
    })
}

export function ActionWarning(_failReason: any) {
    swal.fire({
        title: "Warning",
        text: _failReason,
        icon: "warning",
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        showClass: {
            popup: 'animated shake'
        },
    });
}

export function ActionSuccess(_successReason: any) {
    swal.fire({
        title: "Done",
        text: _successReason,
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
        showClass: {
            popup: 'animated shake'
        },
    });
}

export function ActionComingSoon() {
    swal.fire({
        text: "Coming Soon...",
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        showClass: {
            popup: 'animated shake'
        },
    });
}

// Warning
export function ActionSelectionFail() {
    swal.fire({
        title: "Warning!",
        text: "Please select ONE record to delete.",
        icon: "warning",
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        showClass: {
            popup: 'animated shake'
        },
    });
}

export function ActionSelectionFailCompareMachine() {
    swal.fire({
        title: "Warning!",
        text: "Please select minimum 2 or maximum 3 machines.",
        icon: "warning",
        customClass: {
            confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        showClass: {
            popup: 'animated shake'
        },
    });
}

//Start flash Message
export async function ActionFlashMsg(_title: any, _msg: any, _btnType: number, _confirmBtnURL: string | URL | undefined, _cancelBtnURL: string | URL | undefined) {
    var _confirmBtnText, _cancelBtnText;

    if(_btnType == 1){ _confirmBtnText="Ok"; }
    else if(_btnType == 2){ _confirmBtnText="Ok"; _cancelBtnText="Cancel" }
    else if(_btnType == 3){ _confirmBtnText="Yes"; _cancelBtnText="No" }

    let alertOpt = {
        title: _title,
        //text: _msg,
        html: _msg,
        icon: "success",
        //showCancelButton: true,
        confirmButtonColor: '#2F8BE6',
        cancelButtonColor: '#F55252',
        confirmButtonText: _confirmBtnText,
        cancelButtonText: _cancelBtnText,
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1'
        },
        showClass: {
            popup: 'animated shake'
        },
        buttonsStyling: false,
        inputValidator: (value: any) => {
            return value;
        }
    }
    const obj = JSON.parse(JSON.stringify(alertOpt));
    
    if(_btnType!=1) obj["showCancelButton"] = true;
	
    const {value: msgReply} = await swal.fire(obj);
    if(msgReply==true && _confirmBtnURL!=undefined){
        window.open(_confirmBtnURL, '_blank');
    }
    else if(_cancelBtnURL!=undefined) {
        window.open(_cancelBtnURL, '_blank');
    }
    Swal.close();
}
//End flash Message