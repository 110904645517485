import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    apiUrl:string;
    serviceNameList: any;
    serviceList: any;
    languageSetting: string = "0";

    constructor(private http: HttpClient, private injector: Injector) {
        this.apiUrl = this.injector.get('APIURL');        
        if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
    }

    ngOnInit(): void {
        this.actionGetAllService();
        this.resetOption = [this.options[0]];
    }

    //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
    actionGetAllService(){
        var formData = new FormData();
        formData.append('LanguageID', this.languageSetting);
    
        this.http.post(this.apiUrl + "/Service/DDL", formData, this.injector.get('httpHeaderMultipart')).subscribe(
      //this.http.get(this.apiUrl + "/Service/DDL").subscribe(
        (data: any) => {
            this.serviceList = data;
            this.serviceNameList = data.map(s => s.A1);
        },
        (error) => {
          console.log(error);
        }
      );
    }//actionGetAllService

    mainBannerContent = [
        {
            title: 'Find Serviceby',
            paragraph: 'Expolore top-rated attractions, activities and more...',
            //popularSearchList: this.serviceList
            // [
            //     {
            //         title: 'Restaurants',
            //         link: 'grid-listings-left-sidebar'
            //     },
            //     {
            //         title: 'Events',
            //         link: 'grid-listings-left-sidebar'
            //     },
            //     {
            //         title: 'Clothing',
            //         link: 'grid-listings-left-sidebar'
            //     },
            //     {
            //         title: 'Bank',
            //         link: 'grid-listings-left-sidebar'
            //     },
            //     {
            //         title: 'Fitness',
            //         link: 'grid-listings-left-sidebar'
            //     },
            //     {
            //         title: 'Bookstore',
            //         link: 'grid-listings-left-sidebar'
            //     }
            // ]
        }
    ]

    // Category Select
    singleSelect: any = [];
    multiSelect: any = [];
    stringArray: any = [];
    objectsArray: any = [];
    resetOption: any;
    config = {
        displayKey: "name",
        search: true
    };
    options = [
        // Type here your category name
        {
            name: "Restaurants",
        },
        {
            name: "Events",
        },
        {
            name: "Clothing",
        },
        {
            name: "Bank",
        },
        {
            name: "Fitness",
        },
        {
            name: "Bookstore",
        }
    ];
    searchChange($event) {
        console.log($event);
    }
    reset() {
        this.resetOption = [];
    }

}