import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ITblAgoraDetail } from '../Models/ITblAgoraDetail';
import { Observable } from 'rxjs';
import { GlobalComponent } from '../Global/global-component';
import { IParamAgoraDetailLeave } from '../Models/IParamAgoraDetailLeave';
import { IParam_Get_By_ID } from '../Models/IParam_Get_By_ID';
import { Param_Get_By_ID_IDF } from '../Models/IParam_Get_By_ID_IDF';
import { IParam_Verify_OTP } from '../Models/IParam_Verify_OTP';

@Injectable({
  providedIn: 'root'
})

export class AgoraDetailService {
  apiUrl: string;

  /* - - -     CONSTRUCTOR      - - - */
  constructor(private http: HttpClient, private injector: Injector) {
    //this.attachmentURL = this.injector.get('AttachmentURL');
    this.apiUrl = this.injector.get('APIURL') + '/api/TblAgoraDetail';
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  //INSERT UPDATE
  Action_InsertUpdate(TblAgoraDetail: ITblAgoraDetail): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Agoradetail/Insert`, TblAgoraDetail, { headers: GlobalComponent.header() });
  }
  
  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  //INSERT UPDATE v2
  Action_InsertUpdate_v2(TblAgoraDetail: ITblAgoraDetail): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Agoradetail/Insert-v2`, TblAgoraDetail, { headers: GlobalComponent.header() });
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  //LEAVE
  Action_Leave(param: IParamAgoraDetailLeave): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Agoradetail/Leave`, param, { headers: GlobalComponent.header() });
  }
  
  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  //MissedCall
  Action_MissedCall(param: Param_Get_By_ID_IDF): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Agoradetail/missed-call`, param, { headers: GlobalComponent.header() });
  }

  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  //Verify OTP
  Action_Verify_OTP(TblAgoraDetail: IParam_Verify_OTP): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Agoradetail/Verify-OTP`, TblAgoraDetail, { headers: GlobalComponent.header() });
  }//Verify OTP
  
  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  //INSERT Update_MobileNoType
  Action_Update_MobileNoType(obj: FormData): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/Agoradetail/Update-MobileNoType`, obj, { headers: GlobalComponent.header() });
  }

}
