<div class="row chat-popup">
  <div class="col-md-12">
    <div class="">
      <div class="wmc" onclick="showHide_ChatBox(this, '')">
        <div class="et-profile-custom-container et-mask-bg-dark">
          <div>
            <img class="logo" src="assets/img/logo-light.png" alt="">
          </div>
          <h4 class="et-profile-custom-text">Let's talk</h4>
        </div>
      </div>
      <!-- <div class="card chat">
        <div class="card-header text-end">
          <i class="fa fa-angle-down text-white" onclick="showHide_ChatBox(this, '.wmc')"></i>
        </div>
        <div class="card-body">
          <div class="et-magazine-user-pic mt-1">
            <img class="et-magazine-user-pic-img" src="assets/img/logo-light.png" alt="">
          </div>
          <div class="text-center">
            <h4>Tadween</h4>
            <p class="text-center">Simply Meet Online Agents</p>
          </div>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tabVideoCall" role="tabpanel" aria-labelledby="btVideoCall">
              <div class="row justify-content-center" id="myTab" role="tablist">
                <div class="col-md-8 text-center" role="presentation">
                  <h4 id="headingChat"> Let's Talk</h4>
                  <button class="btn btn-primary col-12" id="btContact" data-bs-toggle="tab"
                    onclick="showHide_ChatBox(this, '.btContact')" data-bs-target="#tabContact" type="button" role="tab"
                    aria-controls="tabContact"> <i class="fa fa-video-camera me-2 "></i> Start Video
                    Call</button>
                </div>
              </div>
            </div>
            <div class="tab-pane p-4 fade show" id="tabContact" role="tabpanel" aria-labelledby="btContact">
              <form class="needs-validation" [formGroup]="frmCtrl" autocomplete="off" id="frmCtrl">
                <div class="row" id="pnContactForm">
                  <div class="col-md-12 mt-2">
                    <input type="text" formControlName="CustomerName" id="tb_Contact_Name" class="form-control"
                      placeholder="Name"
                      [ngClass]="{ 'is-invalid': submitted && f.CustomerName.invalid, 'is-valid': submitted &&  !f.CustomerName.invalid }">
                    <span id="err_tbContact_Name" class="text-danger" style="display: none;">Enter Name</span>
                  </div>
                  <div class="col-md-12 mt-2">
                    <input type="text" formControlName="CustomerEmail" id="tb_Contact_Email" class="form-control "
                      placeholder="example@email.com"
                      [ngClass]="{ 'is-invalid': submitted && f.CustomerEmail.invalid, 'is-valid': submitted &&  !f.CustomerEmail.invalid }">
                    <span id="err_tbContact_Email" class="text-danger" style="display: none;">Enter Email</span>
                    <span id="err_tbContact_Email1" class="text-danger" style="display: none;">Enter Valid Email
                      Address</span>
                  </div>
                  <div class="col-md-12 mt-2">
                    <input type="mobile" formControlName="CustomerMobileNo" id="tb_Contact_Mobile" class="form-control "
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      placeholder="+971 25754435" minlength="10" maxlength="13"
                      [ngClass]="{ 'is-invalid': submitted && f.CustomerMobileNo.invalid, 'is-valid': submitted &&  !f.CustomerMobileNo.invalid }">
                    <span id="err_tbContact_Mobile" class="text-danger" style="display: none;">Enter Mobile
                      Number</span>
                  </div>
                  <div class="col-md-12 mt-3">
                    <button id="tbContact" class="btn btn-primary col-md-12" (click)="actionSave()">Start Video
                      Call</button>
                  </div>
                </div>
              </form>
              <div class="row video-group">
                <div class="col">
                  <p id="local-player-name" class="player-name"></p>
                  <div id="local-player" class="player"></div>
                </div>
                <div class="w-100"></div>
                <div class="col">
                  <div id="remote-playerlist"></div>
                </div>
                <p class="isLoading">Loading... Please wait...</p>
                <button id="leave" type="button" class="btn btn-primary btn-sm mt-2"
                  (click)="Action_Leave()">Leave</button>
              </div>
              <div id="pnContactThankyou" style="display: none;">
                <div class="row">
                  <div class="col-md-12">
                    <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
                      class="swal2-popup swal2-modal swal2-icon-success swal2-show" tabindex="-1" role="dialog"
                      aria-live="assertive" aria-modal="true" style="display: grid;">
                      <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;">
                        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                        </div>
                        <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);">
                        </div>
                      </div>
                      <h2 class="swal2-title" id="swal2-title" style="display: block;">Your Request was sent
                        successfully
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
          </ul>
        </div>
      </div> -->
      <!-- </div> -->
    </div>
  </div>
</div>



<!-- <div class="row chat-popup">
  <div class="col-md-12">
    <div class="">
      <div class="wmc" onclick="showHide_ChatBox(this, '')">
        <div class="et-profile-custom-container et-mask-bg-dark">
          <div>
            <img class="logo" src="assets/img/logo-light.png" alt="">
          </div>
          <h4 class="et-profile-custom-text">Let's talk</h4>
        </div>
      </div> -->
      <!-- <div class="card chat">
        <div class="card-header text-end">
          <i class="fa fa-angle-down text-white" onclick="showHide_ChatBox(this, '.wmc')"></i>
        </div>
        <div class="card-body">
          <div class="et-magazine-user-pic mt-1">
            <img class="et-magazine-user-pic-img" src="assets/img/logo-light.png" alt="">
          </div>
          <div class="text-center">
            <h4>Tadween</h4>
            <p class="text-center">Simply Meet Online Agents</p>
          </div>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="tabVideoCall" role="tabpanel" aria-labelledby="btVideoCall">
              <div class="row justify-content-center" id="myTab" role="tablist">
                <div class="col-md-8 text-center" role="presentation">
                  <h4 id="headingChat"> Let's Talk</h4>
                  <button class="btn btn-primary col-12" id="btContact" data-bs-toggle="tab"
                    onclick="showHide_ChatBox(this, '.btContact')" data-bs-target="#tabContact" type="button" role="tab"
                    aria-controls="tabContact"> <i class="fa fa-video-camera me-2 "></i> Start Video
                    Call</button>
                </div>
              </div>
            </div>
            <div class="tab-pane p-4 fade show" id="tabContact" role="tabpanel" aria-labelledby="btContact">
              <form class="needs-validation" [formGroup]="frmCtrl" autocomplete="off" id="frmCtrl">
                <div class="row" id="pnContactForm">
                  <div class="col-md-12 mt-2">
                    <input type="text" formControlName="CustomerName" id="tb_Contact_Name" class="form-control"
                      placeholder="Name"
                      [ngClass]="{ 'is-invalid': submitted && f.CustomerName.invalid, 'is-valid': submitted &&  !f.CustomerName.invalid }">
                    <span id="err_tbContact_Name" class="text-danger" style="display: none;">Enter Name</span>
                  </div>
                  <div class="col-md-12 mt-2">
                    <input type="text" formControlName="CustomerEmail" id="tb_Contact_Email" class="form-control "
                      placeholder="example@email.com"
                      [ngClass]="{ 'is-invalid': submitted && f.CustomerEmail.invalid, 'is-valid': submitted &&  !f.CustomerEmail.invalid }">
                    <span id="err_tbContact_Email" class="text-danger" style="display: none;">Enter Email</span>
                    <span id="err_tbContact_Email1" class="text-danger" style="display: none;">Enter Valid Email
                      Address</span>
                  </div>
                  <div class="col-md-12 mt-2">
                    <input type="mobile" formControlName="CustomerMobileNo" id="tb_Contact_Mobile" class="form-control "
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      placeholder="+971 25754435" minlength="10" maxlength="13"
                      [ngClass]="{ 'is-invalid': submitted && f.CustomerMobileNo.invalid, 'is-valid': submitted &&  !f.CustomerMobileNo.invalid }">
                    <span id="err_tbContact_Mobile" class="text-danger" style="display: none;">Enter Mobile
                      Number</span>
                  </div>
                  <div class="col-md-12 mt-3">
                    <button id="tbContact" class="btn btn-primary col-md-12" (click)="actionSave()">Start Video
                      Call</button>
                  </div>
                </div>
              </form>
              <div class="row video-group">
                <div class="col">
                  <p id="local-player-name" class="player-name"></p>
                  <div id="local-player" class="player"></div>
                </div>
                <div class="w-100"></div>
                <div class="col">
                  <div id="remote-playerlist"></div>
                </div>
                <p class="isLoading">Loading... Please wait...</p>
                <button id="leave" type="button" class="btn btn-primary btn-sm mt-2"
                  (click)="Action_Leave()">Leave</button>
              </div>
              <div id="pnContactThankyou" style="display: none;">
                <div class="row">
                  <div class="col-md-12">
                    <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
                      class="swal2-popup swal2-modal swal2-icon-success swal2-show" tabindex="-1" role="dialog"
                      aria-live="assertive" aria-modal="true" style="display: grid;">
                      <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;">
                        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                        </div>
                        <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                        <div class="swal2-success-ring"></div>
                        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);">
                        </div>
                      </div>
                      <h2 class="swal2-title" id="swal2-title" style="display: block;">Your Request was sent
                        successfully
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ul class="nav nav-tabs" id="myTab" role="tablist">
          </ul>
        </div>
      </div> -->
      <!-- </div> -->
    <!-- </div>
  </div>
</div> -->