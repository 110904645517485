import { Component, OnInit } from '@angular/core';
//import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  user: any;

  constructor(
    //public auth: AuthService
    ) {
  }

  ngOnInit(): void {
    this.user = {};
  }


  Login(){
    // this.auth.loginWithRedirect();
    // setTimeout(() => {
    //   this.getData();
    // }, 500);
  }

  getData(){
    // this.auth.user$.subscribe((success: any) => {
    //   //debugger;
    //   this.user = success;
    // });
    //console.log(this.user);
  }
}
