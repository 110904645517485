import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-footer-style-one',
    templateUrl: './footer-style-one.component.html',
    styleUrls: ['./footer-style-one.component.scss']
})
export class FooterStyleOneComponent implements OnInit {

    apiUrl:string;
    httpHeaderMultipart: any;
    isRTL: boolean = false;
    userID: string = "";
    userName: string = "";
    languageSetting: string = "0";
    menuList: any;

    constructor(private http: HttpClient, private injector: Injector, private route: ActivatedRoute) {
        this.apiUrl = this.injector.get('APIURL');
        this.httpHeaderMultipart = this.injector.get('httpHeaderMultipart');
        if(localStorage.getItem('UserID') != null){
            this.userID = localStorage.getItem('UserID');
            this.userName = localStorage.getItem('UserName');
        }
        if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
    }

    ngOnInit(): void {
        this.actionGetAll_ActiveMenu();
    }

    //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
    actionGetAll_ActiveMenu(){
      var formData = new FormData();
      formData.append('IsQuickLink', "1");
      formData.append('LanguageID', this.languageSetting);
      formData.append('FranchiseIDF', "16");
  
      this.http.post(this.apiUrl + "/PageMenu/Get_ActiveMenu", formData, this.injector.get('httpHeaderMultipart')).subscribe(
        (data) => {
          this.menuList = data;
        },
        (error) => {
          console.log(error);
        }
      );
    }//actionGetAll_ActiveMenu

}