<app-navbar-style-one></app-navbar-style-one>

<div class="row" style="margin-top: 120px;">
    <div class="col-md-2"></div>
    <div class="col-md-8 text-center">
        <h1><strong>{{serviceTypeName}}</strong></h1>
        <hr>
        <!-- <div *ngIf="socialAuthService.authState | async as socialUser">
            <p>Hello, {{socialUser.name}}!</p>
            <p>Your email: {{socialUser.email}}</p>
            <button (click)="logout()">Logout</button>
          </div> -->
        <div class="live-preview serviceList">
            <!-- <div class="table-responsive"> -->
                <table class="table table-striped table-hover mt-5 mb-5">
                    <tbody>
                        <tr *ngFor="let service of serviceList">
                            <td class="col-6 text-start"><strong>{{service.A1}}</strong></td>
                            <td class="col-3"><div class="arrow"> ➜ </div></td>
                            <td class="col-3">
                                <button *ngIf="userID != '' && !(service.ServiceTypeLink > 0)" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" id="btGetDetails" [routerLink]="['/single-products2']" [queryParams]="{ServiceID:service.ID,ServiceName:service.A1,Price:price,Remark:remark,Type:type,AuthType:authType}"> {{applyHere}} </button>
                                <button *ngIf="userID != '' && service.ServiceTypeLink > 0" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" [routerLink]="['/ese-family-services']" [queryParams]="{sType:service.ServiceTypeLink}"> {{applyHere}} </button>
                                <button *ngIf="userID == ''" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" (click)="openModal()" data-bs-toggle="modal" data-bs-target="#loginRegisterModal"> {{applyHere}} </button>
                                <!-- <app-login-button></app-login-button> -->
                                <!-- <button *ngIf="auth.isAuthenticated$ | async" title="Service to apply" type="button" class="btn btn-md btn-outline-primary" id="btGetDetails" [routerLink]="['/single-products2']" [queryParams]="{ServiceID:service.ID,ServiceName:service.A1,Price:price,Remark:remark,Type:type,AuthType:authType}"> Apply Here </button> -->
                                <!-- <button *ngIf="(auth.isAuthenticated$ | async) == false" title="Service to apply for login" type="button" class="btn btn-md btn-outline-primary" data-bs-toggle="modal" data-bs-target="#loginRegisterModal"> Apply Here </button> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            <!-- </div> -->
        </div>
    </div>
    <div class="col-md-2"></div>
</div>

<app-footer-style-one></app-footer-style-one>

<app-login [IsOpenLoginView]=isOpenLoginView></app-login>

<!-- Start Login with Google Or USE Pass Modal -->
<!-- <div class="modal fade loginModal" id="loginModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li> -->
                <!-- <li class="nav-item">
                    <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                </li> -->
            <!-- </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <div class="login-with-account">
                            <span>Login with</span>
                            <ul> -->
                                <!-- <ul *ngIf="auth.user$ | async as socialUser">
                                    <li>{{ socialUser.name }}</li>
                                    <li>{{ socialUser.email }}</li>
                                  </ul> -->
                                <!-- <li *ngIf="!socialUser"> -->
                                    <!-- <div class="g-signin2" data-onsuccess="onSignIn" data-longtitle="true"></div> -->
                                    <!-- <div id="my-signin2"></div> -->
                                    <!-- <asl-google-signin-button type="standard" theme="outline" size="medium" text="signin_with" locale="zh_CN" width=400></asl-google-signin-button> -->
                                    <!-- <asl-google-signin-button data-type='icon' data-size='medium' data-context="use"> Login </asl-google-signin-button> -->
                                <!-- </li>
                                <li *ngIf="socialUser"><div class="card-block">
                                    <button class="btn" (click)="refreshGoogleToken()">
                                      Refresh google token
                                    </button>
                                  </div></li> -->
                                <!-- <li><asl-google-signin-button type='icon' size='medium'></asl-google-signin-button></li> -->

                                <!-- <li><button type="button" class="btn btn-danger btn-lg" (click)="loginWithRedirect()"><i class='bx bxl-google'></i> Google</button></li> -->
                                <!-- <li><button class="btn btn-primary btn-block" (click)="attemptLogin()">Log in</button></li> -->
                                <!-- <li><button class="btn btn-primary btn-block" (click)="auth.loginWithRedirect()">Log in</button></li> -->
                                <!-- <li><button class="btn btn-primary btn-block" (click)="auth.loginWithRedirect()">Log in</button></li> -->
                                <!-- <li><button class="btn btn-primary btn-block" (click)="auth.loginWithRedirect()">Log in</button></li> -->
                                <!-- <li><button type="button" class="btn btn-danger btn-lg" (click)="login()"><i class='bx bxl-google'></i> Google</button></li> -->
                                <!-- <li><button type="button" class="btn btn-secondary btn-lg"> UAE Pass</button></li> -->

                                <!-- <img src="{{ socialUser.photoUrl }}" referrerpolicy="no-referrer">
                                <div>
                                <h4>{{ socialUser.name }}</h4>
                                <p>{{ socialUser.email }}</p>
                                </div> -->
                            <!-- </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Login with Google Or USE Pass Modal -->