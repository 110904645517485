import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {

  apiUrl:string;
  serviceList: any;
  languageSetting: string = "0";

  constructor(private http: HttpClient, private injector: Injector) {
    this.apiUrl = this.injector.get('APIURL');
    if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
  }
  ngOnInit(): void {
      this.actionGetAllService();
  }
  //= = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = =
  actionGetAllService(){
    var formData = new FormData();
    formData.append('LanguageID', this.languageSetting);

    this.http.post(this.apiUrl + "/Service/DDL", formData, this.injector.get('httpHeaderMultipart')).subscribe(
    //this.http.get(this.apiUrl + "/Service/DDL").subscribe(
      (data) => {
        this.serviceList = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetAllService
  sectionTitle = [
      {
          title: 'Browse Businesses by Service',
          paragraph: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.'
      }
  ]
  // singleServiceBox = [
  //     {
  //         icon: 'flaticon-cooking',
  //         title: 'Restaurant',
  //         numberOfPlaces: '16 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-hotel',
  //         title: 'Hotel',
  //         numberOfPlaces: '42 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-exercise',
  //         title: 'Fitness',
  //         numberOfPlaces: '11 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-commerce',
  //         title: 'Shopping',
  //         numberOfPlaces: '24 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-cleansing',
  //         title: 'Beauty & Spa',
  //         numberOfPlaces: '8 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-calendar',
  //         title: 'Events',
  //         numberOfPlaces: '12 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-heart-1',
  //         title: 'Health Care',
  //         numberOfPlaces: '16 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-airport',
  //         title: 'Travel & Public',
  //         numberOfPlaces: '8 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-car-insurance',
  //         title: 'Auto Insurance',
  //         numberOfPlaces: '10 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-attorney',
  //         title: 'Attorneys',
  //         numberOfPlaces: '25 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-plumber',
  //         title: 'Plumbers',
  //         numberOfPlaces: '5 Places',
  //         link: 'grid-listings-left-sidebar'
  //     },
  //     {
  //         icon: 'flaticon-more-1',
  //         title: 'More Categories',
  //         link: 'grid-listings-left-sidebar'
  //     }
  // ]

}