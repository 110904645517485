import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as SweetAlert from 'src/app/shared/data/sweet-alerts';

import { ITblAgoraDetail } from 'src/app/Models/ITblAgoraDetail';
import { AgoraDetailService } from 'src/app/Services/agoraDetail.service';
import { DatePipe } from '@angular/common';
import { IParamAgoraDetailLeave } from 'src/app/Models/IParamAgoraDetailLeave';
import { IParam_Get_By_ID } from 'src/app/Models/IParam_Get_By_ID';
import { Param_Get_By_ID_IDF } from 'src/app/Models/IParam_Get_By_ID_IDF';

declare var joinChannel: any;
declare var showHide_ChatBox: any;
declare var leave: any;
@Component({
	selector: 'app-chat-bot',
	templateUrl: './chat-bot.component.html',
	styleUrls: ['./chat-bot.component.css']
})
export class ChatBotComponent implements OnInit {

	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	  Local Variable
	  - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	dataList: Array<any> = [];
	searchKeyword: string = '';
	pnGrid: Boolean = true;
	submitted = false;
	pageCrr: number = 1;
	agoraDetailIDP: number = 0;
	agoraIDP: number = 0;
	isProcess: boolean = true;
	isMissedCall: boolean = false;

	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	Constructor InIt
	- - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	constructor(private _service: AgoraDetailService, public datepipe: DatePipe) {

	}

	ngOnInit() {
		// setTimeout(() => {
		// 	this.AddNew();
		// }, 500);
	}

	/*- - - - - - - - - - - - - - - - - - - - - - - - - - -
	Form Group
	- - - - - - - - - - - - - - - - - - - - - - - - - - -*/
	// frmCtrl = new FormGroup({
	// 	AgoraDetailIDP: new FormControl(0),
	// 	AgoraIDF: new FormControl(),
	// 	UserIDF: new FormControl(),
	// 	EmployeeIDF: new FormControl(),
	// 	PartnerIDF: new FormControl(),
	// 	RecordingID: new FormControl(),
	// 	CustomerName: new FormControl('', [Validators.required]),
	// 	CustomerEmail: new FormControl('', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
	// 	CustomerMobileNo: new FormControl('', [Validators.required, Validators.pattern("^[0-9]*$")]),
	// 	DateJoinUser: new FormControl(),
	// 	DateJoinEmployee: new FormControl(),
	// 	DateEndUser: new FormControl(),
	// 	DateEndEmployee: new FormControl(),
	// });
	// get f() { return this.frmCtrl.controls; }
	// //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	// AddNew() {
	// 	this.isProcess = false;
	// 	this.pnGrid = false;
	// 	this.submitted = false;

	// 	this.frmCtrl.reset();
	// 	this.frmCtrl.controls['AgoraDetailIDP'].setValue(0);
	// 	this.frmCtrl.controls['AgoraIDF'].setValue(0);
	// 	this.frmCtrl.controls['UserIDF'].setValue(0);
	// 	this.frmCtrl.controls['EmployeeIDF'].setValue(0);
	// 	this.frmCtrl.controls['PartnerIDF'].setValue(0);
	// 	this.frmCtrl.controls['RecordingID'].setValue('');
	// 	(localStorage.getItem('UserName') != null) ? this.frmCtrl.controls['CustomerName'].setValue(localStorage.getItem('UserName').toString()) : this.frmCtrl.controls['CustomerName'].setValue('');
	// 	(localStorage.getItem('EmailID') != null) ? this.frmCtrl.controls['CustomerEmail'].setValue(localStorage.getItem('EmailID').toString()) : this.frmCtrl.controls['CustomerEmail'].setValue('');
	// 	(localStorage.getItem('MobileNo') != null) ? this.frmCtrl.controls['CustomerMobileNo'].setValue(localStorage.getItem('MobileNo').toString()) : this.frmCtrl.controls['CustomerMobileNo'].setValue('');
	// 	this.frmCtrl.controls['DateJoinUser'].setValue('');
	// 	this.frmCtrl.controls['DateJoinEmployee'].setValue('');
	// 	this.frmCtrl.controls['DateEndUser'].setValue('');
	// 	this.frmCtrl.controls['DateEndEmployee'].setValue('');
	// }
	//==================================================================
	showHide_ChatBox(id: number, event: any) {
		showHide_ChatBox(event, '');
		// id == 0 ? showHide_ChatBox(event, '') : showHide_ChatBox(event, '.wmc');
	}
	//==================================================================
	// ClearCtrl() {
	// 	this.frmCtrl.reset();
	// 	this.frmCtrl.controls['AgoraDetailIDP'].setValue(0);
	// 	this.submitted = false;
	// }
	//%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
	/*-------------------------------------------------------------------
			__   ___  __  ___          __   __       ___  ___ 
	| |\ | /__` |__  |__)  |     |  | |__) |  \  /\   |  |__  
	| | \| .__/ |___ |  \  |     \__/ |    |__/ /~~\  |  |___ 
	-------------------------------------------------------------------*/
	// async actionSave() {
	// 	this.submitted = true;
	// 	if (this.frmCtrl.invalid) {
	// 		return;
	// 	}
	// 	this.isProcess = true;
	// 	const obj = this.frmCtrl.value;

	// 	var fd: ITblAgoraDetail = {
	// 		agoraDetailIDP: Number(obj['AgoraDetailIDP']),
	// 		agoraIDF: obj['AgoraIDF'],
	// 		userIDF: obj['UserIDF'],
	// 		employeeIDF: obj['EmployeeIDF'],
	// 		partnerIDF: obj['PartnerIDF'],
	// 		recordingID: obj["RecordingID"] == null ? null : String(obj["RecordingID"]),
	// 		customerName: String(obj["CustomerName"]),
	// 		customerEmail: String(obj["CustomerEmail"]),
	// 		customerMobileNo: String(obj["CustomerMobileNo"]),
	// 		languageID: Number(localStorage.getItem("LanguageID")),
	// 		dateJoinUser: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
	// 		dateJoinEmployee: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
	// 		dateEndUser: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
	// 		dateEndEmployee: this.datepipe.transform(Date(), 'YYYY-MM-ddTHH:mm:ss.sss'),
	// 	}
	// 	// this._service.Action_InsertUpdate(fd).subscribe(
	// 	this._service.Action_InsertUpdate_v2(fd).subscribe(
	// 		async (r) => {
	// 			if (r.id <= 0) { SweetAlert.ActionFail(r.msg); this.isProcess = false; return; }
	// 			else {
	// 				if (r.agoraID != "") {
	// 					SweetAlert.ActionSuccess("Your Request was sent successfully.");
	// 					this.agoraDetailIDP = r.id;
	// 					this.agoraIDP = r.agoraID;
	// 					joinChannel(r.appId, r.token, r.channelName);
	// 					this.pnGrid = true;
	// 					this.isProcess = false;
	// 					this.ClearCtrl();
	// 					var  param : Param_Get_By_ID_IDF = {
	// 						Id: this.agoraDetailIDP,
	// 						Idf : this.agoraDetailIDP
	// 					}
	// 					var isMissedCall = setInterval(()=>{
	// 						this._service.Action_MissedCall(param).subscribe(
	// 							async (r) => {
	// 								if(r.isMisssedCall)
	// 								{
	// 									this.isMissedCall  = true;
	// 									this.Action_Leave();
	// 									SweetAlert.ActionFail("Sorry, Our all employee are busy!, please try after sometime.");
	// 									clearInterval(isMissedCall);
	// 								}
	// 							}, (error) => {
	// 								console.log(error);
	// 							}
	// 						);
	// 					},30000)
	// 				}
	// 				else {
	// 					SweetAlert.ActionFail("Sorry, Our all employee are busy!, please try after sometime.");
	// 				}
	// 			}
	// 		}, (error) => {
	// 			this.isProcess = false;
	// 			console.log(error);
	// 		}
	// 	);
	// }//END - Action Insert Update

	// Action_Leave() {
	// 	var fd: IParamAgoraDetailLeave = {
	// 		agoraDetailIDP: this.agoraDetailIDP,
	// 		agoraIDF: this.agoraIDP,
	// 		employeeIDF: 1,
	// 		partnerIDF: null,
	// 	}
	// 	this._service.Action_Leave(fd).subscribe(
	// 		async (r) => {
	// 			if (r.id > 0) {
	// 				if(!this.isMissedCall)SweetAlert.ActionSuccess(r.msg);
	// 				leave();
	// 				this.pnGrid = true;
	// 				this.isProcess = false;
	// 				this.isMissedCall = false;
	// 			}
	// 			else {
	// 				SweetAlert.ActionFail('Something wrong!'); this.isProcess = false; return;
	// 			}
	// 		}, (error) => {
	// 			this.isProcess = false;
	// 			console.log(error);
	// 		}
	// 	);
	// }
}
