<footer class="et-l et-l--footer">
    <div class="et_builder_inner_content et_pb_gutters3">
        <div class="et_pb_section et_pb_section_0_tb_footer et_pb_with_background et_section_regular">
            <div class="et_pb_row et_pb_row_0_tb_footer et_pb_equal_columns et_pb_gutters2 et_pb_row_3-5_1-5_1-5">
                <div class="et_pb_column et_pb_column_3_5 et_pb_column_0_tb_footer  et_pb_css_mix_blend_mode_passthrough">
                    <div class="et_pb_module et_pb_image et_pb_image_0_tb_footer">
                        <span class="et_pb_image_wrap "><img loading="lazy" width="1000" height="391"
                            src="https://tadween.ae/wp-content/uploads/2022/01/TADWEEN.png" alt=""
                            title="TADWEEN"
                            srcset="https://tadween.ae/wp-content/uploads/2022/01/TADWEEN.png 1000w, https://tadween.ae/wp-content/uploads/2022/01/TADWEEN-980x383.png 980w, https://tadween.ae/wp-content/uploads/2022/01/TADWEEN-480x188.png 480w"
                            sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) 1000px, 100vw"
                            class="wp-image-47">
                        </span>
                    </div>
                    <div class="et_pb_module et_pb_text et_pb_text_0_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                        <div class="et_pb_text_inner">
                            <p>
                                <span>Leadership and excellence in providing direct legal services and following them with high quality and less cost.</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="et_pb_column et_pb_column_1_5 et_pb_column_1_tb_footer  et_pb_css_mix_blend_mode_passthrough">
                    <div class="et_pb_module et_pb_text et_pb_text_1_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                        <div class="et_pb_text_inner">
                            <p>Navigate</p>
                        </div>
                    </div>
                    <div class="et_pb_module et_pb_divider et_pb_divider_0_tb_footer et_pb_divider_position_ et_pb_space">
                        <div class="et_pb_divider_internal"></div>
                    </div>
                    <div class="et_pb_module et_pb_text et_pb_text_2_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                        <div class="et_pb_text_inner">
                            <p>
                                <span *ngFor="let menus of menuList" style="color: #ffffff;">
                                    <a routerLink="/cms/{{menus.A1}}/{{menus.ID}}" style="color: #ffffff;">{{menus.A2}}</a><br>
                            </span>
                                <!-- <span *ngFor="let menu of menuList" style="color: #ffffff;"><a href="https://tadween.ae" style="color: #ffffff;">Home</a></span><br> -->
                                <!-- <span style="color: #ffffff;"><a href="https://tadween.ae/about-us/" style="color: #ffffff;">About us</a></span><br>
                                <span style="color: #ffffff;"><a href="https://tadween.ae/services/" style="color: #ffffff;">Services</a></span><br>
                                <span style="color: #ffffff;"><a href="https://tadween.ae/our-locations/" style="color: #ffffff;">Our locations</a></span><br>
                                <span style="color: #ffffff;"><a href="https://tadween.ae/contact/" style="color: #ffffff;">Contact</a><br></span> -->
                            </p>
                        </div>
                    </div>
                </div>
                <div class="et_pb_column et_pb_column_1_5 et_pb_column_2_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child">
                    <div class="et_pb_module et_pb_text et_pb_text_3_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                        <div class="et_pb_text_inner">
                            <p>Contact</p>
                        </div>
                    </div>
                    <div class="et_pb_module et_pb_divider et_pb_divider_1_tb_footer et_pb_divider_position_ et_pb_space">
                        <div class="et_pb_divider_internal"></div>
                    </div>
                    <div class="et_pb_module et_pb_text et_pb_text_4_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                        <div class="et_pb_text_inner">
                            <p>
                                <span>info@tadween.ae<br>+97125754435</span><br><span>+97124911326</span><br><span>+971507100859</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="et_pb_section et_pb_section_1_tb_footer et_pb_with_background et_section_regular">
            <div class="et_pb_row et_pb_row_1_tb_footer et_pb_equal_columns et_pb_gutters2">
                <div class="et_pb_column et_pb_column_1_2 et_pb_column_3_tb_footer  et_pb_css_mix_blend_mode_passthrough">
                    <div class="et_pb_module et_pb_text et_pb_text_5_tb_footer  et_pb_text_align_left et_pb_bg_layout_light">
                        <div class="et_pb_text_inner">
                            <p>Copyright © 2022 – Tadween All Right Reserved</p>
                        </div>
                    </div>
                </div>
                <div class="et_pb_column et_pb_column_1_2 et_pb_column_4_tb_footer  et_pb_css_mix_blend_mode_passthrough et-last-child et_pb_column_empty">
                </div>
            </div>
            <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
            <app-chat-bot></app-chat-bot>
            <!-- $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ -->
        </div>
    </div>
</footer>


<!-- Start Footer Area -->
<!-- <footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>About</h3>

                    <ul class="link-list">
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> About Business Hub</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Careers</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Recent News</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Investor Relations</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Content Guidelines</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Terms of Service</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Discover</h3>

                    <ul class="link-list">
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Project Cost Guides</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Upcoming Events</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Mobile App</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Customer Support</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Developers</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Collections</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Our Blog</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Business With Business Hub</h3>

                    <ul class="link-list">
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Claim your Business</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Advertise on Business Hub</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Restaurant Owners</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Business Success Stories</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Business Support</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Blog for Business</a></li>
                        <li><a routerLink="/"><i class="flaticon-left-chevron"></i> Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li><i class="bx bx-map"></i>175 5th Ave Premium Area, New York, NY 10010, United States</li>
                        <li><i class="bx bx-phone-call"></i><a href="tel:+11234567890">+1 (123) 456 7890</a></li>
                        <li><i class="bx bx-envelope"></i><a href="mailto:hello@vesax.com">hello@vesax.com</a></li>
                        <li><i class="bx bxs-inbox"></i><a href="tel:+557854578964">+55 785 4578964</a></li>
                    </ul>

                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <p>© Business Hub is Proudly Owned by <a href="https://saaction.in/" target="_blank">saAction</a></p>
        </div>
    </div>

    <div class="footer-image text-center">
        <img src="assets/img/footer-image.png" alt="image">
    </div>
</footer> -->
<!-- End Footer Area -->

<div class="go-top"><i class='bx bx-up-arrow-alt'></i></div>