import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-products-details',
    templateUrl: './products-details.component.html',
    styleUrls: ['./products-details.component.scss']
})
export class ProductsDetailsComponent implements OnInit {
    
    serviceId: number;
    serviceName: string;
    price:string;
    remark:string;
    type:string;
    authType: number;

    constructor(private route: ActivatedRoute) { }

    ngOnInit(): void { 
        this.route.queryParams.subscribe(
            params => {
                this.serviceId =  params['ServiceID'];
                this.serviceName =  params['ServiceName'];
                this.price = params['Price'];
                this.remark = params['Remark'];
                this.type = params['Type'];
                this.authType = params['AuthType'];
            }
        )
    }
    
    pageTitleContent = [
        {
            //title: 'Service Details',
            backgroundImage: 'assets/img/page-title/page-title4.jpg'
        }
    ]

}