<!-- Start Category Area -->
<div class="container">
    <div class="section-title" *ngFor="let Title of sectionTitle;">
        <h2>{{Title.title}}</h2>
        <p>{{Title.paragraph}}</p>
    </div>

    <div class="row">
        <div class="col-lg-2 col-sm-6 col-md-4" *ngFor="let Content of serviceList;">
            <div class="single-category-box">
                <div class="icon">
                    <i class="flaticon-commerce"></i>
                </div>
                <h3>{{Content.A1}}</h3>
                <!-- <span>{{Content.numberOfPlaces}}</span> -->
                <a routerLink="/single-products" [queryParams]="{ServiceName:Content.A1,Price:Content.A2,Remark:Content.A4,Type:Content.A3,AuthType:Content.A5}" class="link-btn"></a>
            </div>
        </div>
    </div>
</div>
<!-- End Category Area -->