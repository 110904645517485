import { HttpHeaders } from "@angular/common/http";

export class GlobalComponent {
    // Api Calling
    // API_URL : 'https://api-node.themesbrand.website/',
    // headerToken : {'Authorization': `Bearer ${localStorage.getItem('token')}`},

    // Auth Api
    public static AUTH_API: string = "https://api-node.themesbrand.website/auth/";
    
    // Products Api
    // product:'apps/product',
    // productDelete:'apps/product/',

    // Orders Api
    // order:'apps/order',
    // orderId:'apps/order/',

    // Customers Api
    //customer:'apps/customer',

    // public static APIUrl: string = 'https://esetadween-api.visioninformatics.in';
    // public static AttachmentUrl: string =  'https://esetadween-img.visioninformatics.in/';
    
    public static APIUrl: string = 'https://api.tadweenerp.site';
    public static AttachmentUrl: string =  'https://files.tadweenerp.site/';

    // public static APIUrl: string = 'https://stage-api.esetadween.com';
    // public static AttachmentUrl: string =  'https://stage-cdn.esetadween.com/';

    // public static APIUrl: string = 'https://localhost:44397';
    // public static AttachmentUrl: string = 'D:/0__Work/Angular Templates/API/uae-saas-api/Assets/';

    public static token: any = localStorage.getItem('Token');
    public static userID: any = localStorage.getItem('UserID');
    public static userName: any = localStorage.getItem('UserName');
    public static userType: any = localStorage.getItem('UserType');
    public static isRTL: any = localStorage.getItem('IsRTL');

    
    public static header(): HttpHeaders 
    {
        return new HttpHeaders(
        {
            'Authorization': "" + localStorage.getItem('Token'),
            'UserType': "" + localStorage.getItem('UserType')
        });
    }

    public static httpHeader(): HttpHeaders 
    {
        return new HttpHeaders(
        {
            'content-type': 'application/json',
            'Authorization': "" + localStorage.getItem('Token'),
            'UserType': "" + localStorage.getItem('UserType')
        });
    }

    public static httpHeaderMultipart1(): HttpHeaders 
    {
        return new HttpHeaders(
        {
            'content-type': 'multipart/form-data',
            'Authorization': "" + localStorage.getItem('Token'),
            'UserType': "" + localStorage.getItem('UserType')
        });
    }

    public static httpHeaderMultipart(): HttpHeaders 
    {
        return new HttpHeaders(
        {
            'enctype': 'multipart/form-data',
            'Authorization': "" + localStorage.getItem('Token'),
            'UserType': "" + localStorage.getItem('UserType')
        });
    }

    public static headerFile(): HttpHeaders 
    {
        return new HttpHeaders(
            {
                enctype: 'multipart/form-data',
                'Token': String(localStorage.getItem('Token')),
                'UserType': String(localStorage.getItem('UserType')),
            });
    }
}