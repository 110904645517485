<!-- Start Navbar Area -->
<div class="navbar-area navbar-style-two">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo-light.png" height="60px" width="120px" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo-light.png" height="60px" width="120px" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What are you looking for?">
                    </form>

                    <ul class="navbar-nav">
                        <!-- <li class="nav-item" *ngFor="let menus of menuList"><a routerLink="/" (click)="actionGetMenuDetails(menus.ID)" class="nav-link" [routerLinkActiveOptions]="{exact: true}">{{menus.A2}}</a></li> -->
                        
                        <li class="nav-item" *ngFor="let menus of menuList | subPageMenu:0">
                            <a routerLink="/cms/{{menus.A1}}/{{menus.ID}}" (click)="actionGetMenuDetails(menus.ID)" routerLinkActive="router-link-active" class="nav-link">{{menus.A2}}</a>
                            <ul class="dropdown-menu" *ngFor="let subMenus of menuList | subPageMenu:menus.ID">
                                <li class="nav-item" *ngFor="let subMenus of menuList | subPageMenu:menus.ID"><a routerLink="/cms/{{subMenus.A1}}/{{subMenus.ID}}" (click)="actionGetMenuDetails(subMenus.ID)" routerLinkActive="router-link-active" class="nav-link">{{subMenus.A2}}</a></li>
                            </ul>
                        </li>
                        
                        <li class="nav-item">
                            <ng-select class="nav-link mt-3" [(ngModel)]="languageSetting" [multiple]="false" [clearable]="false" (change)="changeLanguageSetting($event)">
                                <ng-option value="0">English</ng-option>
                                <ng-option value="1">Arabic</ng-option>
                            </ng-select>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div *ngIf="userID != ''" class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                <span class="d-flex align-items-center">
                                    <!-- <img class="rounded-circle header-profile-user" src="assets/img/users/user-dummy-img.jpg" style="height: 30px; width: 30px;" alt="Header Avatar"> -->
                                    <img class="rounded-circle header-profile-user" [src]="getSantizeUrl(photoURL)" style="height: 30px; width: 30px;" alt="Header Avatar">
                                    <span class="text-start ms-xl-2"></span>
                                </span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                <h6 class="dropdown-header"><b>Welcome {{userName}}</b></h6>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" [routerLink]="['/user-dashboard']"><i class="bx bx-home align-middle"></i> <span class="align-middle"> Dashboard</span></a>
                                <a class="dropdown-item" [routerLink]="['/user-profile']"><i class="bx bx-cog align-middle"></i> <span class="align-middle"> Setting</span></a>
                                <a class="dropdown-item" href="" (click)="actionLogout()"><i class="bx bx-exit align-middle"></i> <span class="align-middle"> Logout</span></a>
                            </div>
                        </div>
                        <div class="option-item">
                            <button *ngIf="userID == ''" type="button" class="btn btn-default" (click)="openModal()"><span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span></button>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="navbar-search-box">
                                <label><i class="flaticon-search"></i></label>
                                <input type="text" class="input-search" placeholder="What are you looking for?">
                            </form>
                        </div>

                        <div class="option-item">
                            <button type="button" class="btn btn-default" (click)="openModal()"><span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="navbar-area navbar-style-two">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/logo-light.png" height="60px" width="120px" alt="logo"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo-light.png" height="60px" width="120px" alt="logo"></a>

                <div class="collapse navbar-collapse mean-menu">
                    <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search" placeholder="What are you looking for?">
                    </form>

                    <ul class="navbar-nav">
                        <li class="nav-item" *ngFor="let menus of menuList"><a routerLink="/" class="nav-link" [routerLinkActiveOptions]="{exact: true}">{{menus.A1}}</a></li>
                        
                        <li class="nav-item">
                            <ng-select class="nav-link mt-3" [(ngModel)]="languageSetting" [multiple]="false" [clearable]="false" (change)="changeLanguageSetting($event)">
                                <ng-option value="0">English</ng-option>
                                <ng-option value="1">Arabic</ng-option>
                            </ng-select>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div *ngIf="userID != ''" class="dropdown ms-sm-3 header-item topbar-user" ngbDropdown>
                            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                <span class="d-flex align-items-center">
                                    <img class="rounded-circle header-profile-user" src="assets/img/users/user-dummy-img.jpg" style="height: 30px; width: 30px;" alt="Header Avatar">
                                    <span class="text-start ms-xl-2"></span>
                                </span>
                            </button>
                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                <h6 class="dropdown-header"><b> Welcome {{userName}} </b></h6>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" [routerLink]="['/user-dashboard']"><i class="bx bx-home align-middle"></i> <span class="align-middle"> Dashboard</span></a>
                                <a class="dropdown-item" href="" (click)="actionLogout()"><i class="bx bx-exit align-middle"></i> <span class="align-middle"> Logout</span></a>
                            </div>
                        </div>
                        <div class="option-item"> -->
                            <!-- <button *ngIf="userID != ''" type="button" class="btn btn-default" (click)="actionLogout()"><span><i class="bx bx-exit"></i> Logout</span></button> -->
                            <!-- <button *ngIf="userID == ''" type="button" class="btn btn-default" (click)="openModal()"><span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span></button> -->
                            <!-- <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span> -->
                        <!-- </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="navbar-search-box">
                                <label><i class="flaticon-search"></i></label>
                                <input type="text" class="input-search" placeholder="What are you looking for?">
                            </form>
                        </div>

                        <div class="option-item">
                            <button *ngIf="userID != ''" type="button" class="btn btn-default" (click)="actionLogout()"><span><i class="bx bx-exit"></i> Logout</span></button>
                            <button *ngIf="userID == ''" type="button" class="btn btn-default" (click)="openModal()"><span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span></button> -->
                            <!-- <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i class="flaticon-user"></i> Login / Register</span> -->
                        <!-- </div> -->

                        <!-- <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i> Add Listing</a>
                        </div> -->
                    <!-- </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Navbar Area -->


<app-login [IsOpenLoginView]=isOpenLoginView></app-login>

<!-- Start Login & Register Modal -->
<!-- <div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li>
                <li class="nav-item">
                    <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <div class="login-with-account">
                            <span>Login with</span>
                            <ul> -->
                                <!-- <li><button type="button" class="btn btn-danger btn-lg" (click)="loginWithRedirect()"><i class='bx bxl-google'></i> Google</button></li> -->
                                <!-- <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or login with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username or Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <button type="submit">Login Now</button>
                        </form>
                        <span class="dont-account">Don't have an account? <a href="#">Register Now</a></span>
                    </div>
                </div>
                <div class="tab-pane fade" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <div class="register-with-account">
                            <span>Register with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or Register with</span></span>
                        <form>
                            <div class="form-group">
                                <input type="text" placeholder="Username" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control">
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="form-control">
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an account? <a href="#">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Login & Register Modal -->