import { Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NgForm, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import * as SweetAlert from 'src/app/shared/data/sweet-alerts';

// Import the AuthService type from the SDK
//import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  socialUser: SocialUser | undefined;

  @Input("IsOpenLoginView")
  set name(name: number) {
    if(name===0) return;
    this.openModal();
    //this.mdl.open(this.modalCtrlImageViewer, { size: 'xl', backdrop: 'static' });
  }

  apiUrl:string;
  httpHeaderMultipart: any;
  isRTL: boolean = false;
  isGoogleLogin: boolean = false;
  userID: string = "";
  languageSetting: string = "0";
  //languageID: number = 1;
  menuList: any;
  menuDetails: string = '';
  loginFormSubmitted = false;
  isProcessLogin : boolean = false;
  registrationFormSubmitted = false;
  isProcessRegister : boolean = false;

  //user: any;

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  // constructor(public auth: AuthService, private http: HttpClient, private injector: Injector, private route: ActivatedRoute, public sanitizer: DomSanitizer, public auth: AuthService) {
    constructor(private socialAuthService: SocialAuthService, private http: HttpClient, private injector: Injector, private route: ActivatedRoute, private toastr: ToastrService, public sanitizer: DomSanitizer) {
    this.apiUrl = this.injector.get('APIURL');
    this.httpHeaderMultipart = this.injector.get('httpHeaderMultipart');
    if(localStorage.getItem('UserID') != null) this.userID = localStorage.getItem('UserID');
    //console.log(this.userID);
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  ngOnInit(): void {
    if(localStorage.getItem('IsRTL') != null && localStorage.getItem('IsRTL') == 'rtl'){
      this.isRTL = true;
      this.languageSetting = "1";
    }
    else{
      this.isRTL = false;
      this.languageSetting = "0";
    }
    //this.user = {};
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      console.log(this.socialUser);
      if(this.isGoogleLogin == true) this.googleLogin();
    });
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%


  loginWithGoogle(): void {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID);
    this.isGoogleLogin = true;
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID);
    this.isGoogleLogin = true;
  }
  // loginWithRedirect(){
  //   this.auth.loginWithRedirect();

  //   this.auth.isAuthenticated$
  //   this.auth.user$.subscribe((success: any) => {
  //     this.user = success;
  //   });

  //   console.log(this.user);
    
  // }//loginWithRedirect

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  frmCtrlRegistartion = new FormGroup({
    FirstName : new FormControl('',[Validators.required]),
    //MiddleName : new FormControl('',[Validators.required]),
    LastName : new FormControl('',[Validators.required]),
    MobileNo : new FormControl('',[Validators.required]),
    // EmailID : new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
    EmailID : new FormControl('',[Validators.required, Validators.pattern("^[a-z0-9._%+-]+@ese.gov.ae$")]),
    Password : new FormControl('',[Validators.required, Validators.minLength(6)]),
    ConfirmPassword : new FormControl('',[Validators.required]),
  });

  get f() {
    return this.frmCtrlRegistartion.controls;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  frmCtrlLogin = new FormGroup({
    UserName : new FormControl('',[Validators.required]),
    LgnPassword : new FormControl('',[Validators.required]),
  });

  get flgn() {
    return this.frmCtrlLogin.controls;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  openModal(){
    this.loginFormSubmitted = false;
    this.registrationFormSubmitted = false;
    this.frmCtrlLogin.reset();
    this.frmCtrlRegistartion.reset();
    document.getElementById("login-tab").click();
    setTimeout(() => { var c1 = <HTMLInputElement>document.querySelector("[autofocus]"); if (c1 != null) c1.focus(); }, 300);
  }//openModal()

  actionLogout(){
    localStorage.clear();
    window.location.reload();
    this.isGoogleLogin = false;
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionRegistration(){
    this.registrationFormSubmitted = true;
    if (this.frmCtrlRegistartion.invalid) {
      return;
    }else if(this.f.Password.value != this.f.ConfirmPassword.value){
      return;
    }else this.isProcessRegister = true;
    
    var formData = new FormData();
    formData.append('ServiceIdp', "0");
    formData.append('FirstName', (this.f.FirstName.value == null) ? '' : this.f.FirstName.value);
    //formData.append('MiddleName', (this.f.MiddleName.value == null) ? '' : this.f.MiddleName.value);
    formData.append('LastName', (this.f.LastName.value == null) ? '' : this.f.LastName.value);
    formData.append('MobileNo', (this.f.MobileNo.value == null) ? '' : this.f.MobileNo.value);
    formData.append('EmailID', (this.f.EmailID.value == null) ? '' :  this.f.EmailID.value);
    formData.append('Password', (this.f.Password.value == null) ? '' :  this.f.Password.value);
    formData.append('RegistrationType', "Entry By ESE Staff Portal");

    this.http.post(this.apiUrl + '/Client/Registration', formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data => {
        const res : any = data;
        if(res.status == "Done"){
          document.getElementById("closeModalButton").click();
          SweetAlert.ActionSuccess(res.msg);
          //this.toastr.success(res.msg, res.status);
          this.flgn.UserName.setValue(this.f.EmailID.value);
          this.flgn.LgnPassword.setValue(this.f.Password.value);
          this.actionLogin();
          this.frmCtrlRegistartion.reset();
        }else{
          SweetAlert.ActionFail(res.msg);
          //this.toastr.error(res.msg, res.status);
        }
        this.isProcessRegister = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessRegister = false;
      })
    )
  }//actionSaveService

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  actionLogin(){
    this.loginFormSubmitted = true;
    if (this.frmCtrlLogin.invalid) {
      return;
    }
    this.isProcessLogin = true;
    
    var formData = new FormData();
    formData.append('UserName', this.flgn.UserName.value);
    formData.append('Password', this.flgn.LgnPassword.value);

    this.http.post(this.apiUrl + '/Client/Login', formData, this.injector.get('httpHeaderMultipart')).subscribe(
      (data => {
        const res : any = data[0];
        if(data["msg"] != "Invalid credential"){
          document.getElementById("closeModalButton").click();
          SweetAlert.ActionSuccess("Login Successfully.");
          //this.toastr.success("Login Successfully.", "Done");
          this.frmCtrlLogin.reset();
          localStorage.setItem('UserName', res.FullName);
          localStorage.setItem('UserID', res.ClientIDP);
          localStorage.setItem('Token', res.CurrentToken);
          localStorage.setItem('EmailID', res.EmailID);
          localStorage.setItem('MobileNo', res.MobileNo);
          localStorage.setItem('Photo', res.ProfilePicture);
          if(res.isRTL == 0){
            localStorage.setItem('IsRTL', "true");
            localStorage.setItem('LanguageID', "1");
          }
          else{
            localStorage.setItem('IsRTL', "false");
            localStorage.setItem('LanguageID', "0");
          }
          
          window.location.reload();
        }else{
          this.isProcessLogin = false;
          SweetAlert.ActionFail(data["msg"], "Login Failed");
          //this.toastr.error(data["msg"], "Login Failed");
        }
        this.isProcessLogin = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessLogin = false;
      })
    )
  }//actionLogin

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%

  googleLogin(){
    this.isProcessLogin = true;
      
    var param: any;
    param = { EmailID:this.socialUser.email, GoogleID:this.socialUser.id, FirstName:this.socialUser.firstName, LastName:this.socialUser.lastName, FullName:this.socialUser.name, Photo:this.socialUser.photoUrl, LoginType: 0, PreClientIDF: 0};
    
    this.http.post(this.apiUrl + '/Client/Login_ByGoogle', JSON.stringify(param), this.injector.get('httpHeader')).subscribe(
      (data => {
        const res : any = data[0];
        if(data["msg"] != "Invalid credential"){
          document.getElementById("closeModalButton").click();
          SweetAlert.ActionSuccess("Login Successfully.");
          //this.toastr.success("Login Successfully.", "Done");
          this.frmCtrlLogin.reset();
          localStorage.setItem('UserName', res.FullName);
          localStorage.setItem('UserID', res.ClientIDP);
          localStorage.setItem('Token', res.CurrentToken);
          localStorage.setItem('EmailID', res.EmailID);
          localStorage.setItem('MobileNo', res.MobileNo);
          localStorage.setItem('Photo', res.ProfilePicture);
          if(res.isRTL == 0){
            localStorage.setItem('IsRTL', "true");
            localStorage.setItem('LanguageID', "1");
          }
          else{
            localStorage.setItem('IsRTL', "false");
            localStorage.setItem('LanguageID', "0");
          }
          window.location.reload();
        }else{
          this.isProcessLogin = false;
          SweetAlert.ActionFail(data["msg"], "Login Failed");
          //this.toastr.error(data["msg"], "Login Failed");
        }
        this.isProcessLogin = false;
      }),
      (error => {
        console.log("Error :- " + error);
        this.isProcessLogin = false;
      })
    )
  }

  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
  //%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
}