<app-navbar-style-one></app-navbar-style-one>

<section class="checkout-area ptb-100">
    <input type="hidden" id="hiddenAPIURL" [(ngModel)]="apiUrl">
    <input type="hidden" id="hiddenAttachmentURL" [(ngModel)]="attachmentURL">
    <input type="hidden" id="hiddenServiceID" [(ngModel)]="serviceId">
    <input type="hidden" id="hiddenFormType" [(ngModel)]="formType">
    <input type="hidden" id="hiddenRecordIDP" [(ngModel)]="recordIDP">
    <input type="hidden" id="hiddenDynamicIDP" [(ngModel)]="dynamicIdf">
    <input type="hidden" id="hiddenTableName" [(ngModel)]="tableName">
    <input type="hidden" id="hiddenFormDetails" [(ngModel)]="formDetails">
    <input type="hidden" id="hiddenFormTableDetail" [(ngModel)]="formTableDetail">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2>Client Dashboard</h2>
                <div class="live-preview" *ngIf="isProcessLoading == false && serviceList.length != 0">
                    <div class="table-responsive">
                        <table class="table table-striped table-hover mt-5 mb-5">
                            <thead>
                                <tr>
                                    <th>Action</th>  
                                    <th>Service Name</th>
                                    <th>Status</th>
                                    <th>Insert Date</th>
                                    <th>Update Date</th>
                                    <th>Remark</th>
                                </tr>
                              </thead>
                            <tbody>
                                <tr *ngFor="let service of serviceList">
                                    <td *ngIf="service.StatusID != 'Query' && service.StatusID != 'Color photo required'"><button type="button" title="Click to View form details" id="btGetFormDetail" class="btn btn-default" (click)="actionViewForm(service, 'View')"><i class="bx bx-show"></i></button></td>
                                    <td *ngIf="service.StatusID == 'Query' || service.StatusID == 'Color photo required'"><button type="button" title="Click to Edit form details" id="btEditFormDetail" class="btn btn-default" (click)="actionViewForm(service, 'Edit')"><i class='bx bx-edit'></i></button></td>
                                    <td>{{service.ServiceName}}</td>
                                    <!-- <td>{{service.StatusID}}</td> -->
                                    <td>
                                        <span *ngIf="service.StatusID == 'Submitted'" class="badge bg-success"> Submitted </span>
                                        <span *ngIf="service.StatusID == 'On Progress'" class="badge bg-warning"> On Progress </span>
                                        <span *ngIf="service.StatusID == 'Approved'" class="badge bg-info"> Approved </span>
                                        <span *ngIf="service.StatusID == 'Pending'" class="badge bg-primary"> Pending </span>
                                        <span *ngIf="service.StatusID == 'Waiting Approval'" class="badge bg-secondary"> Waiting Approval </span>
                                        <span *ngIf="service.StatusID == 'Color photo required'" class="badge bg-danger"> Color photo required </span>
                                        <span *ngIf="service.StatusID == 'Query'" class="badge bg-danger"> Query </span>
                                        <span *ngIf="service.StatusID == 'Under Progress'" class="badge bg-secondary"> Under Progress </span>
                                        <span *ngIf="service.StatusID == 'Rejected'" class="badge bg-danger"> Rejected </span>
                                        <span *ngIf="service.StatusID == 'Done'" class="badge bg-success"> Done </span>
                                    </td>
                                    <td>{{service.DateInserted | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                    <td>{{service.DateUpdated | date:'dd/MM/yyyy hh:mm:ss a'}}</td>
                                    <td>{{service.Remark}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div *ngIf="isProcessLoading == false && serviceList.length == 0"><h1 class="text-center"> -- No Data Found -- </h1></div>
                <div *ngIf="isProcessLoading == true" class="text-center"><i class="fa-refresh fa-spin fa ml-2" style="font-size: 40px;"></i></div>
            </div>
        </div>
    </div>
</section>

<!-- Task Start Popup -->
<ng-template id="modalCtrlFormDetail" #modalCtrlFormDetail let-modal>
    <div class="modal-header">
        <h3>{{serviceName}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="pnProcessLoading text-center"><i class="fa-spinner fa-spin fa mr-2 mt-5 mb-5" style="font-size: 30px;"></i></div>
        <div id="formDetailGet"></div>

        <div id="pnServiceStatus" style="display: none;">
            <div class="row mt-3 mb-3" style="border: 1px solid #cdcdcd; border-radius: 15px; margin: 5px; padding: 20px;">
                <div class="col-md-6">
                    <label title="Status" for="Status" class="form-label-english">Status : </label>
                </div>
                <div class="col-md-6 text-right">
                    <label title="حالة" for="Status" class="form-label-arabic">حالة </label>
                </div>
                <div class="col-md-12">
                    <label id="hiddenServiceStatus"></label>
                </div>
            </div>
            <div class="row mt-3 mb-3" style="border: 1px solid #cdcdcd; border-radius: 15px; margin: 5px; padding: 20px;">
                <div class="col-md-6">
                    <label title="Remark" for="Remark" class="form-label-english">Remark : </label>
                </div>
                <div class="col-md-6 text-right">
                    <label title="ملاحظة" for="Remark" class="form-label-arabic">ملاحظة </label>
                </div>
                <div class="col-md-12">
                    <label id="hiddenServiceRemark"></label>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="row">
            <div class="col-12">
                <button type="button" class="btn btn-warning" (click)="actionCancel()"> Cancel</button>
                <button type="button" class="btn btn-success ml-2" id="btUpdate" style="display: none;"><i class=""></i> Update</button>
            </div>
        </div>
    </div>
</ng-template>
<!-- Task End Popup -->

<app-footer-style-one></app-footer-style-one>