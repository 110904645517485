<input type="hidden" id="hfAPIURL" value={{apiURL}}>
<input type="hidden" id="hfAttachmentURL" value={{attachmentURL}}>
<input type="hidden" id="hfAgoraIDF" value={{agoraIDP}}>
<input type="hidden" id="hfAgoraDetailIDF" value={{agoraDetailIDP}}>
<input type="hidden" id="hfClientIDP" value={{clientIDP}}>
<input type="hidden" id="hfEmployeeIDF" value={{employeeIDF}}>
<input type="hidden" id="hfUserName" value={{userName}}>
<input type="hidden" id="hfMobileNo" value={{mobileNo}}>
<input type="hidden" id="hfAgoraAgentName" value={{agoraAgentName}}>
<input type="hidden" id="hfAgentPicture" value={{AgentPicture}}>
<input type="hidden" id="hfClientCustomerPicture" value={{CustomerPicture}}>

<div class="row center-container mt-1">
    <div class="col-md-12">
      <div class="center-container">
        <div class="card chat">
          <!-- <div class="card-header text-end">
            <i class="fa fa-angle-down text-white" onclick="showHide_ChatBox(this, '')"></i>
          </div> -->
          <div class="card-body">
            <div *ngIf="pnChat && pnGrid" class="et-magazine-user-pic mt-1">
              <img class="et-magazine-user-pic-img" src="assets/img/logo-light.png" alt="">
            </div>
            <div *ngIf="pnChat && pnGrid" class="text-center">
              <h4>Tadween</h4>
              <p class="text-center">Simply Meet Online Agents</p>
            </div>
            <div class="row">
                <div [ngClass]="{'col-md-12' : !pnChat, 'col-md-6' : pnChat}" class="center-container" style="min-height: 85vh !important;">
                    <div *ngIf="!pnChat && !pnGrid" class="et-magazine-user-pic">
                      <img class="et-magazine-user-pic-img" src="assets/img/logo-light.png" alt="">
                    </div>
                    <div *ngIf="!pnChat && !pnGrid" class="text-center">
                      <h4>Tadween</h4>
                      <p class="text-center">Simply Meet Online Agents</p>
                    </div>
                    <div class="tab-content" id="myTabContent">
                      <!-- <div class="tab-pane p-4 fade show active" id="tabVideoCall" role="tabpanel" aria-labelledby="btVideoCall"> -->
                      <div class="tab-pane fade show active" id="tabVideoCall" role="tabpanel" aria-labelledby="btVideoCall">
                        <div class="row justify-content-center" id="myTab" role="tablist">
                          <div class="col-md-12 text-center" role="presentation">
                            <h4 id="headingChat"> Let's Talk</h4>
                            <button class="btn btn-primary col-md-12" id="btContact" data-bs-toggle="tab"
                              onclick="showHide_ChatBox(this, '.btContact')" data-bs-target="#tabContact" type="button" role="tab"
                              aria-controls="tabContact" (click)="actionshowHideContactForm()"> <i class="fa fa-video-camera me-2 "></i> Start Video Call</button>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="tab-pane p-4 fade show" id="tabChat" role="tabpanel" aria-labelledby="btChat">
                          <div class="row mt-3" id="pnChat_ContactForm">
                            <div class="col-md-12 mb-2">
                              <input class="form-control" id="tb_Chat_Name" type="text" placeholder="Name">
                              <span id="err_tbChat_Name" class="text-danger" style="display: none;">Enter Name</span>
                            </div>
                            <div class="col-md-12 mb-2">
                              <input class="form-control" id="tb_Chat_Email" type="email" placeholder="Email">
                              <span id="err_tbChat_Email" class="text-danger" style="display: none;">Enter Email Address</span>
                              <span id="err_tbChat_Email1" class="text-danger" style="display: none;">Enter Valid Email
                                Address</span>
                            </div>
                            <div class="col-md-12">
                              <textarea class="form-control " id="tb_Chat_Message" rows="3"
                                placeholder="Enter your message"></textarea>
                              <span id="err_tbChat_Message" class="text-danger" style="display: none;">Enter Message</span>
                            </div>
                            <div class="col-md-12 text-center">
                              <button id="tbChat" class="btn col-md-12 btn-primary mt-3" onclick="sendMessage(this)">Send</button>
                            </div>
                          </div>
                          <div class="row mt-3" id="pnChat_Chatbox" style="display: none;">
                            <div class="col-md-12">
                              <input type="text" class="form-control" placeholder="Say something">
                            </div>
                          </div>
                        </div> -->
                      <div class="tab-pane p-4 fade show" id="tabContact" role="tabpanel" aria-labelledby="btContact">
                        <form *ngIf="!pnOTP && !pnChat && !pnContactForm" class="needs-validation" [formGroup]="frmCtrl" autocomplete="off" id="frmCtrl">
                          <div class="row" id="pnContactForm">
                            <div class="col-md-12 mt-2">
                              <input type="text" formControlName="CustomerName" id="tb_Contact_Name" class="form-control" placeholder="Name"
                                [ngClass]="{ 'is-invalid': submitted && f.CustomerName.invalid, 'is-valid': submitted &&  !f.CustomerName.invalid }" [readonly]="IsExistClient">
                              <span id="err_tbContact_Name" class="text-danger" style="display: none;">Enter Name</span>
                            </div>
                            <div class="col-md-12 mt-2">
                              <input type="text" formControlName="CustomerEmail" id="tb_Contact_Email" class="form-control "
                                placeholder="example@email.com"
                                [ngClass]="{ 'is-invalid': submitted && f.CustomerEmail.invalid, 'is-valid': submitted &&  !f.CustomerEmail.invalid }" [readonly]="IsExistClient">
                              <span id="err_tbContact_Email" class="text-danger" style="display: none;">Enter Email</span>
                              <span id="err_tbContact_Email1" class="text-danger" style="display: none;">Enter Valid Email
                                Address</span>
                            </div>
                            <div class="col-md-12 mt-2">
                              <input type="tel" class="form-control" name="phone" formControlName="CustomerMobileNo" id="tb_Contact_Mobile"
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                placeholder="Enter Mobile Number" minlength="10" maxlength="13"
                                [ngClass]="{ 'is-invalid': submitted && f.CustomerMobileNo.invalid, 'is-valid': submitted &&  !f.CustomerMobileNo.invalid }" [readonly]="IsExistClient">
                              <!-- <input type="mobile" formControlName="CustomerMobileNo" id="tb_Contact_Mobile" class="form-control "
                                oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                placeholder="+971 25754435" minlength="10" maxlength="13"
                                [ngClass]="{ 'is-invalid': submitted && f.CustomerMobileNo.invalid, 'is-valid': submitted &&  !f.CustomerMobileNo.invalid }"> -->
                              <span id="err_tbContact_Mobile" class="text-danger" style="display: none;">Enter Mobile
                                Number</span>
                            </div>
                            <div *ngIf="IsExistClient == false" class="col-md-12 mt-2 form-group ml-4">
                              <ul class="list-unstyled">
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="WhatsappNumber" type="radio" [value]="1" [(ngModel)]="mobileNoType" name="MobileNumberType" formControlName="MobileNumberType">
                                        <label for="WhatsappNumber"> WhatApp OTP </label>
                                    </div>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="SIM" type="radio" [value]="0" [(ngModel)]="mobileNoType" name="MobileNumberType" formControlName="MobileNumberType">
                                        <label for="SIM"> SIM OTP </label>
                                    </div>
                                </li>
                              </ul>
                            </div>
                            <div *ngIf="IsExistClient == false" class="col-md-12 mt-2 form-group ml-4">
                              <ul class="list-unstyled">
                                <li class="d-inline-block mr-5">
                                  <lable>Preferred Language :</lable>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="Both" type="radio" [value]="0" [(ngModel)]="preferredLanguage" name="PreferredLanguage" formControlName="PreferredLanguage">
                                        <label for="Both"> English/Arabic </label>
                                    </div>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="English" type="radio" [value]="1" [(ngModel)]="preferredLanguage" name="PreferredLanguage" formControlName="PreferredLanguage">
                                        <label for="English"> English </label>
                                    </div>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="Arabic" type="radio" [value]="2" [(ngModel)]="preferredLanguage" name="PreferredLanguage" formControlName="PreferredLanguage">
                                        <label for="Arabic"> Arabic </label>
                                    </div>
                                </li>
                              </ul>
                            </div>
                            <div class="col-md-12 mt-3">
                              <button class="btn btn-primary col-md-12" (click)="actionSave()" [disabled]="isProcess"><i [ngClass]="{'fa-spinner fa-spin fa mr-2' : isProcess}"></i> Start Video Call </button>
                            </div>
                          </div>
                        </form>
                        <div *ngIf="!pnOTP && !pnChat && pnContactForm && isProcess">
                          <p class="isLoading text-center"> <i class="fa-refresh fa-spin fa"></i> Loading... Please wait... </p>
                        </div>
                        <form *ngIf="pnOTP && !pnChat && !pnNoAgent" class="needs-validation" [formGroup]="frmCtrlOTP" autocomplete="off" id="frmCtrlOTP">
                          <div class="row">
                              <div class="col-md-12 text-center">
                                <div class="row">
                                  <div *ngIf="!pnAnotherWay" class="col-md-12">
                                    <div *ngIf="f.MobileNumberType.value == 1 || TAWmobileNoType == 1" class="title m-5"> Verify OTP With WhatsApp</div>
                                    <div *ngIf="f.MobileNumberType.value == 0 || TAWmobileNoType == 0" class="title m-5"> Verify OTP With SMS</div>
                                    <input class="otp ml-2" type="text" formControlName="OTP1" onkeyup='tabChange(1)' maxlength="1" autofocus >
                                    <input class="otp" type="text" formControlName="OTP2" onkeyup='tabChange(2)' maxlength="1" >
                                    <input class="otp" type="text" formControlName="OTP3" onkeyup='tabChange(3)' maxlength="1" >
                                    <input class="otp" type="text" formControlName="OTP4" onkeyup='tabChange(4)' maxlength="1" >
                                    <hr class="mt-4">
                                    <button id="tbContact" class="btn btn-primary col-md-12" (click)="actionVerifyOTP()" [disabled]="isProcess"><i [ngClass]="{'fa-spinner fa-spin fa mr-2' : isProcess}"></i> Verify OTP </button>
                                    <div class="text-center mt-2">
                                      <p *ngIf="counter != 00"> Try another way in {{ transform(counter) }} </p>
                                      <p *ngIf="counter == 00"> <button type="button" class="btn btn-link" (click)="actionTryAnother()"> Try another way ? </button> </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                        </form>
                        <div *ngIf="pnOTP && !pnChat && pnAnotherWay" class="row">
                          <div class="col-md-12 text-center">
                            <div class="title m-5"> Try another way to verify OTP </div>
                            <div class="col-md-12 form-group ml-4">
                              <ul class="list-unstyled">
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="WhatsappNumber1" type="radio" [value]="1" [(ngModel)]="TAWmobileNoType" name="TAWMobileNumberType">
                                        <label for="WhatsappNumber1"> WhatApp OTP </label>
                                    </div>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="SIM1" type="radio" [value]="0" [(ngModel)]="TAWmobileNoType" name="TAWMobileNumberType">
                                        <label for="SIM1"> SIM OTP </label>
                                    </div>
                                </li>
                              </ul>
                            </div>
                            <!-- <div class="col-md-12 mt-2 form-group ml-4">
                              <ul class="list-unstyled">
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="Both1" type="radio" [value]="0" [(ngModel)]="TAWPreferredLanguage" name="TAWPreferredLanguage">
                                        <label for="Both1"> English/Arabic </label>
                                    </div>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="English1" type="radio" [value]="1" [(ngModel)]="TAWPreferredLanguage" name="TAWPreferredLanguage">
                                        <label for="English1"> English </label>
                                    </div>
                                </li>
                                <li class="d-inline-block mr-5">
                                    <div class="radio">
                                        <input class="form-check-input" id="Arabic1" type="radio" [value]="2" [(ngModel)]="TAWPreferredLanguage" name="TAWPreferredLanguage">
                                        <label for="Arabic1"> Arabic </label>
                                    </div>
                                </li>
                              </ul>
                            </div> -->
                            <div class="col-md-12 mt-3">
                              <button class="btn btn-primary col-md-12" (click)="actionUpdateByMobileType()" [disabled]="isProcess"><i [ngClass]="{'fa-spinner fa-spin fa mr-2' : isProcess}"></i> Update </button>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="pnOTP && !pnChat && pnNoAgent" class="row">
                          <div class="col-md-3 text-center mt-2">
                            <img src="assets/img/Warning.png" height="100%" width="100%" alt="No Agent">
                          </div>
                          <div class="col-md-9">
                            <p>Sorry,<br> Our all staff are busy with other interaction<br> We have received your details,<br> We will call you back soon</p>
                          </div>
                          <div class="col-md-12 mt-3">
                            <button class="btn btn-primary col-md-12" (click)="backToHome()" [disabled]="isProcess"><i [ngClass]="{'fa-spinner fa-spin fa mr-2' : isProcess}"></i> Close </button>
                          </div>
                        </div>
                        <div *ngIf="pnChat && pnGrid" class="row video-group">
                          <input type="hidden" id="hiddenUserID">
                          <div class="col-md-12">
                            <p id="local-player-name" class="player-name"></p>
                            <div id="local-player" class="player"></div>
                          </div>
                          <div class="w-100"></div>
                          <div class="col-md-12" style="min-height: calc(100vh - 40vh);">
                            <div id="remote-playerlist">
                              <div id="DefaultVideoThumbnail"><img src="assets/img/users/user-dummy-img.jpg" height="100%" width="100%" alt="Default Thumbnail"></div>
                            </div>
                          </div>
                          <p class="isLoading text-center"> <i class="fa-refresh fa-spin fa"></i> Loading... Please wait... </p>
                          <button id="leave" type="button" class="btn btn-primary btn-sm mt-2"
                            (click)="Action_Leave(true)">Leave</button>
                        </div>
                        <div id="pnContactThankyou" style="display: none;">
                          <div class="row">
                            <div class="col-md-12">
                              <div aria-labelledby="swal2-title" aria-describedby="swal2-html-container"
                                class="swal2-popup swal2-modal swal2-icon-success swal2-show" tabindex="-1" role="dialog"
                                aria-live="assertive" aria-modal="true" style="display: grid;">
                                <div class="swal2-icon swal2-success swal2-icon-show" style="display: flex;">
                                  <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);">
                                  </div>
                                  <span class="swal2-success-line-tip"></span> <span class="swal2-success-line-long"></span>
                                  <div class="swal2-success-ring"></div>
                                  <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);"></div>
                                  <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);">
                                  </div>
                                </div>
                                <h2 class="swal2-title" id="swal2-title" style="display: block;">Your Request was sent
                                  successfully
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="tab-pane p-4 fade show" id="tabSchedule" role="tabpanel" aria-labelledby="btSchedule">
                          <div class="row pnSchedule">
                            <div class="col-md-12 text-center text-primary">
                              <h4>Let's Schedule A Meeting</h4>
                            </div>
                            <div class='input-group date' id='datetimepicker1'>
                              <input type='text' class="form-control" />
                              <span class="input-group-addon">
                                <span class="glyphicon glyphicon-calendar"></span>
                              </span>
                            </div>
                          </div>
                        </div> -->
                    </div>
                    <!-- <ul class="nav nav-tabs" id="myTab" role="tablist"> </ul> -->
                </div>
                <div *ngIf="pnChat" [ngClass]="{'col-md-12' : !pnChat, 'col-md-6' : pnChat}">
                    <!-- Start User chat -->
                    <div class="user-chat w-100 overflow-hidden">
                        <div class="chat-content d-lg-flex">
                            <!-- start chat conversation section -->
                            <div class="w-100 overflow-hidden position-relative">
                                <!-- conversation user -->
                                <div class="position-relative">
                                    <!-- <div class="p-3 user-chat-topbar">
                                        <div class="row align-items-center">
                                            <div class="col-sm-4 col-8">
                                                <div class="d-flex align-items-center">
                                                    <div class="flex-shrink-0 d-block d-lg-none me-3">
                                                        <a href="javascript: void(0);" class="user-chat-remove fs-18 p-1"><i class="ri-arrow-left-s-line align-bottom"></i></a>
                                                    </div>
                                                    <div class="flex-grow-1 overflow-hidden">
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 chat-user-img user-own-img align-self-center me-3 ms-0">
                                                                <img src="assets/images/users/user-dummy-img.jpg" class="rounded-circle avatar-xs" alt="">
                                                                <span class="user-status"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-8 col-4">
                                                <ul class="list-inline user-chat-nav text-end mb-0">
                                                    <li class="list-inline-item m-0">
                                                        <div class="dropdown" ngbDropdown>
                                                            <button class="btn btn-ghost-secondary btn-icon shadow-none arrow-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                                <i-feather name="search" class="icon-sm"></i-feather>
                                                            </button>
                                                            <div class="dropdown-menu p-0 dropdown-menu-end dropdown-menu-lg" ngbDropdownMenu>
                                                                <div class="p-2">
                                                                    <div class="search-box">
                                                                        <input type="text" class="form-control bg-light border-light" placeholder="Search here..." id="searchMessage" autocomplete="off">
                                                                        <i class="ri-search-2-line search-icon"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                
                                                    <li class="list-inline-item d-none d-lg-inline-block m-0">
                                                        <button type="button" class="btn btn-ghost-secondary btn-icon shadow-none" data-bs-toggle="offcanvas" data-bs-target="#userProfileCanvasExample" aria-controls="userProfileCanvasExample" (click)="openEnd(userInfo)">
                                                            <i-feather name="info" class="icon-sm"></i-feather>
                                                        </button>
                                                    </li>
                
                                                    <li class="list-inline-item m-0">
                                                        <div class="dropdown" ngbDropdown>
                                                            <button class="btn btn-ghost-secondary btn-icon shadow-none arrow-none" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                                                            <i-feather name="more-vertical" class="icon-sm"></i-feather>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                                <a class="dropdown-item d-block d-lg-none user-profile-show" href="javascript:void(0);"><i class="ri-user-2-fill align-bottom text-muted me-2"></i> View Profile</a>
                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="ri-inbox-archive-line align-bottom text-muted me-2"></i> Archive</a>
                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="ri-mic-off-line align-bottom text-muted me-2"></i> Muted</a>
                                                                <a class="dropdown-item" href="javascript:void(0);"><i class="ri-delete-bin-5-line align-bottom text-muted me-2"></i> Delete</a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> -->
                                    <!-- end chat user head -->
                                    <div class="position-relative" id="users-chat">
                                      <ngx-simplebar class="chat-conversation p-3 p-lg-4" #scrollRef>
                                          <!-- <div *ngIf="!isProcessLoadChat && chatMessagesData == 0">
                                            <h3 class="center-container" style="min-height: 69vh !important;">--No Chat Found--</h3>
                                          </div> -->
                                          <!-- <div *ngIf="isProcessLoadChat">
                                            <h4 class="center-container" style="min-height: 50vh;"><i class="fa-refresh fa-spin fa ml-2"></i></h4>
                                          </div> -->
                                          <div>
                                            <ul class="list-unstyled chat-conversation-list" id="users-conversation">
                                                <!-- <li class="chat-list left" *ngFor="let data of chatMessagesData"
                                                    [ngClass]="{ 'right': data.ChatTypeID === 3 }">
                                                    <div class="conversation-list">
                                                        <div class="chat-avatar">
                                                          <img [src]="getProfile(data.ChatTypeID, data.ClientProfileImage)" alt="">
                                                        </div>
                                                        <div class="user-chat-content">
                                                            <div class="ctext-wrap">
                                                                <div class="ctext-wrap-content">

                                                                    <div class="replymessage-block mb-0 d-flex align-items-start" *ngIf="data.replayName">
                                                                        <div class="flex-grow-1">
                                                                            <h5 class="conversation-name">
                                                                                {{data.EmployeeName}}</h5>
                                                                            <p class="mb-0">{{data.ChatMessage}}</p>
                                                                        </div>
                                                                        <div class="flex-shrink-0">
                                                                            <button type="button"
                                                                                class="btn btn-sm btn-link mt-n2 me-n3 font-size-18">
                                                                            </button>
                                                                        </div>
                                                                    </div>

                                                                    <p class="mb-0 ctext-content">{{data.ChatMessage}}</p> -->
                                                                    <!-- <ul class="list-inline message-img mt-3  mb-0"
                                                                        *ngIf="data.image">
                                                                        <li class="list-inline-item message-img-list"
                                                                            *ngFor="let images of data.image; let i=index">
                                                                            <a class="d-inline-block m-1">
                                                                                <img src="{{images}}" alt=""
                                                                                    class="rounded img-thumbnail"
                                                                                    (click)="open(i)">
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div> -->
                                                                <!-- <div class="dropdown align-self-start message-box-drop"
                                                                    ngbDropdown>
                                                                    <a class="dropdown-toggle"
                                                                        href="javascript:void(0);" role="button"
                                                                        data-bs-toggle="dropdown" aria-haspopup="true"
                                                                        aria-expanded="false" ngbDropdownToggle>
                                                                        <i class="ri-more-2-fill"></i>
                                                                    </a>
                                                                    <div class="dropdown-menu" ngbDropdownMenu>
                                                                        <a class="dropdown-item reply-message"
                                                                            href="javascript:void(0);"><i
                                                                                class="ri-reply-line me-2 text-muted align-bottom"></i>Reply</a>
                                                                        <a class="dropdown-item"
                                                                            href="javascript:void(0);"><i
                                                                                class="ri-share-line me-2 text-muted align-bottom"></i>Forward</a>
                                                                        <a class="dropdown-item copy-message"
                                                                            href="javascript:void(0);"><i
                                                                                class="ri-file-copy-line me-2 text-muted align-bottom"></i>Copy</a>
                                                                        <a class="dropdown-item"
                                                                            href="javascript:void(0);"><i
                                                                                class="ri-bookmark-line me-2 text-muted align-bottom"></i>Bookmark</a>
                                                                        <a class="dropdown-item delete-item"
                                                                            href="javascript:void(0);"><i
                                                                                class="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>Delete</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="conversation-name">
                                                              <small class="text-muted time">{{data.ChatDate | date:'dd/MM/YYYY hh:mm a'}}</small> -->
                                                              <!-- <span class="text-success check-message-icon"><i class="bx bx-check-double align-middle"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li> -->
                                                <!-- chat-list -->
                                            </ul>
                                            <!-- end chat-conversation-list -->
                                          </div>
                                        </ngx-simplebar>
                                        <!-- <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show"
                                            id="copyClipBoard" role="alert">
                                            Message copied
                                        </div> -->
                                    </div>
                                    <!-- end chat-conversation -->
                                    <div class="chat-input-section p-3 p-lg-4">

                                        <form id="chatinput-form" enctype="multipart/form-data">
                                            <div class="row g-0 align-items-center">
                                                <!-- <div class="col-auto">
                                                    <div class="chat-input-links me-2">
                                                        <div class="links-list-item">
                                                            <button type="button"
                                                                class="btn btn-link text-decoration-none emoji-btn"
                                                                id="emoji-btn">
                                                                <i class="bx bx-smile align-middle"></i>
                                                            </button>
                                                            <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker"
                                                                (emojiSelect)="addEmoji($event)"
                                                                title="Pick your emoji…"></emoji-mart>
                                                        </div>
                                                    </div>
                                                </div> -->

                                                <div class="col">
                                                    <!-- <div *ngIf="submitted && form['message'].errors" class="chat-input-feedback">
                                                        <span *ngIf="form['message'].errors['required']">Please Enter a Message</span>
                                                    </div> -->
                                                    <input type="text"
                                                        class="form-control chat-input bg-light border-light"
                                                        id="chat_Send_TxtMessage" placeholder="Type your message..." [readonly]="!pnChat"
                                                        autocomplete="off" (blur)="onBlur()">
                                                </div>
                                                <div class="col-auto">
                                                    <div class="chat-input-links ms-2">
                                                        <div class="links-list-item">
                                                            <button type="submit" id="chat_Btn_Send_Message"
                                                                class="btn btn-primary chat-send waves-effect waves-light shadow" [disabled]="!pnChat">
                                                                <i class="bx bxs-send align-middle"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                    <div class="replyCard">
                                        <div class="card mb-0">
                                            <div class="card-body py-3">
                                                <div class="replymessage-block mb-0 d-flex align-items-start">
                                                    <div class="flex-grow-1">
                                                        <h5 class="conversation-name"></h5>
                                                        <p class="mb-0"></p>
                                                    </div>
                                                    <div class="flex-shrink-0">
                                                        <button type="button" id="close_toggle"
                                                            class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none"
                                                            (click)="closeReplay()">
                                                            <i class="bx bx-x align-middle"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>