import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Injector, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
//import { browserRefresh } from 'src/app/app.component';

@Component({
  selector: 'app-products-details2',
  templateUrl: './products-details2.component.html',
  styleUrls: ['./products-details2.component.scss'],
})
export class ProductsDetails2Component implements OnInit {
    
  activeTab = "ngbRequiredDocuments";
  //browserRefresh: boolean;
  apiUrl:string;
  //isRTL: string = '';
  languageSetting: string = "0";
  serviceDetails: any;
  formDetails: any;
  //formTableDetail: any;
  serviceId: number;
  serviceName: string;
  formType: string = "Save";
  recordIDP: number = 0;
  price:string;
  remark:string;
  type:string;
  authType: number;
  dynamicIdf: number;
  tableName:string;

  constructor(private http: HttpClient, private injector: Injector, private route: ActivatedRoute, public sanitizer: DomSanitizer, public datepipe: DatePipe) {
    this.apiUrl = this.injector.get('APIURL');
    if(localStorage.getItem('LanguageID') != null) this.languageSetting = localStorage.getItem('LanguageID');
    //this.isRTL = (localStorage.getItem('IsRTL').toString() == "rtl") ? "2" : "1";
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params => {
        this.serviceId = params['ServiceID'];
        this.serviceName = params['ServiceName'];
        this.price = params['Price'];
        this.remark = params['Remark'];
        this.type = params['Type'];
        this.authType = params['AuthType'];
      }
    )

    // this.browserRefresh = browserRefresh;
    // console.log('refreshed?:', browserRefresh);
    this.actionGetServiceDetails();
  }

  public getSantizeUrl(url : string) {
    var temp = "Assets/Service/" + this.serviceId + "/" + url;
    //var temp = "D:/0__Work/Angular Templates/API/uae-saas-api/Assets/Service/" + this.serviceId + "/" + url;
    return this.sanitizer.bypassSecurityTrustUrl(temp);
  }//getSantizeUrl
  
  actionGetServiceDetails(){
    this.http.get(this.apiUrl + "/Service/Get_With_FormDetail_For_Presentation/" + this.serviceId + "/" + this.languageSetting + "/" + localStorage.getItem('UserID')).subscribe(
      (data) => {
        this.serviceDetails = data["ServiceData"];
        this.serviceName = data["ServiceData"][0].ServiceName;
        this.formDetails = JSON.stringify(data["DynamicFormDetailData"]);
        // this.formTableDetail = JSON.stringify(data["DynamicFormTableData"]);
        this.dynamicIdf = data["DynamicFormDetailData"][0].DynamicIDF;
        this.tableName = data["DynamicForm"][0].TableName;
      },
      (error) => {
        console.log(error);
      }
    );
  }//actionGetServiceDetails
  
  // pageTitleContent = [
  //     {
  //         //title: 'Service Details',
  //         backgroundImage: 'assets/img/page-title/page-title4.jpg'
  //     }
  // ]

}