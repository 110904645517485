<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<!-- <div class="page-title-area" style="background-image: url(assets/img/page-title/page-title5.jpg);">
    <div class="container">
        <div class="page-title-content">
            <h2>{{serviceName}} To Apply</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Services</li>
                <li>{{serviceName}}</li>
                <li>Checkout</li>
            </ul>
        </div>
    </div>
</div> -->

<form  class="needs-validation" [formGroup]="frmCtrlRenewVisa" autocomplete="off">
<section *ngIf="pnService" class="checkout-area pt-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <h3>Renew Residency Visa</h3><div class="row">
                    <div class="col-md-6 form-group">
                        <label for="StaffName" class="form-label">{{StaffName}}<i>*</i></label>
                        <input class="form-control" type="text" placeholder="{{StaffName}}" formControlName="StaffName"
                        [ngClass]="{ 'is-invalid': submittedRenewVisa && fRenewVisa.StaffName.invalid, 'is-valid': submittedRenewVisa &&  !fRenewVisa.StaffName.invalid }" required>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="StaffNumber" class="form-label">{{StaffNumber}}</label>
                        <input class="form-control" type="number" placeholder="{{StaffNumber}}" formControlName="StaffNumber">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-6 form-group">
                        <label for="EmployeeEmail" class="form-label">{{EmployeeEmail}}<i>*</i></label>
                        <input class="form-control" type="text" placeholder="{{EmployeeEmail}}" formControlName="EmployeeEmail"
                        [ngClass]="{ 'is-invalid': submittedRenewVisa && fRenewVisa.EmployeeEmail.invalid, 'is-valid': submittedRenewVisa &&  !fRenewVisa.EmployeeEmail.invalid }" required>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="EmployeeMobile" class="form-label">{{EmployeeMobile}}</label>
                        <input class="form-control" type="text" placeholder="{{EmployeeMobile}}" formControlName="EmployeeMobile">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 form-group">
                        <label for="Location" class="form-label">Country {{Location}}<i>*</i></label>
                        <ng-select formControlName="Location" placeholder="Select Country Location" [multiple]="false" [clearable]="false"
                        [ngClass]="{ 'is-invalid': submittedRenewVisa && fRenewVisa.Location.invalid, 'is-valid': submittedRenewVisa &&  !fRenewVisa.Location.invalid }" required>
                            <ng-option value="Inside UAE">Inside UAE</ng-option>
                            <ng-option value="OutSide UAE">OutSide UAE</ng-option>
                        </ng-select>
                        <!-- <input class="form-control" type="text" placeholder="Country {{Location}}" formControlName="Location"
                        [ngClass]="{ 'is-invalid': submittedRenewVisa && fRenewVisa.Location.invalid, 'is-valid': submittedRenewVisa &&  !fRenewVisa.Location.invalid }" required> -->
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="EmployeeLocation" class="form-label">{{EmployeeLocation}}<i>*</i></label>
                        <ng-select formControlName="EmployeeLocation" placeholder="Select {{EmployeeLocation}}" [multiple]="false" [clearable]="false"
                        [ngClass]="{ 'is-invalid': submittedRenewVisa && fRenewVisa.EmployeeLocation.invalid, 'is-valid': submittedRenewVisa &&  !fRenewVisa.EmployeeLocation.invalid }" required>
                            <ng-option value="Abu Dhabi">Abu Dhabi</ng-option>
                            <ng-option value="AI Ain">AI Ain</ng-option>
                            <ng-option value="Western Region">Western Region</ng-option>
                        </ng-select>
                        <!-- <input class="form-control" type="text" placeholder="{{EmployeeLocation}}" formControlName="EmployeeLocation"
                        [ngClass]="{ 'is-invalid': submittedRenewVisa && fRenewVisa.EmployeeLocation.invalid, 'is-valid': submittedRenewVisa &&  !fRenewVisa.EmployeeLocation.invalid }" required> -->
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 form-group">
                        <label for="ProfilePicture" class="form-label">{{ProfilePicture}}<i>*</i></label>
                        <input class="form-control file-input" type="file" (change)="ProfilePictureChange($event)">
                        <!-- <input class="form-control file-input" formControlName="ProfilePicture" type="file" (change)="ProfilePictureChange($event)"> -->
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="Passport" class="form-label">{{Passport}}<i>*</i></label>
                        <input class="form-control file-input" type="file" (change)="PassportChange($event)">
                        <!-- <input class="form-control file-input" formControlName="Passport" type="file" (change)="PassportChange($event)"> -->
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 form-group">
                        <label for="MedicalCertificate" class="form-label">{{MedicalCertificate}}<i>*</i></label>
                        <input class="form-control file-input" type="file" (change)="MedicalCertificateChange($event)">
                        <!-- <input class="form-control file-input" formControlName="MedicalCertificate" type="file" (change)="MedicalCertificateChange($event)"> -->
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="EIDApplication" class="form-label">{{EIDApplication}}<i>*</i></label>
                        <input class="form-control file-input" type="file" (change)="EIDApplicationChange($event)">
                        <!-- <input class="form-control file-input" formControlName="EIDApplication" type="file" (change)="EIDApplicationChange($event)"> -->
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 form-group">
                        <label for="HealthInsurance" class="form-label">{{HealthInsurance}}<i>*</i></label>
                        <input class="form-control file-input" type="file" (change)="HealthInsuranceChange($event)">
                        <!-- <input class="form-control file-input" formControlName="HealthInsurance" type="file" (change)="HealthInsuranceChange($event)"> -->
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="SalaryCertificate" class="form-label">{{SalaryCertificate}}<i>*</i></label>
                        <input class="form-control file-input" type="file" (change)="SalaryCertificateChange($event)">
                        <!-- <input class="form-control file-input" formControlName="SalaryCertificate" type="file" (change)="SalaryCertificateChange($event)"> -->
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6 form-group">
                        <label for="Comments" class="form-label">{{Comments}}</label>
                        <textarea class="form-control" rows="2" placeholder="{{Comments}}" formControlName="Comments"></textarea>
                    </div>
                    <div class="col-md-6 form-group">
                        <label for="SalaryCertificateIssueDate" class="form-label">{{SalaryCertificateIssueDate}}</label>
                        <input class="form-control" type="date" placeholder="{{SalaryCertificateIssueDate}}" formControlName="SalaryCertificateIssueDate">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="pnService" class="checkout-area pb-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper">
                <div class="row">
                    <div class="col-md-12">
                        <button type="button" class="btn btn-success" (click)="actionSave()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</form>

<section *ngIf="!pnService" class="checkout-area ptb-100">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper text-center">
                <h3 class="text-success" style="padding: 50px;">Thank you for apply {{serviceName}} service.</h3>
                <a *ngIf="serviceId != 1" class="btn btn-primary" routerLink="/service-form-template" [queryParams]="{sid:serviceId, cid:customerId, ServiceName:serviceName}"> Next </a>
            </div>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>