<app-navbar-style-one></app-navbar-style-one>

<!-- Start Page Title Area -->
<!-- <div class="page-title-area" *ngFor="let Content of pageTitleContent;" style="background-image: url({{Content.backgroundImage}});">
    <div class="container">
        <div class="page-title-content">
            <h2>{{serviceName}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">Services</a></li>
                <li>{{serviceName}}</li>
            </ul>
        </div>
    </div>
</div> -->
<!-- End Page Title Area -->

<!-- Start Products Details Area -->
<section id="pnServiceDetail" class="products-details-area ptb-100">
    <input type="hidden" id="hiddenAPIURL" [(ngModel)]="apiUrl">
    <input type="hidden" id="hiddenServiceID" [(ngModel)]="serviceId">
    <input type="hidden" id="hiddenDynamicIDP" [(ngModel)]="dynamicIdf">
    <input type="hidden" id="hiddenTableName" [(ngModel)]="tableName">
    <input type="hidden" id="hiddenFormType" [(ngModel)]="formType">
    <input type="hidden" id="hiddenRecordIDP" [(ngModel)]="recordIDP">
    <div class="container" *ngFor="let serviceDetail of serviceDetails">
        <h1 class="text-center"><strong>{{serviceName}}</strong></h1>
        <div [ngClass]="{'text-right mt-4 ml-4': languageSetting == 2}" [innerHTML] = "serviceDetail.Remarks"></div>
        <input type="hidden" id="hiddenFormDetails" [(ngModel)]="formDetails">
        <!-- <h1>{{serviceName}}</h1>
        <p>Issue a new residency visa for your dependents on your sponsorship, regardless if they were Inside or Outside UAE.</p>
        <input type="hidden" id="hiddenFormDetails" [(ngModel)]="formDetails"> -->
        <!-- <input type="hidden" id="hiddenFormTableDetail" [(ngModel)]="formTableDetail"> -->
        <!-- <div class="row" *ngFor="let serviceDetail of serviceDetails">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <a [href]="getSantizeUrl(serviceDetail.Banner)" target="_blank" class="popup-btn">
                        <img src="assets/img/products/products-img1.jpg" alt="image"> -->
                        <!-- <img [src]="getSantizeUrl(serviceDetail.Banner)" alt="image"> -->
                    <!-- </a>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <nav ngbNav #altnav="ngbNav" class="nav-tabs"> -->
                    <!-- First Tab Start -->
                    <!-- <ng-container ngbNavItem>
                        <a ngbNavLink id="ngbRequiredDocuments">Required Documents</a>
                        <ng-template ngbNavContent>
                            <div class="mt-4" [innerHTML] = "serviceDetail.Remarks"></div>
                        </ng-template>
                    </ng-container> -->
                    <!-- First Tab Close -->
                    <!-- Second Tab Start -->
                    <!-- <ng-container ngbNavItem>
                        <a ngbNavLink id="ngbSteps">Steps</a>
                        <ng-template ngbNavContent>
                            <div class="mt-4" [innerHTML] = "serviceDetail.Steps"></div>
                        </ng-template>
                    </ng-container> -->
                    <!-- Second Tab Close -->
                    <!-- Third Tab Start -->
                    <!-- <ng-container ngbNavItem>
                        <a ngbNavLink id="ngbImportantNotes">Important Notes</a>
                        <ng-template ngbNavContent>
                            <div class="mt-4" [innerHTML] = "serviceDetail.ImportantNotes"></div>
                        </ng-template>
                    </ng-container> -->
                    <!-- Third Tab Close -->
                <!-- </nav>
                <div [ngbNavOutlet]="altnav"></div> -->
                <!-- </div> -->
            <!-- </div>
        </div> -->

        <section id="ngx">
            <h1 style="display: none;">{{serviceName}} Order</h1>
            <div class="card" style="border: none;">
                <div class="card-content">
                    <div class="card-body">
                        <div class="pnProcessLoading text-center"><i class="fa-spinner fa-spin fa mr-2 mt-5 mb-5" style="font-size: 30px;"></i></div>
                        <div id="displayForm"></div>

                        <div class="row mt-3 mb-3" style="border: 1px solid #cdcdcd; border-radius: 15px; margin: 5px; padding: 20px;">
                            <div class="col-md-12">
                                <div class="form-check-inline">
                                    <input class="form-check-input mr-3" type="checkbox" id="cbConfirmation" />
                                    <label for="cbConfirmation" class="form-check-label form-label-english">  I hereby confirm and acknowledge that all papers and data provided is correct <i>*</i></label><br>
                                    <label for="cbConfirmation" class="form-check-label form-label-english ml-4">  أتعهد بأن جميع الاوراق والبيانات صحيحة </label>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 mt-4">
                                <div class="d-flex flex-wrap gap-2 justify-content-center">
                                    <button type="button" class="btn btn-primary" id="btSave"><i class=""></i> Submit</button>
                                </div>
                                <div class="d-flex flex-wrap gap-2 justify-content-center">
                                    <p id="msgSaveWarning" style="visibility: hidden;">Please do not refresh the page.</p>
                                </div>
                            </div>
                        </div>

                        <!-- <aw-wizard #wizard [navBarLayout]="'large-empty-symbols'">
                            <aw-wizard-step [stepTitle]="'Step 1'"
                                [navigationSymbol]="{ symbol: '1', fontFamily: 'FontAwesome' }">
                                <form #personalForm="ngForm" class="editForm" novalidate>
                                    <h4 class="head text-center">Please tell us about yourself.</h4>
                                    <br />
                                    <div class='row'>
                                        <div class='col-12'>
                                            <div class="row">
                                                <div class='col-12 col-sm-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="firstname">First
                                                            Name</label>
                                                        <input class="form-control input-md" ngModel
                                                            #firstname="ngModel" required id="firstname"
                                                            name="firstname" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="!firstname.valid && (firstname.dirty || firstname.touched)">This
                                                            field is required!</small>
                                                    </div>
                                                </div>
                                                <div class='col-12 col-sm-6'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="lastname">Last
                                                            Name</label>
                                                        <input class="form-control input-md" ngModel #lastname="ngModel"
                                                            required id="lastname" name="lastname" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="!lastname.valid && (lastname.dirty || lastname.touched)">This
                                                            field is required!</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-control-label" for="email">Email</label>
                                                <input class="form-control input-md" ngModel #email="ngModel" required
                                                    email id="email" name="email" type="text">
                                                <small class="form-text text-muted danger"
                                                    *ngIf="!email.valid && (email.dirty || email.touched)">Please enter
                                                    a valid email!</small>
                                            </div>

                                            <div class="form-group text-center">
                                                <button type="button" uiSref="work" class="btn btn-info"
                                                    [disabled]="!personalForm.valid" awNextStep>
                                                    Next
                                                    <span style="margin-left:10px;">
                                                        <i class="ft-chevron-right"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </aw-wizard-step>
                            <aw-wizard-step [stepTitle]="'Step 2'"
                                [navigationSymbol]="{ symbol: '2', fontFamily: 'FontAwesome' }">
                                <form #workForm="ngForm" class="editForm" novalidate>
                                    <h4 class="head text-center">What do you do?</h4>
                                    <br />
                                    <div class='row justify-content-center'>
                                        <div class='col-2'>
                                            <div class="form-group">
                                                <div class="custom-control custom-radio m-0">
                                                    <input type="radio" #work required id="customRadioInline1"
                                                        name="work" class="custom-control-input" value="Design">
                                                    <label class="custom-control-label"
                                                        for="customRadioInline1">Design</label>
                                                </div>
                                                <div class="custom-control custom-radio m-0">
                                                    <input type="radio" #work required id="customRadioInline2"
                                                        name="work" class="custom-control-input" value="Code">
                                                    <label class="custom-control-label"
                                                        for="customRadioInline2">Code</label>
                                                </div>
                                                <div class="custom-control custom-radio m-0">
                                                    <input type="radio" #work required id="customRadioInline3" checked
                                                        name="work" class="custom-control-input" value="Deploy">
                                                    <label class="custom-control-label"
                                                        for="customRadioInline3">Deploy</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group text-center space-20">
                                        <button uiSref="personal" type="button" class="btn btn-secondary mr-1"
                                            awPreviousStep>
                                            <span style="margin-right:10px;">
                                                <i class="ft-chevron-left"></i>
                                            </span> Previous</button>
                                        <button uiSref="address" type="button" class="btn btn-info"
                                            [disabled]="!workForm.valid" awNextStep>
                                            Next
                                            <span style="margin-left:10px;">
                                                <i class="ft-chevron-right"></i>
                                            </span>
                                        </button>
                                    </div>
                                </form>
                            </aw-wizard-step>
                            <aw-wizard-step [stepTitle]="'Step 3'"
                                [navigationSymbol]="{ symbol: '3', fontFamily: 'FontAwesome' }">
                                <form #addressForm="ngForm" class="editForm" novalidate>
                                    <h4 class="head text-center">Where do you live?</h4>
                                    <br />
                                    <div class='row'>
                                        <div class='col-sm-12'>
                                            <div class="form-group">
                                                <label class="form-control-label" for="street">Street Address</label>
                                                <input class="form-control input-md" ngModel #street="ngModel" required
                                                    id="street" name="street" type="text">
                                                <small class="form-text text-muted danger"
                                                    *ngIf="!street.valid && (street.dirty || street.touched)">This field
                                                    is required!</small>

                                            </div>
                                            <div class="row">
                                                <div class='col-12 col-sm-4'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="city">City</label>
                                                        <input class="form-control input-md" ngModel #city="ngModel"
                                                            required id="city" name="city" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="!city.valid && (city.dirty || city.touched)">This
                                                            field is required!</small>

                                                    </div>
                                                </div>
                                                <div class='col-4 col-sm-offset-1 col-sm-3'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="state">State</label>
                                                        <input class="form-control input-md" ngModel #state="ngModel"
                                                            required id="state" name="state" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="!state.valid && (state.dirty || state.touched)">This
                                                            field is required!</small>

                                                    </div>
                                                </div>
                                                <div class='col-offset-1 col-7 col-sm-offset-1 col-sm-3'>
                                                    <div class="form-group">
                                                        <label class="form-control-label" for="zip">Zip</label>
                                                        <input class="form-control input-md" ngModel #zip="ngModel"
                                                            required id="zip" name="zip" type="text">
                                                        <small class="form-text text-muted danger"
                                                            *ngIf="!zip.valid && (zip.dirty || zip.touched)">This field
                                                            is required!</small>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group text-center">
                                                <button uiSref="work" type="button" class="btn btn-secondary mr-1"
                                                    awPreviousStep>
                                                    <span style="margin-right:10px;">
                                                        <i class="ft-chevron-left"></i>
                                                    </span> Previous</button>
                                                <button uiSref="result" type="button" class="btn btn-success"
                                                    [disabled]="!addressForm.valid" awResetWizard>
                                                    Finish
                                                    <span style="margin-left:10px;">
                                                        <i class="ft-chevron-right"></i>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </aw-wizard-step>
                        </aw-wizard> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</section>
<!-- End Products Details Area -->

<section id="pnMsgSuccess" class="checkout-area ptb-100" style="display: none;">
    <div class="container">
        <div id="add-review">
            <div class="review-form-wrapper text-center">
                <h3 class="text-success" style="padding: 50px;">Thank you for apply {{serviceName}} service.</h3>
                <a class="btn btn-primary" routerLink="/user-dashboard"> Go to Dashboard </a>
            </div>
        </div>
    </div>
</section>

<!-- <app-related-products></app-related-products> -->

<app-footer-style-one></app-footer-style-one>