<!-- Start Login & Register Modal -->
<div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" id="closeModalButton" class="close" data-bs-dismiss="modal" aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link active" id="login-tab" data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li>
                <li class="nav-item">
                    <button class="nav-link" id="register-tab" data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab" aria-controls="register" aria-selected="true">Register</button>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <div class="login-with-account">
                            <!-- <span>Login with</span>
                            <ul>
                                <li><a class="google" (click)="loginWithGoogle()"><i class='bx bxl-google'></i> Google</a></li>
                                <li><a class="facebook" (click)="signInWithFB()"><i class='bx bxl-facebook'></i> Facebook</a></li>
                            </ul> -->
                        </div>
                        <!-- <span class="sub-title"><span>Or login with</span></span> -->
                        <form class="needs-validation" [formGroup]="frmCtrlLogin" autocomplete="off">
                            <div class="form-group">
                                <input type="text" placeholder="Username or Email" class="form-control" formControlName="UserName" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': loginFormSubmitted && flgn.UserName.invalid, 'is-valid': loginFormSubmitted && !flgn.UserName.invalid }"
                                required autofocus>
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control" formControlName="LgnPassword" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': loginFormSubmitted && flgn.LgnPassword.invalid, 'is-valid': loginFormSubmitted && !flgn.LgnPassword.invalid }"
                                required>
                            </div>
                            <button type="submit" (click)="actionLogin()" [disabled]="isProcessLogin"><i [ngClass]="{'fa-spinner fa-spin fa mr-2' : isProcessLogin}"></i> Login Now</button>
                            <!-- <button type="submit">Login Now</button> -->
                        </form>
                        <!-- <span class="dont-account">Don't have an account? <a href="#">Register Now</a></span> -->
                    </div>
                </div>
                <div class="tab-pane fade" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <!-- <div class="register-with-account">
                            <span>Register with</span>
                            <ul>
                                <li><a href="#" class="facebook"><i class='bx bxl-facebook'></i> Facebook</a></li>
                                <li><a href="#" class="twitter"><i class='bx bxl-twitter'></i> Twitter</a></li>
                            </ul>
                        </div>
                        <span class="sub-title"><span>Or Register with</span></span> -->
                        <form class="needs-validation" [formGroup]="frmCtrlRegistartion" autocomplete="off">
                            <div class="form-group">
                                <input type="text" placeholder="First Name" class="form-control" formControlName="FirstName" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.FirstName.invalid, 'is-valid': registrationFormSubmitted && !f.FirstName.invalid }"
                                required autofocus>
                            </div>
                            <!-- <div class="form-group">
                                <input type="text" placeholder="MiddleName" class="form-control" formControlName="MiddleName" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.MiddleName.invalid, 'is-valid': registrationFormSubmitted && !f.MiddleName.invalid }"
                                required>
                            </div> -->
                            <div class="form-group">
                                <input type="text" placeholder="Last Name" class="form-control" formControlName="LastName" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.LastName.invalid, 'is-valid': registrationFormSubmitted && !f.LastName.invalid }"
                                required>
                            </div>
                            <div class="form-group">
                                <input type="text" placeholder="Mobile No" class="form-control" formControlName="MobileNo" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.MobileNo.invalid, 'is-valid': registrationFormSubmitted && !f.MobileNo.invalid }"
                                required>
                            </div>
                            <div class="form-group">
                                <input type="email" placeholder="Email ID" class="form-control" formControlName="EmailID" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.EmailID.invalid, 'is-valid': registrationFormSubmitted && !f.EmailID.invalid }"
                                required>
                                <div *ngIf="f.EmailID.errors && (f.EmailID.invalid || f.EmailID.touched)">
                                    <small class="text-danger" *ngIf="f.EmailID.errors?.pattern">Please provide a email address only @ese.gov.ae</small>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Password" class="form-control" formControlName="Password" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.Password.invalid, 'is-valid': registrationFormSubmitted && !f.Password.invalid }"
                                required>
                                <!-- <div *ngIf="registrationFormSubmitted && (f.Password.errors?.required)"
                                    class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Password is required
                                </div> -->
                                <div *ngIf="registrationFormSubmitted && (!f.Password.errors?.required && f.Password.errors?.minlength)"
                                    class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Password must be at least 6 characters
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" placeholder="Confirm Password" class="form-control" formControlName="ConfirmPassword" autocomplete="cc-name"
                                [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.ConfirmPassword.invalid, 'is-valid': registrationFormSubmitted && !f.ConfirmPassword.invalid }"
                                required>
                                <!-- <div *ngIf="registrationFormSubmitted && (f.ConfirmPassword.errors?.required)"
                                    class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Confirm Password is required
                                </div> -->
                                <div *ngIf="registrationFormSubmitted && (frmCtrlRegistartion.value.Password !== frmCtrlRegistartion.value.ConfirmPassword)"
                                    [ngClass]="{ 'is-invalid': registrationFormSubmitted && f.ConfirmPassword.invalid, 'is-valid': registrationFormSubmitted && !f.ConfirmPassword.invalid }"
                                    class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Passwords don't match.
                                </div>
                            </div>
                            <button type="submit" (click)="actionRegistration()" [disabled]="isProcessRegister"><i [ngClass]="{'fa-spinner fa-spin fa mr-2' : isProcessRegister}"></i> Register Now</button>
                            <!-- <button type="submit">Register Now</button> -->
                        </form>
                        <!-- <span class="already-account">Already have an account? <a href="#">Login Now</a></span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login & Register Modal -->