<!-- <asl-google-signin-button type='icon' size='medium'></asl-google-signin-button> -->
<!-- <asl-google-signin-button *ngIf="!user"></asl-google-signin-button> -->

<!-- <div *ngIf="!user" class="" style="margin-top: 100px;" id="google-button">123</div>

<div *ngIf="user">
    <img src="{{ user.photoUrl }}" referrerpolicy="no-referrer">
    <div>
    <h4>{{ user.name }}</h4>
    <p>{{ user.email }}</p>
    </div>
</div> -->


<div dir="{{isRTL}}">
    <router-outlet></router-outlet>
</div>